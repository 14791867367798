import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';

@Component({
  selector: 'app-dialog-albaranes',
  templateUrl: './dialog-albaranes.component.html',
  styleUrls: ['./dialog-albaranes.component.scss']
})
export class DialogAlbaranesComponent implements OnInit{
  infoArchivo:any;
  infoGeneral:any;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
        private servicesService: ServicesService,
        private matDialogRef: MatDialogRef<DialogAlbaranesComponent>,
        
        ){}

  ngOnInit(): void {
      this.modalAlbaranes(this.data)
  }

public modalAlbaranes (data:any) {

  this.servicesService.getAnuncioFinalizado(data).subscribe({
    next: (resp) => {
      
      if(resp.cod == 200){
        
      this.infoArchivo = resp.info_archivo
      this.infoGeneral = resp.info_general
      
      }
    },
    error: (error) => {},
    complete: () => {},
  })

}




close(): void {
  this.matDialogRef.close();
}



}

<ng-container [formGroup]="informationForm">
  <div class="row">
    <div class="col-sm-12">
      <div>
        <label style="color: #000000;">Tipo de transportista <span style="color: red;">*</span></label>
      </div>

      <div>
        <select class="form-select"
                aria-label="Default select example"
                name="tipoTransport"
                formControlName="tipoTransport"
                #mySelect
                (change)='enviarPadre(mySelect.value)'>
          <option value="0" [disabled]="true">Tipo</option>
          <option value="1">Persona Natural</option>
          <option value="2">Empresa</option>
        </select>
      </div>
    </div>
  </div>


  <div class="row mt-3">
    <div class="col-sm-6">
      <label style="color: #000000;">Nombre Físcal <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="Nombre Físcal"
              class="form-control"
              name="nombreFiscal"
              formControlName="nombreFiscal"
              style="font-size: 12px; margin-top: -5px;"
              maxlength="190"
              [ngClass]="{'is-invalid': invalidate('nombreFiscal').value }">
    </div>

    <div class="col-sm-6">
      <label style="color: #000000;">CIF/NIF <span style="color: red;">*</span></label>
      <input type="text"
             placeholder="CIF/NIF"
             class="form-control"
             name="cif"
             formControlName="cif"
             style="font-size: 12px; margin-top: -5px;"
             maxlength="40"
             [ngClass]="{'is-invalid': invalidate('cif').value }">
    </div>
  </div>


  <div class="row mt-3">
    <div class="col-sm-12">
      <div>
        <label style="color: #000000;">Dirección</label>
        <input type="text"
               placeholder="Avenida o Calle, Número, Código Postal, Ciudad, Provincia"
               class="form-control"
               name="direccion"
               formControlName="direccion"
               maxlength="190"
               style="font-size: 12px; margin-top: -5px;">
      </div>
    </div>
  </div>


  <div class="row mt-3">
    <div class="col-sm-6">
      <label style="color: #000000;">Teléfono <span style="color: red;">*</span></label>
      <input type="text"
             placeholder="Teléfono"
             class="form-control"
             name="telefono"
             formControlName="telefono"
             style="font-size: 12px; margin-top: -5px;"
             maxlength="40"
             [ngClass]="{'is-invalid': invalidate('telefono').value }">
    </div>

    <div class="col-sm-6">
      <label style="color: #000000;">Correo <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="Correo"
              class="form-control"
              name="correo"
              formControlName="correo"
              style="font-size: 12px; margin-top: -5px;"
              maxlength="190"
              [ngClass]="{'is-invalid': invalidate('correo').value }">
    </div>
  </div>
</ng-container>

<ul>
  <li class="container" *ngFor="let trayecto of trayectos">
    <div>
      <label>Origen</label>
      <div>{{trayecto.calle_origen}} {{trayecto.numero_origen}}, {{trayecto.ciudad_origen}} - {{trayecto.provincia_origen}}</div>
      <div>Postal {{trayecto.cod_postal_origen}}</div>
    </div>
    <div>
      <label>Destino</label>
      <div>{{trayecto.calle_destino}} {{trayecto.numero_destino}}, {{trayecto.ciudad_destino}} - {{trayecto.provincia_destino}}</div>
      <div>Postal {{trayecto.cod_postal_destino}}</div>
    </div>
    <div *ngIf="trayecto.descripcion">
      <label>Descripción</label>
      <div>{{trayecto.descripcion}}</div>
    </div>
    <div *ngIf="trayecto.fecha_max_entrega">
      <label>Fecha máxima de entrega</label>
      <div>{{trayecto.fecha_max_entrega | date:'dd/MM/yyyy'}}</div>
    </div>
    <div *ngIf="trayecto.cantidad_articulos_entregar">
      <label>Cantidad artículos a entregar</label>
      <div>{{trayecto.cantidad_articulos_entregar}}</div>
    </div>
    <div *ngIf="trayecto.nombre_descargador">
      <label>Quien recibe</label>
      <div>{{trayecto.nombre_descargador}}</div>
    </div>
    <div *ngIf="trayecto.cuenta_con">
      <label>El punto de descarga cuenta con...</label>
      <div>{{trayecto.cuenta_con}}</div>
    </div>
  
    <div *ngIf="trayecto.url">
      <a href="{{trayecto.url}}" target="_blank"><i class="fa fa-external-link-alt"></i></a>
    </div>
  </li>
</ul>

<ng-container *ngIf="false">

  <!--Trayecto Unico-->
  <ng-container *ngIf="false">
    <div class="form-group row">
      <div class="card">
        <div class="card-body">

          <div class="row mt-2">
            <div class="col-sm-6">
              <label style="color: #000000;"><b>Origen</b></label>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <label style="color: #000000;">Provincia</label>
              <input type="text"
                    placeholder="Provincia"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>

            <div class="col-sm-3">
              <label style="color: #000000;">Ciudad</label>
              <input type="text"
                    placeholder="Ciudad"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Calle</label>
              <input type="text"
                    placeholder="Calle"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Número</label>
              <input type="text"
                    placeholder="Número"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Código Postal </label>
              <input type="text"
                    placeholder="Cod Postal"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-sm-6">
              <label style="color: #000000;"><b>Destino</b></label>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <label style="color: #000000;">Provincia</label>
              <input type="text"
                    placeholder="Provincia"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>

            <div class="col-sm-3">
              <label style="color: #000000;">Ciudad</label>
              <input type="text"
                    placeholder="Ciudad"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Calle</label>
              <input type="text"
                    placeholder="Calle"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Número</label>
              <input type="text"
                    placeholder="Número"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Código Postal</label>
              <input type="text"
                    placeholder="Cod Postal"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-sm-6">
              <div class="form-group">
                <label style="color: #000000;">Fecha máxima de entrega</label>
                <input type="text"
                        class="form-control"
                        name="fecha_maxima"
                        style="font-size: 12px; margin-top: -5px;">
              </div>
            </div>

            <div class="col-sm-6">
              <div>
                <label style="color: black;">Orden de entrega</label>
              </div>
              <div>
                <input type="text"
                    placeholder="Código Postal"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
              </div>
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="exampleFormControlTextarea1" style="color: black;">Descripción</label>
                <textarea class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"></textarea>
              </div>
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-sm-6">
              <div class="form-group">
                <label style="color: #000000;">¿Quien recibe en el destino?</label>
                <input type="text"
                    placeholder="Cargador"
                    class="form-control"
                    style="font-size: 12px; margin-top: -5px;">
              </div>
            </div>

            <div class="col-sm-6">
              <div>
                <label style="color: #000000;">El punto de descarga cuenta con...</label>
              </div>

              <div class="form-check a">
                <input class="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                  Muelle
                </label>
              </div>

              <div class="form-check a">
                <input class="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                  Transpaleta
                </label>
              </div>

              <div class="form-check a">
                <input class="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                  Carretilla elevadora
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


  <!--Trayecto Multiple-->
  <ng-container *ngIf="false">
    <ng-container>
      <div class="form-group row">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-12 text-center">
                <label style="font-size: 17px; color: #0F4C81;"><b>Trayecto Inicial</b></label>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-6">
                <label style="color: #000000;"><b>Origen</b></label>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3">
                <label style="color: #000000;">Provincia</label>
                <input type="text"
                      placeholder="Provincia"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
              </div>

              <div class="col-sm-3">
                <label style="color: #000000;">Ciudad</label>
                <input type="text"
                      placeholder="Ciudad"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Calle</label>
                <input type="text"
                      placeholder="Calle"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Número</label>
                <input type="number"
                      placeholder="Número"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Código Postal </label>
                <input type="text"
                      placeholder="Cod Postal"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-sm-6">
                <label style="color: #000000;"><b>Destino</b></label>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3">
                <label style="color: #000000;">Provincia</label>
                <input type="text"
                      placeholder="Provincia"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
              </div>

              <div class="col-sm-3">
                <label style="color: #000000;">Ciudad</label>
                <input type="text"
                      placeholder="Ciudad"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Calle</label>
                <input type="text"
                      placeholder="Calle"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Número</label>
                <input type="text"
                      placeholder="Número"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Código Postal</label>
                <input type="text"
                      placeholder="Cod Postal"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-sm-4">
                <div class="form-group">
                  <label style="color: #000000;">Fecha máxima de entrega</label>
                  <input type="text"
                          class="form-control"
                          name="fecha_maxima"
                          style="font-size: 12px; margin-top: -5px;">
                </div>
              </div>


              <div class="col-sm-4">
                <div class="form-group">
                  <label style="color: #000000;">Cantidad de artículos a entregar</label>
                  <input type="text"
                         placeholder="0"
                         class="form-control"
                         style="font-size: 12px; margin-top: -5px;">
                </div>
              </div>


              <div class="col-sm-4">
                <div>
                  <label style="color: black;">Orden de entrega</label>
                </div>
                <div>
                  <input type="text"
                      placeholder="Orden"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
                </div>
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1" style="color: black;">Descripción</label>
                  <textarea class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="2"></textarea>
                </div>
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-sm-6">
                <div class="form-group">
                  <label style="color: #000000;">¿Quien recibe en el destino?</label>
                  <input type="text"
                      placeholder="Cargador"
                      class="form-control"
                      style="font-size: 12px; margin-top: -5px;">
                </div>
              </div>

              <div class="col-sm-6">
                <div>
                  <label style="color: #000000;">El punto de descarga cuenta con...</label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                    Muelle
                  </label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                    Transpaleta
                  </label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                    Carretilla elevadora
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div>
        <div class="card">
          <div class="card-body">
            <div class="row mt-2">
              <div class="col-sm-12 text-center">
                <label style="font-size: 17px; color: #0F4C81;"><b>Trayecto Adicional</b></label>
              </div>
            </div>


            <div class="row mt-2">
              <div class="col-sm-6">
                <label style="color: #000000;"><b>Origen</b></label>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3">
                <label style="color: #000000;">Provincia</label>
                <input type="text"
                      placeholder="Provincia"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>

              <div class="col-sm-3">
                <label style="color: #000000;">Ciudad</label>
                <input type="text"
                      placeholder="Ciudad"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Calle</label>
                <input type="text"
                      placeholder="Calle"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Número</label>
                <input type="text"
                      placeholder="Número"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Código Postal</label>
                <input type="text"
                      placeholder="Código Postal"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>
            </div>


            <div class="row mt-4">
              <div class="col-sm-6">
                <label style="color: #000000;"><b>Destino</b></label>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3">
                <label style="color: #000000;">Provincia</label>
                <input type="text"
                      placeholder="Provincia"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>

              <div class="col-sm-3">
                <label style="color: #000000;">Ciudad</label>
                <input type="text"
                      placeholder="Ciudad"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Calle</label>
                <input type="text"
                      placeholder="Calle"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Número</label>
                <input type="text"
                      placeholder="Número"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Código Postal</label>
                <input type="text"
                      placeholder="Código Postal"
                      class="form-control"

                      style="font-size: 12px; margin-top: -5px;"
                      >
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-4">
                <div class="form-group">
                  <label style="color: #000000;">Fecha máxima de entrega</label>
                  <input type="text"
                         class="form-control"
                         style="font-size: 12px; margin-top: -5px;">
                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                  <label style="color: #000000;">Cantidad de artículos a entregar</label>
                  <input type="text"
                        placeholder="0"
                        class="form-control"

                        style="font-size: 12px; margin-top: -5px;"
                        >
                </div>
              </div>

              <div class="col-sm-4">
                <label style="color: black;">Orden de entrega</label>
                <input type="text"
                       placeholder="Orden"
                       class="form-control"
                       style="font-size: 12px; margin-top: -5px;">
              </div>
            </div>


            <div class="row mt-2">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1" style="color: black;">Descripción</label>
                  <textarea class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="2"></textarea>
                </div>
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-sm-6">
                <div class="form-group">
                  <label style="color: #000000;">¿Quien recibe en el destino?</label>
                  <input type="text"
                         placeholder="Cargador"
                         class="form-control"
                         style="font-size: 12px; margin-top: -5px;">
                </div>
              </div>

              <div class="col-sm-6">
                <div>
                  <label style="color: #000000;">El punto de descarga cuenta con...</label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                         type="checkbox" value=""
                         id="flexCheckDefault"
                         >
                  <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                    Muelle
                  </label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                         type="checkbox"
                         id="flexCheckDefault"
                         >
                  <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                    Transpaleta
                  </label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                         type="checkbox"
                         id="flexCheckDefault"
                         >
                  <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                    Carretilla elevadora
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

</ng-container>

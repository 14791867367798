<div class="container-fluid">

  <ngx-spinner type="ball-scale-multiple" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff">
    <p style="font-size: 20px; color: white">Cargando...</p>
  </ngx-spinner>

  <div class="row">
    <div class="col-sm-12">
      <div class="card">
          <div class="card-body border-bottom">
            <!--
            <div class="card-body bodycard">
              <div>
                <img src="assets/images/transfriendly/charger-white.png">
              </div>
            </div>
            -->
            <form class="d-xl-block" [formGroup]="searchCargador">
              <div class="row g-3 mt-2">
                <div class="col-sm-9">
                  <input type="search"
                         class="form-control"
                         id="searchInput"
                         placeholder="Buscar..."
                         formControlName="nombre">
                </div>

                <!-- <div class="col-sm-3">
                  <input type="search"
                         class="form-control"
                         id="searchInput"
                         placeholder="Usuario...">
                </div> -->

                <div class="col-sm-3">
                  <button type="button"
                          class="btn btn-primary w-100"
                          (click)="searchUsuarios()">
                          Filtrar
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body">
              <div class="table-responsive">
                  <table class="table table-bordered align-middle nowrap">
                    <thead>
                      <tr class="text-center">
                        <th scope="col">Id</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Experiencia</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Acción</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr class="text-center" *ngFor="let item of pagina; let i = index">
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.experiencia }}</td>
                        <td>
                          <span [ngClass]="{'activo': item.status === 'Activo', 'nuevo': item.status === 'Nuevo', 'inactivo': item.status === 'Inhabilitado'}">
                            {{ item.status }}
                          </span>
                        </td>
                        <td>
                          <ul class="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View">
                                <a class="btn btn-sm btn-soft-primary"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg">
                                   <i class="mdi mdi-eye-outline"
                                   (click)="largeModal(modalCargador,item.id)"></i>
                                </a>
                            </li>

                            <!-- <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Edit">
                                <a class="btn btn-sm btn-soft-info"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg">
                                   <i class="mdi mdi-pencil-outline"></i>
                                </a>
                            </li> -->

                            <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete">
                                <a class="btn btn-sm btn-soft-danger"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg">
                                   <i class="mdi mdi-close"
                                   (click)="eliminarUsuario(item.id)"></i>
                                </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>

              <div class="row justify-content-between align-items-center">
                <div class="d-flex justify-content-between p-2">
                  <ngb-pagination [collectionSize]="collectionSize"
                                  [(page)]="page"
                                  [pageSize]="pageSize"
                                  (pageChange)="refreshPagina()">
                  </ngb-pagination>

                  <select class="form-select"
                          style="width: auto"
                          [(ngModel)]="pageSize"
                          (ngModelChange)="refreshPagina()">
                    <option [ngValue]="5">5 items por página</option>
                    <option [ngValue]="10">10 items por página</option>
                    <option [ngValue]="25">25 items por página</option>
                    <option [ngValue]="50">50 items por página</option>
                  </select>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalCargador let-modal>
  <div class="modal-header">
    <div class="modal-tags">
      <h5 class="modal-title" [ngClass]="{'sel': tag === 1}" (click)="changeTag(1)" id="myLargeModalLabel">Perfil</h5>
      <h5 class="modal-title" [ngClass]="{'sel': tag === 2}" (click)="changeTag(2)">Gestión de pagos</h5>
      <h5 class="modal-title" [ngClass]="{'sel': tag === 3}" (click)="changeTag(3)">Calificaciones</h5>
      <h5 class="modal-title" [ngClass]="{'sel': tag === 4}" (click)="changeTag(4)">Historial</h5>
    </div>
    <mat-spinner [diameter]="20"
          *ngIf="loading"
          style="display: inline-block;
                 vertical-align: bottom;"></mat-spinner>
    <button *ngIf="!loading" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
  </div>

  <div class="modal-body" style="height: 255px;overflow-y: auto;">
    <div *ngIf="tag === 1">
      <div class="row">
        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Nombre</b></label>
          </div>
          <div>
            <label>{{ usuario?.name }}</label>
          </div>
        </div>

        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Estado</b></label>
          </div>
          <div>
            <span [ngClass]="{'activoSmall': usuario?.status == 'Activo', 'nuevoSmall': usuario?.status == 'Nuevo', 'inactivoSmall': usuario?.status == 'Inhabilitado'}">
              {{ usuario?.status }}
            </span>
          </div>
        </div>

        <div class="col-sm-4">
          <div>
            <label style="color: black;"><b>Correo</b></label>
          </div>
          <div>
            <label>{{ usuario?.email }}</label>
          </div>
        </div>

        <div class="col-sm-2">
          <div>
            <label style="color: black;"><b>CIF/NIF</b></label>
          </div>
          <div>
            <label>{{ usuario?.cif_nif }}</label>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-6">
          <div>
            <label style="color: black;"><b>Dirección</b></label>
          </div>
          <div>
            <label>{{ usuario?.direccion }}</label>
          </div>
        </div>

        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Teléfono</b></label>
          </div>
          <div>
            <label>{{ usuario?.telefono }}</label>
          </div>
        </div>

        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Teléfono (Opcional)</b></label>
          </div>
          <div>
            <label>
              <span *ngIf="usuario?.telefono_opc != null">
                {{ usuario?.telefono_opc }}
              </span>
            </label>
          </div>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-sm-12">
          <div>
            <label style="color: black;"><b>Perfiles activos</b></label>
          </div>

          <div *ngFor="let item of perfiles_activos; let i = index" style="float: left; position: relative;">
            <label *ngIf="item.id_perfil == 2" style="color: white; background-color: #FF6C2B; padding: 4px; margin: 3px; border-radius: 4px;">Cargador</label>
            <label *ngIf="item.id_perfil == 3" style="color: white; background-color: #b93900; padding: 4px; margin: 3px; border-radius: 4px;">Descargador</label>
            <label *ngIf="item.id_perfil == 4" style="color: white; background-color: #0F4C81; padding: 4px; margin: 3px; border-radius: 4px;">Transportista</label>
          </div>
        </div>
      </div>


      <!-- <div class="row mt-5 mb-2">
        <div class="col-sm-3">
          <button type="button" class="btn btn-secondary w-100">
            Rechazar
          </button>
        </div>

        <div class="col-sm-6 mt-3"></div>

        <div class="col-sm-3">
          <button type="button" class="btn btn-primary w-100">
            Aprobar
          </button>
        </div>
      </div> -->
    </div>
    <div *ngIf="tag === 2">
      <form [formGroup]="managePay" >
        <div class="row">
          <div class="col-sm-6">
            <label style="color: black;"><b>¿El usuario es de Pago Verificado?</b></label>
            <ul>
              <li>
                <input formControlName="pagoVerificado" type="radio" value="1" >
                  <label style="color: black;">Sí</label>
              </li>
              <li>
                <input formControlName="pagoVerificado" type="radio" value="2">
                <label style="color: black;">No</label>
              </li>
            </ul>
            <label style="color: black;"><b>¿El usuario es Partner?</b></label>
            <ul>
              <li>
                <input type="radio" formControlName="partner" value="true" >
                  <label style="color: black;">Sí</label>
              </li>
              <li>
                <input type="radio" formControlName="partner" value="false">
                <label style="color: black;">No</label>
              </li>
            </ul>
          </div>

          <div class="col-sm-6">
            <label style="color: black;"><b>Plazos de pago aprobados:</b></label>
            <ul>
              <li *ngFor="let payTime of listPayTimes;">
                <input type="checkbox"
                       [value]="payTime.idtiempopago"
                       (change)="onCheckChange($event)"
                       [checked]="isSelect(payTime.idtiempopago)">
                <label style="color: black;">{{payTime.descripcion}}</label>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-9"></div>
          <div class="col-sm-3">
            <button type="button"
                    class="btn btn-primary w-100"
                    (click)="saveManagePay()"
                    [disabled]="loading">
                    {{(loading)?'Guardando':'Guardar'}}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="tag === 3">
      <div class="score" style="align-items: center;">
        <img class="rounded-circle header-profile-user" src="{{userScore.avatar}}" alt="Header Avatar"
                          style="height: 90px; width: 90px;">
        <div class="score" style="width: 100%;">

          <div style="display: flex; flex-direction: column;">
              <span style="font-size: 1.5em;font-weight: 600;">{{userScore.alias}}</span>
              <span style="font-size: 12px; color: #4A4A4A;"> Basado en {{userScore.total_calificaciones}}
                Calificaciones</span>
              <span>
                <b style="padding-right: 5px;">{{userScore.promedio | number:'1.0-2'}} </b>
                <img style="padding-bottom: 4px;padding-right: 2px;" src="assets/images/transfriendly/star.png" alt="">
                <img style="padding-bottom: 4px;padding-right: 2px;" src="assets/images/transfriendly/star.png" alt="">
                <img style="padding-bottom: 4px;padding-right: 2px;" src="assets/images/transfriendly/star.png" alt="">
                <img style="padding-bottom: 4px;padding-right: 2px;" src="assets/images/transfriendly/star.png" alt="">
                <img style="padding-bottom: 4px;padding-right: 2px;" src="assets/images/transfriendly/star.png" alt="">
              </span>
          </div>
          <div>
            <button type="button" class="filtrar btn btn-primary" (click)="modalScore(); modal.dismiss();">
              Modificar calificaciones
            </button>
          </div>
        </div>
      </div>
      <div class="py-3">
        <b style="font-size: 1.2em;">Comentarios</b>
      </div>
      <div class="score border-bottom py-2" *ngFor="let calificacion of userScore.calificaciones">
        <img class="rounded-circle header-profile-user" src="{{calificacion.avatar}}" alt="Header Avatar"
                          style="height: 90px; width: 90px;">
        <div style="width: 100%;">
          <label>Comentado por {{calificacion.nombre}}</label>
          <div>{{calificacion.descripcion}}</div>
        </div>
      </div>

    </div>
    <div *ngIf="tag === 4">
      <div class="contTable">
        <table mat-table [dataSource]="dataSourceOfferHistory" matSort>
          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef> Código </th>
            <td mat-cell *matCellDef="let offer"> {{offer.codigo}} </td>
          </ng-container>
          <ng-container matColumnDef="origen_destino">
            <th mat-header-cell *matHeaderCellDef> Origen - Destino </th>
            <td mat-cell *matCellDef="let offer"> {{ offer.ciudad_origen }} - {{ offer.ciudad_destino }} </td>
          </ng-container>
          <ng-container matColumnDef="fecha_viaje">
            <th mat-header-cell *matHeaderCellDef> Fecha viaje / salida </th>
            <td mat-cell *matCellDef="let offer"> {{ offer.fecha_viaje | date:'dd/MM/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef> Monto </th>
            <td mat-cell *matCellDef="let offer"> {{ offer.monto * 1.1 | formatNumberPipe }} € </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsOfferHistory"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsOfferHistory;"></tr>
        </table>
      </div>
      <mat-paginator *ngIf="listOfferHistory.length>0"
        [length]="listOfferHistory.length"
        [pageSize]="10"
        [pageIndex]="pageHistory"
        (page)="loadPage($event)">
      </mat-paginator>
    </div>
  </div>
</ng-template>

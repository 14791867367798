export const environment =
{
  production: false,
  defaultauth: 'fackbackend',

  //*******************Desarrollo************************
  //apiUrl: 'https://trans-back-test.smallshi.com/api',
  //keyPusher:'c0a1a153a1de3622dae2',

  //*******************Produccion************************
  apiUrl: 'https://back.transfriendly.es/api',
  keyPusher:'454994e430104bc9c880',

  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

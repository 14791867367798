<div class="dialog-top">
  <mat-spinner [diameter]="20"
          *ngIf="loading"
          style="display: inline-block;
                 vertical-align: bottom;"></mat-spinner>
  <span *ngIf="!loading" class="mdi mdi-close" (click)="close()"></span>
</div>
<div style="max-height: 70vh; overflow-y:auto; overflow-x:hidden;">
  <div style="width: 400px;padding: 0 30px">
    <div style="text-align: center;font-size: 1.3em;margin-bottom: 2em;">
      <div *ngIf="isAdmin" style="margin-bottom: 0.5rem;">El pago de este viaje fue de</div>
      <div *ngIf="!isAdmin" style="margin-bottom: 0.5rem;">El pago recibido de este viaje fue de</div>
      <div class="amount">{{get('monto') | formatNumberPipe }}€</div>

      <div *ngIf="isAdmin">El monto de la puja contratada es </div>
      <div *ngIf="isAdmin" class="amount">{{get('puja') | formatNumberPipe }}€</div>
    </div>
    <!-- <div class="score">
      <span style="border-right: 3px solid #D9D9D9">
        <div class="title">Duración</div>
        <span class="quatity">
          <span>04</span>
          <label>Días</label>
        </span>
        <span class="quatity"><span>:</span></span>
        <span class="quatity">
          <span>08</span>
          <label>Horas</label>
        </span>
      </span>
      <span>
        <div class="title">Distancia</div>
        <span class="quatity">
          <span>350</span>
          <label>Kilometros</label>
        </span>
      </span>
    </div> -->
    <dl>
      <dt>Código del anuncio</dt>
      <dd>{{get('codigo_anuncio')}}</dd>
      <dt>Fecha de salida</dt>
      <dd>{{get('fechasalida') | date:'dd/MMM/yyyy'}}</dd>
      <dt>Método de pago</dt>
      <dd><img style="margin-right: 5px;"
        src="assets/images/transfriendly/transfer.png">
        {{get('forma_pago')}}</dd>
      <dt>Tipo de viaje</dt>
      <dd>{{get('tipo_viaje')}}</dd>
      <dt>Orden de inicio</dt>
      <dd><ul>
        <li *ngFor="let file of dataOffer?.info_archivo; let idx = index">
          <a *ngIf="file.orden_inicio.length > 0" href="{{file.orden_inicio}}" target="_blank">Orden inicio {{idx+1}}</a>
        </li>
      </ul></dd>
      <dt>Orden de llegada</dt>
      <dd><ul>
        <li *ngFor="let file of dataOffer?.info_archivo ; let idx = index">
          <a *ngIf="file.orden_llegada.length > 0" href="{{file.orden_llegada}}" target="_blank">Orden llegada {{idx+1}}</a>
        </li>
      </ul></dd>
    </dl>
  </div>
</div>
<div *ngIf="get('incidencia')" style="padding: 1em 30px; color:red">
  Incidencia: {{get('incidencia')}}
</div>

<mat-dialog-actions style="padding: 0 30px 19px 30px;" *ngIf="false">
  <div class="actions">
      <div>
        <button type="button" style="width: 100%;" class="btn btn-primary" (click)="save()">
          Descargar todo
        </button>
      </div>
      <div>
        <button type="button" style="width: 100%;" class="btn btn-secondary" (click)="close()">
          Descargar documentos
        </button>
      </div>
      <div *ngIf="footer" style="text-align: center;">
        ¿Tienes algún inconveniente? <a href="">contactar con soporte</a>
      </div>
  </div>
</mat-dialog-actions>

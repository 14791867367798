import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { SharingService } from '../../core/services/sharing.service';

@Component({
  selector: 'app-articulos-editar',
  templateUrl: './articulos-editar.component.html',
  styleUrls: ['./articulos-editar.component.scss']
})

export class ArticulosEditarComponent implements OnInit {
  @Input() formGroupName: string;

  public enviar: FormGroup;
  public tipoCarga:number;
  public jaulaCantidad:number = 0;
  public frigorifico:number = 0;
  public cantidad:number;
  public porcentaje_ocupado:number = 0;

  constructor(private formGroupDirective: FormGroupDirective, public sharingService:SharingService)
  {
    this.sharingService.tipoViaje;
    this.sharingService.ocupacionInfo;
  }

  ngOnInit(): void {
    this.enviar = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
    this.tipoCarga = this.enviar.get('tipo_carga').value;
    this.iniciar();
    console.log('this.sharingService.selectCheckbox',this.sharingService.selectCheckbox);
    console.log('this.sharingService.selectCheckbox',this.sharingService.ocupacionInfo);
  }


  public iniciar(){
    if(this.sharingService.selectCheckbox){
      switch (this.tipoCarga){
        case 1: this.removeValidatorCargaLiquida(); break;
        case 2: this.removeValidatorPaletizados(); break;
        case 3: this.removeValidatorFrigorificoCalorifico(); break;
        case 4: this.removeValidatorTolva(); break;
        case 5: this.removeValidatorJaula(); break;
        case 6: this.removeValidatorcamionEspeciales(); break;
      }
    }
    else
    {
      switch (this.tipoCarga){
        case 1: this.addValidatorCargaLiquida(); break;
        case 2: this.addValidatorPaletizados(); break;
        case 3: this.addValidatorFrigorificoCalorifico(); break;
        case 4: this.addValidatorTolva(); break;
        case 5: this.addValidatorJaula();  break;
        case 6: this.addValidatorcamionEspeciales(); break;
      }

    }
  }


  public selectCheckboxAction(){
    this.sharingService.selectCheckbox=!this.sharingService.selectCheckbox;
    if(this.sharingService.selectCheckbox)
    {
      this.porcentaje_ocupado = 0;
    }

    switch (this.tipoCarga){
      case 1: this.removeValidatorCargaLiquida(); break;
      case 2: this.removeValidatorPaletizados(); break;
      case 3: this.removeValidatorFrigorificoCalorifico(); break;
      case 4: this.removeValidatorTolva(); break;
      case 5: this.removeValidatorJaula(); break;
      case 6: this.removeValidatorcamionEspeciales(); break;
    }
  }

  public removeValidatorJaula(){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    if(this.sharingService.selectCheckbox){
        doc.get('descripcion_jaula').clearValidators();
        doc.get('cantidad_articulos').clearValidators();
        doc.get('ocupacion_completa').setValue(1);
        doc.get('descripcion_jaula').updateValueAndValidity()
        doc.get('cantidad_articulos').updateValueAndValidity()

    }else{
      doc.get('descripcion_jaula').addValidators([Validators.required]);
      doc.get('cantidad_articulos').addValidators([Validators.required, Validators.min(1)]);
      doc.get('ocupacion_completa').setValue(0);
      doc.get('descripcion_jaula').updateValueAndValidity()
      doc.get('cantidad_articulos').updateValueAndValidity()
    }
  }

  public removeValidatorTolva(){

    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    if(this.sharingService.selectCheckbox){

        doc.get('peso_articulo').clearValidators();
        doc.get('volumen_tolva').clearValidators();
        doc.get('alimentario').clearValidators();
        doc.get('ocupacion_completa').setValue(1);
        doc.get('peso_articulo').updateValueAndValidity()
        doc.get('volumen_tolva').updateValueAndValidity()
        doc.get('alimentario').updateValueAndValidity()

    }
    else
    {
      doc.get('peso_articulo').addValidators([Validators.required]);
      doc.get('volumen_tolva').addValidators([Validators.required]);
      doc.get('alimentario').addValidators([Validators.required]);
      doc.get('ocupacion_completa').setValue(0);
      doc.get('peso_articulo').updateValueAndValidity()
      doc.get('volumen_tolva').updateValueAndValidity()
      doc.get('alimentario').updateValueAndValidity()

    }
  }

  public removeValidatorFrigorificoCalorifico(){

    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    if(this.sharingService.selectCheckbox){

        doc.get('peso_art').clearValidators();
        doc.get('largo').clearValidators();
        doc.get('ancho').clearValidators();
        doc.get('alto').clearValidators();
        doc.get('cant_articulos').clearValidators();
        doc.get('ocupacion_completa').setValue(1);
        doc.get('peso_art').updateValueAndValidity()
        doc.get('largo').updateValueAndValidity()
        doc.get('ancho').updateValueAndValidity()
        doc.get('cant_articulos').updateValueAndValidity()
        doc.get('alto').updateValueAndValidity()

    }
    else
    {
        doc.get('peso_art').addValidators([Validators.required]);
        doc.get('largo').addValidators([Validators.required]);
        doc.get('ancho').addValidators([Validators.required]);
        doc.get('cant_articulos').addValidators([Validators.required, Validators.min(1)]);
        doc.get('alto').addValidators([Validators.required]);
        doc.get('ocupacion_completa').setValue(0);
        doc.get('peso_art').updateValueAndValidity()
        doc.get('largo').updateValueAndValidity()
        doc.get('ancho').updateValueAndValidity()
        doc.get('cant_articulos').updateValueAndValidity()
        doc.get('alto').updateValueAndValidity()
    }
  }

  public removeValidatorcamionEspeciales(){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    if(this.sharingService.selectCheckbox){
          doc.get('descripcion_especiales').clearValidators();
          doc.get('ocupacion_completa').setValue(1);
          doc.get('descripcion_especiales').updateValueAndValidity()

      }else{
        doc.get('descripcion_especiales').addValidators([Validators.required]);
        doc.get('ocupacion_completa').setValue(0);
        doc.get('descripcion_especiales').updateValueAndValidity()
      }
  }

  public removeValidatorCargaLiquida(){

    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    if(this.sharingService.selectCheckbox){

          doc.get('volumen').clearValidators();
          doc.get('unidad_medida').clearValidators();
          doc.get('descripcion').clearValidators();
          doc.get('ocupacion_completa').setValue(1);
          doc.get('volumen').updateValueAndValidity()
          doc.get('unidad_medida').updateValueAndValidity()
          doc.get('descripcion').updateValueAndValidity()

      }else{
        doc.get('volumen').addValidators([Validators.required]);
        doc.get('unidad_medida').addValidators([Validators.required, Validators.min(1)]);
        doc.get('descripcion').addValidators([Validators.required]);
        doc.get('ocupacion_completa').setValue(0);
        doc.get('volumen').updateValueAndValidity()
        doc.get('unidad_medida').updateValueAndValidity()
        doc.get('descripcion').updateValueAndValidity()
      }


  }

  public addValidatorCargaLiquida(){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    doc.get('volumen').addValidators([Validators.required]);
    doc.get('unidad_medida').addValidators([Validators.required, Validators.min(1)]);
    doc.get('descripcion').addValidators([Validators.required]);

    if(this.sharingService.selectCheckbox)
    { doc.get('ocupacion_completa').setValue(1); }
    else
    { doc.get('ocupacion_completa').setValue(0); }

    doc.get('volumen').updateValueAndValidity()
    doc.get('unidad_medida').updateValueAndValidity()
    doc.get('descripcion').updateValueAndValidity()
  }


  public addValidatorPaletizados(){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    doc.get('peso_artic').addValidators([Validators.required, Validators.min(1)]);
    doc.get('lar').addValidators([Validators.required, Validators.min(1)]);
    doc.get('anch').addValidators([Validators.required, Validators.min(1)]);
    doc.get('alt').addValidators([Validators.required, Validators.min(1)]);
    doc.get('canti_art').addValidators([Validators.required, Validators.min(1)]);
    doc.get('cantidad_apilable').addValidators([Validators.required]);

    if(this.sharingService.selectCheckbox)
    { doc.get('ocupacion_completa').setValue(1); }
    else
    { doc.get('ocupacion_completa').setValue(0); }

    doc.get('peso_artic').updateValueAndValidity()
    doc.get('lar').updateValueAndValidity()
    doc.get('anch').updateValueAndValidity()
    doc.get('alt').updateValueAndValidity()
    doc.get('canti_art').updateValueAndValidity()
    doc.get('cantidad_apilable').updateValueAndValidity()
  }

  public addValidatorFrigorificoCalorifico(){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    doc.get('peso_art').addValidators([Validators.required]);
    doc.get('largo').addValidators([Validators.required]);
    doc.get('ancho').addValidators([Validators.required]);
    doc.get('cant_articulos').addValidators([Validators.required, Validators.min(1)]);
    doc.get('alto').addValidators([Validators.required]);

    if(this.sharingService.selectCheckbox)
    { doc.get('ocupacion_completa').setValue(1); }
    else
    { doc.get('ocupacion_completa').setValue(0); }

    doc.get('peso_art').updateValueAndValidity()
    doc.get('largo').updateValueAndValidity()
    doc.get('ancho').updateValueAndValidity()
    doc.get('cant_articulos').updateValueAndValidity()
    doc.get('alto').updateValueAndValidity()
  }

  public addValidatorTolva(){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    doc.get('peso_articulo').addValidators([Validators.required]);
    doc.get('volumen_tolva').addValidators([Validators.required]);
    doc.get('alimentario').addValidators([Validators.required]);

    if(this.sharingService.selectCheckbox)
    { doc.get('ocupacion_completa').setValue(1); }
    else
    { doc.get('ocupacion_completa').setValue(0); }

    doc.get('peso_articulo').updateValueAndValidity();
    doc.get('volumen_tolva').updateValueAndValidity();
    doc.get('alimentario').updateValueAndValidity();
  }

  public addValidatorJaula(){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    doc.get('descripcion_jaula').addValidators([Validators.required]);
    doc.get('cantidad_articulos').addValidators([Validators.required, Validators.min(1)]);

    if(this.sharingService.selectCheckbox)
    { doc.get('ocupacion_completa').setValue(1); }
    else
    { doc.get('ocupacion_completa').setValue(0); }


    doc.get('descripcion_jaula').updateValueAndValidity()
    doc.get('cantidad_articulos').updateValueAndValidity()
  }

  public addValidatorcamionEspeciales(){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    doc.get('descripcion_especiales').addValidators([Validators.required]);

    if(this.sharingService.selectCheckbox)
    { doc.get('ocupacion_completa').setValue(1); }
    else
    { doc.get('ocupacion_completa').setValue(0); }

    doc.get('descripcion_especiales').updateValueAndValidity()
  }


  public removeValidatorPaletizados(){

    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    if(this.sharingService.selectCheckbox){

          doc.get('peso_artic').clearValidators();
          doc.get('lar').clearValidators();
          doc.get('anch').clearValidators();
          doc.get('alt').clearValidators();
          doc.get('canti_art').clearValidators();
          doc.get('cantidad_apilable').clearValidators();
          doc.get('ocupacion_completa').setValue(1);
          doc.get('peso_artic').updateValueAndValidity()
          doc.get('lar').updateValueAndValidity()
          doc.get('anch').updateValueAndValidity()
          doc.get('alt').updateValueAndValidity()
          doc.get('canti_art').updateValueAndValidity()
          doc.get('cantidad_apilable').updateValueAndValidity()

      }else{
        doc.get('peso_artic').addValidators([Validators.required, Validators.min(1)]);
        doc.get('lar').addValidators([Validators.required, Validators.min(1)]);
        doc.get('anch').addValidators([Validators.required, Validators.min(1)]);
        doc.get('alt').addValidators([Validators.required, Validators.min(1)]);
        doc.get('canti_art').addValidators([Validators.required, Validators.min(1)]);
        doc.get('cantidad_apilable').addValidators([Validators.required]);
        doc.get('ocupacion_completa').setValue(0);
        doc.get('peso_artic').updateValueAndValidity()
        doc.get('lar').updateValueAndValidity()
        doc.get('anch').updateValueAndValidity()
        doc.get('alt').updateValueAndValidity()
        doc.get('canti_art').updateValueAndValidity()
        doc.get('cantidad_apilable').updateValueAndValidity()
      }
  }

  addFoto(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('addFoto').setValue(event.target.files[0]);
      doc.get('nombreAddFoto').setValue(event.target.files[0].name);
    }
  }

  invalidateCantidad(nameFormControl: string){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    let valida = { value: false };
    if(doc.get(nameFormControl)?.touched && doc.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }

  invalidate(nameFormControl: string){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    let valida = { value: false };
    if((doc.get(nameFormControl)?.touched ||
        doc.get(nameFormControl)?.dirty) &&
        doc.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }

  fileFotoCarga($event){}

  fileaddDocumento(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('addDocumento').setValue(event.target.files[0]);
      doc.get('nameAddDocumento').setValue(event.target.files[0].name);
    }
  }

  addFotoCarga(event){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[0] as FormGroup;
      doc.get('foto_carga').setValue(event.target.files[0]);
      doc.get('name_foto_carga').setValue(event.target.files[0].name);
    }
  }


  addFrigorificoCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.frigorifico = doc.get('cant_articulos').value;
    this.frigorifico++;
    doc.get('cant_articulos').setValue(this.frigorifico);
  }


  minusFrigorificoCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.frigorifico = doc.get('cant_articulos').value;
    if(this.frigorifico > 0){
      this.frigorifico--;
      doc.get('cant_articulos').setValue(this.frigorifico);
    }
  }

  //******************************************



  cerrar(indice:number){
    (<FormArray>this.enviar.get('articulos')).removeAt(indice)
  }


  addJaulaCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.jaulaCantidad = doc.get('cantidad_articulos').value;
    this.jaulaCantidad++
    doc.get('cantidad_articulos').setValue(this.jaulaCantidad);
  }


  minusJaulaCantidad(){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[0] as FormGroup;
    this.jaulaCantidad = doc.get('cantidad_articulos').value;
    if(this.jaulaCantidad > 0){
      this.jaulaCantidad--;
      doc.get('cantidad_articulos').setValue(this.jaulaCantidad);
    }
  }

  addCantidad(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    this.cantidad = doc.get('canti_art').value;
    this.cantidad++;
    doc.get('canti_art').setValue(this.cantidad);

  }

  minusCantidad(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    this.cantidad = doc.get('canti_art').value;
    if(this.cantidad > 0){
      this.cantidad--;
      doc.get('canti_art').setValue(this.cantidad);

    }
  }

  fileFotodeCarga(event,indice:number){
    if(event.target.files.length > 0){
      let documento = this.enviar.get('articulos') as FormArray;
      let doc = documento.controls[indice] as FormGroup;
      doc.get('foto_de_carga').setValue(event.target.files[0]);
      doc.get('name_foto_de_carga').setValue(event.target.files[0].name);
    }
  }

  addEURPallet1(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(120);
    doc.get('anch').setValue(80);
    doc.get('alt').setValue(0);
    doc.get('seleccionPaleta').setValue(1);
  }

  addEURPallet3(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(120);
    doc.get('anch').setValue(100);
    doc.get('alt').setValue(0);
    doc.get('seleccionPaleta').setValue(2);
  }


  addEURPallet6(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(80);
    doc.get('anch').setValue(60);
    doc.get('alt').setValue(0);
    doc.get('seleccionPaleta').setValue(3);
  }

  cajaMudanza(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    doc.get('lar').setValue(75);
    doc.get('anch').setValue(35);
    doc.get('alt').setValue(35);
    doc.get('seleccionPaleta').setValue(4);
  }

  paletaSeleccionada(indice:number){
    let paleta = { value: 0 };
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    paleta.value = doc.controls['seleccionPaleta'].value
    return paleta
  }

//*********************************************************************

  invalidateSelect(nameFormControl: string){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[0] as FormGroup;

    let valida = { value: false };
    if(doc.get(nameFormControl)?.touched && doc.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }


  invalidatePaleta(nameFormControl: string, indice:number){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[indice] as FormGroup;

    let valida = { value: false };
    if((doc.get(nameFormControl)?.touched ||
        doc.get(nameFormControl)?.dirty) &&
        doc.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }


  invalidateCantidadPaleta(nameFormControl: string, indice:number){
    let camion = this.enviar.get('articulos') as FormArray;
    let doc = camion.controls[indice] as FormGroup;

    let valida = { value: false };
    if(doc.get(nameFormControl)?.touched && doc.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }

  //*********************************************************************

  apilable(indice:number){
    let documento = this.enviar.get('articulos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    let valor = doc.get('noapilable').value
    if(valor == true){
      doc.get('cantidad_apilable').disable();
    }
    else
    { doc.get('cantidad_apilable').enable(); }
  }

  //*********************************************************************

  addContenedorLiquido(){}

}

<div class="container-fluid">

  <ngx-spinner type="ball-scale-multiple" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff">
    <p style="font-size: 20px; color: white">Cargando...</p>
  </ngx-spinner>

  <div class="row">
    <div class="col-12">
      <div class="card">
          <div class="card-body border-bottom">
            <form class="d-xl-block" [formGroup]="searchAnticipado">
              <div class="row g-3">
                <div class="col-sm-6">
                  <input type="search"
                         class="form-control"
                         id="searchInput"
                         placeholder="Buscar..."
                         formControlName="nombre">
                </div>

                <div class="col-sm-3">
                  <input type="search"
                         class="form-control"
                         id="searchInput"
                         placeholder="Código"
                         formControlName="codigo">
                </div>

                <div class="col-sm-3">
                  <button type="button"
                          class="btn btn-primary w-100"
                          (click)="searchOffers()">
                          Filtrar
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body">
              <div class="table-responsive">
                  <table class="table table-bordered align-middle nowrap">
                    <thead>
                      <tr class="text-center">
                        <th scope="col">Cargador</th>
                        <th scope="col">Fecha de inicio del viaje</th>
                        <th scope="col">ID de la propuesta</th>
                        <th scope="col">Método de pago</th>
                        <th scope="col">Acción</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr class="text-center" *ngFor="let item of pagina; let i = index">
                        <td>{{item.nombre_cargador}}</td>
                        <td>{{item.fecha_viaje | date:'dd/MM/yyyy'}}</td>
                        <td>{{item.codigo_anuncio}}</td>
                        <td>{{item.descripcion}}</td>
                        <td>
                          <ul class="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View">
                                <a class="btn btn-sm btn-soft-primary2"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg"
                                   (click)="validarUsuario(item.id)">
                                   <i class="mdi mdi-check"></i>
                                </a>
                            </li>

                            <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Delete">
                                <a class="btn btn-sm btn-soft-danger"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg"
                                   (click)="invalidarUsuario(item.id)">
                                   <i class="mdi mdi-close"></i>
                                </a>
                            </li>

                            <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Ver anuncio">
                                <a class="btn btn-sm btn-soft-primary"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg"
                                   (click)="viewModalAnuncio(item)">
                                   <i class="mdi mdi-eye-outline"></i>
                                </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>

              <div class="row justify-content-between align-items-center">
                <div class="d-flex justify-content-between p-2">
                  <ngb-pagination [collectionSize]="collectionSize"
                                  [(page)]="page"
                                  [pageSize]="pageSize"
                                  (pageChange)="refreshPagina()">
                  </ngb-pagination>

                  <select class="form-select"
                          style="width: auto"
                          [(ngModel)]="pageSize"
                          (ngModelChange)="refreshPagina()">
                    <option [ngValue]="5">5 items por página</option>
                    <option [ngValue]="10">10 items por página</option>
                    <option [ngValue]="25">25 items por página</option>
                    <option [ngValue]="50">50 items por página</option>
                  </select>
                </div>
              </div>



          </div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #verPagoAnticipado let-modal>
  <div class="modal-header">
      <h5 style="color: #FF6C2B;" class="modal-title" id="myLargeModalLabel">Detalles del Registro</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Nombre</b></label>
        </div>
        <div>
          <label></label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Estado</b></label>
        </div>
        <div>
          <span style="color: #23BB87; background-color: #CEFFDF; padding: 6px; border-radius: 4px;">

          </span>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Correo</b></label>
        </div>
        <div>
          <label></label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>CIF/NIF</b></label>
        </div>
        <div>
          <label></label>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Dirección</b></label>
        </div>
        <div>
          <label></label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Teléfono</b></label>
        </div>
        <div>
          <label></label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Teléfono (Opcional)</b></label>
        </div>
        <div>
          <label></label>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Perfil para aprobación</b></label>
        </div>
        <div>

        </div>
      </div>

      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Perfiles activos</b></label>
        </div>
        <div>
          <label style="color: white; background-color: #FF6C2B; padding: 4px; margin: 3px; border-radius: 4px;">Cargador</label>
          <label style="color: white; background-color: #0F4C81; padding: 4px; margin: 3px; border-radius: 4px;">Transportista</label>
        </div>
      </div>
    </div>


    <div class="row mt-5 mb-2">
      <div class="col-sm-3">
        <button type="button" class="btn btn-secondary w-100" (click)="cerrar()">
          Cerrar
        </button>
      </div>

      <div class="col-sm-6 mt-3"></div>

      <div class="col-sm-3">
        <button type="button" class="btn btn-primary w-100">
          Aprobar
        </button>
      </div>
    </div>
  </div>
</ng-template> -->

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, forkJoin, map, of } from 'rxjs';


const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})


export class ServicesService {

  constructor(public httpClient:HttpClient){}

  getListInProcess(paramSearch:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    return this.httpClient.post(`${API_URL}/general/getviajesproceso`,
    {...paramSearch, idtransportista:dataLocal.id});
  }

  queryDriver(idcontratacion:number){
    return this.httpClient.post(`${API_URL}/general/getconductorcontrato`,{idcontratacion})
  }

  setDriver(data){
    return this.httpClient.post(`${API_URL}/anuncio/dataconductor`,data)
  }

  searchDriver(data){
    return this.httpClient.post(`${API_URL}/general/conductor`,data)
  }

  headerTransportista(){
    let dataLocal = JSON.parse(localStorage.getItem('data'))
    return this.httpClient.post(`${API_URL}/general/headerTransportista`,{idusuario:dataLocal.id})
  }

  getListOffers(paramSearch:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    return this.httpClient.post(
      `${API_URL}/general/anunciosdisponibles`,
      {...paramSearch,idtransportista:dataLocal.id})
  }

  getListTypeVehicles(){
    return this.httpClient.get(`${API_URL}/general/tipotransporte`);
  }

  getPuja(dataPuja:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    if(dataPuja.id_anuncio) dataPuja.id_usuario = dataLocal.id;
    return this.httpClient.post(
      `${API_URL}/anuncio/getPujaById`,
      dataPuja)
  }

  sendPuja(dataPuja:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    dataPuja.id_usuario = dataLocal.id;
    // console.log(dataPuja);
    return this.httpClient.post(
      `${API_URL}/anuncio/realizarPuja`,
      dataPuja)
  }

  deletePuja(dataPuja:any){
    return this.httpClient.post(
      `${API_URL}/anuncio/eliminarPuja`,
      dataPuja)
  }

  headerCargador(){
    let dataLocal = JSON.parse(localStorage.getItem('data'))
    return this.httpClient.post(`${API_URL}/general/headerCargador`,{idusuario:dataLocal.id})
  }

  consultaanunciocargador(data){
    return this.httpClient.post(`${API_URL}/anuncio/consultaanunciocargador`,data)
  }

  consultaCalificaciones(idusuario:number){
    return this.httpClient.post(`${API_URL}/consultarCalificacion`,{idusuario})
  }

  getListFinishedUnloads(idusuario:number){
    return this.httpClient.post(`${API_URL}/general/descargasrealizadas`,{idusuario})
  }

  consultaanunciocontratado(data){
    return this.httpClient.post(`${API_URL}/anuncio/consultaanunciocontratado`,data)
  }

  saveOffer(dataOffer:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    dataOffer.id_transportista = dataLocal.id;
    return this.httpClient.post(
      `${API_URL}/anuncio/agregarFav`,
      dataOffer);
  }

  getListOffersSave(paramSearch:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'))
    return this.httpClient.post(
      `${API_URL}/anuncio/anunciosFav`,
      {...paramSearch, idtransportista:dataLocal.id})
  }

  getCargadorListPujas(data){
    return this.httpClient.post(
      `${API_URL}/anuncio/consultaPuja`,
      data);
  }

  contratarTransportista(data){
    return this.httpClient.post(
      `${API_URL}/anuncio/contratar`,
      data);
  }

  getDataAnuncio(data){
    return this.httpClient.post(
      `${API_URL}/anuncio/anunciofull`,
      data);
  }

  getListFinishedOffers(data){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    data['idtransportista'] = (data.idUser)?data.idUser:dataLocal.id;
    delete data.idUser;
    return this.httpClient.post(
      `${API_URL}/general/getviajesrealizados`,
      data);
  }

  // --- Chat

  getChats(){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    const {id, perfil} = dataLocal;
    if(perfil == 2){ // Cargador
      return this.httpClient.post(
              `${API_URL}/chat/listchats`,
              {idusuario:id});
    } else if(perfil == 4){ // Transportista
      return this.httpClient.post(
        `${API_URL}/chat/listchatstransporte`,
        {idusuario:id});
    }

  }

  loadChat(data){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    data.id_user_activa = dataLocal.id;
    return this.httpClient.post(
      `${API_URL}/chat/chatwhitout`,
      data);
  }

  sendMessage(data){
    return this.httpClient.post(
      `${API_URL}/chat/mensaje`,
      data);
  }

  createChat(data){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    data.id_from_user = dataLocal.id;
    return this.httpClient.post(
      `${API_URL}/chat/createchat`,
      data);
  }

  checkChat(data){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    data.id_to_user = dataLocal.id;
    return this.httpClient.post(
      `${API_URL}/chat/consultasalatransporte`,
      data);
  }

  readedChat(id_sala:number){
    return this.httpClient.post(
      `${API_URL}/chat/leido`,
      {id_sala});
  }

  // ---- Descargador

  headerDescargador(){
    let dataLocal = JSON.parse(localStorage.getItem('data'))
    return this.httpClient.post(`${API_URL}/general/headerDescargador`,{idusuario:dataLocal.id})
  }

  getUnloadingRequests(paramSearch:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    return this.httpClient.post(
      `${API_URL}/general/listsolicituddescarga`,
      {...paramSearch,idusuario:dataLocal.id})
  }

  getNextUnloadings(paramSearch:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    return this.httpClient.post(
      `${API_URL}/general/proximasdescargas`,
      {...paramSearch,idusuario:dataLocal.id})
  }

  getFinishUnloadings(paramSearch:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    return this.httpClient.post(
      `${API_URL}/general/descargasrealizadas`,
      {...paramSearch,idusuario:dataLocal.id})
  }

  setAcceptUnloadingRequests(data){
    data.gestion=1;
    return this.httpClient.post(
      `${API_URL}/general/gestionadescarga`,data)
  }

  setDeclineUnloadingRequests(data){
    data.gestion=2;
    return this.httpClient.post(
      `${API_URL}/general/gestionadescarga`,data)
  }

  getModalUnload(data:any){
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    return this.httpClient.post(
      `${API_URL}/anuncio/modaldescargador`,
      {...data,idusuario:dataLocal.id})
  }


  // ------

  // ---- Dashboard

  getListPujas(){
    return this.httpClient.get(`${API_URL}/anuncio/consultaPujaAll`);
  }

  getListEstados(){
    return this.httpClient.get(`${API_URL}/general/getEstados`);
  }

  getListTotalTravels(data):Observable<any>{
    return this.httpClient.post(`${API_URL}/anuncio/anuncios`, data);
  }

  getListTransportistas(data):Observable<any>{
    return this.httpClient.post(`${API_URL}/general/getviajesprocesoadmin`, data);
  }

  getAnuncioFinalizado(data):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/modalanuncio`, data);
  }

  getDataStatistics(mes:string="",mes_usuarios:string=""):Observable<any>{
    let data = {mes, mes_usuarios};
    return this.httpClient.post<any>(`${API_URL}/estadisticasAdmin`, data);
  }

  // ------

  sumacubitaje(data):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/sumacubitaje`, data);
  }

  /*
  restacubitaje(data):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/restacubitaje`, data);
  }
  */

  calificar(idUsuario:number, id_anuncio:number, calificacion:number, comentario:string, opciones:any):Observable<any>{
    let dataLocal = JSON.parse(localStorage.getItem('data'));
    const data = { "id_usuario": idUsuario,
                   "id_anuncio": id_anuncio,
                   "calificacion": calificacion,
                   "opcion": opciones,
                   "comentario": comentario,
                   "calificador": dataLocal.id
                 }
    return this.httpClient.post<any>(`${API_URL}/general/calificar`, data);
  }

  notificaciones(idUsuario:number):Observable<any>{
    const data = { "idusuario": idUsuario }
    return this.httpClient.post<any>(`${API_URL}/notificaciones/notificaciones`, data);
  }

  getNoLeidos(idUsuario:number):Observable<any>{
    const data = { "idusuario": idUsuario }
    return this.httpClient.post<any>(`${API_URL}/notificaciones/countnotificaciones`, data);
  }

  setReaded(idNotify:number){
    return this.httpClient.post<any>(
      `${API_URL}/notificaciones/readnotify`, {"idnotificacion":idNotify});
  }

  getMsgNoLeidos(idUsuario:number):Observable<any>{
    const data = { "idusuario": idUsuario }
    return this.httpClient.post<any>(`${API_URL}/notificaciones/countMessages`, data);
  }

  exportexcel(tabla:any, titulo:string):Observable<any>{
    const data = { "titulo": titulo, "tabla": tabla }
    return this.httpClient.post<any>(`${API_URL}/general/exportexcel`, data);
  }

  eliminarUsuario(idUsuario:number):Observable<any>{
    const data = { "idusuario": idUsuario }
    return this.httpClient.post<any>(`${API_URL}/eliminarUsuario`, data);
  }

  deleteanuncio(idanuncio:number):Observable<any>{
    const data = { "idanuncio": idanuncio }
    return this.httpClient.post<any>(`${API_URL}/anuncio/deleteanuncio`, data);
  }

  duplicarAnuncio(idanuncio:number):Observable<any>{
    const data = { "idanuncio": idanuncio }
    return this.httpClient.post<any>(`${API_URL}/anuncio/duplicarAnuncio`, data);
  }

  eliminarAnuncioContra(idanuncio:number):Observable<any>{
    const data = { "idanuncio": idanuncio }
    return this.httpClient.post<any>(`${API_URL}/anuncio/eliminarAnuncioContra`, data);
  }

  tipotransporte():Observable<any>{
    return this.httpClient.get(`${API_URL}/general/tipotransporte`)
  }

  descargadores(idusuario:number, cod_postal?:number, iddescargador?: any):Observable<any>{
    const data = { "idusuario" : idusuario, "cod_postal":cod_postal, "iddescargador": iddescargador }
    return this.httpClient.get<any>(`${API_URL}/general/descargadores`, { params: data });
  }

  consultaanuncio(idanuncio:number):Observable<any>{
    const data = { "idanuncio": idanuncio, "estatus":0 }
    return this.httpClient.post<any>(`${API_URL}/anuncio/consultaanuncio`, data);
  }

  sendDocsRequest(idUsuario:number):Observable<any>{
    return this.httpClient.post<any>(
        `${API_URL}/notifTransportista`,
        {"id_transportista":idUsuario});
  }

  downloadObsfile(file:string):Observable<any>{
    return this.httpClient.get(
      `${API_URL}/general/downloadimages?ruta=` + file, { responseType: 'blob' }).pipe(
        map((blob: Blob) => {
          return {
            nombre: file.substring(file.lastIndexOf('/') + 1),
            blob: blob
          };
        })
      );
  }

  registerEditInfoGeneral(data: any):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/addupdate`, data);
  }

  editarArticulos(data: any):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/articulosupdate`, data);
  }

  editarTrayecto(data: any):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/anuncio/trayectosupdate`, data);
  }

  solicitarRol(data):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/general/solicitarRol`, data);
  }

  perfilesdelusuario(data):Observable<any>{
    return this.httpClient.post<any>(`${API_URL}/general/perfilesdelusuario`, data);
  }

}

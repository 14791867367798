import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIModule } from './ui/ui.module';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { AdjuntaDocumentacionComponent } from './adjunta-documentacion/adjunta-documentacion.component';
import { TarjetaIdentificacionComponent } from './tarjeta-identificacion/tarjeta-identificacion.component';
import { HorizontalTopBarComponent } from './horizontal-top-bar/horizontal-top-bar.component';
import { HeadPanelComponent } from './head-panel/head-panel.component';
import { CargadorPublicacionesComponent } from './cargador-publicaciones/cargador-publicaciones.component';
import { TransportistaSugeridoComponent } from './transportista-sugerido/transportista-sugerido.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GestionPropuestaComponent } from './gestion-propuesta/gestion-propuesta.component';
import { InformacionGeneralComponent } from './informacion-general/informacion-general.component';
import { ArticulosEnviarComponent } from './articulos-enviar/articulos-enviar.component';
import { TrayectoUnoComponent } from './trayecto-uno/trayecto-uno.component';
import { TrayectoDosComponent } from './trayecto-dos/trayecto-dos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InformacionAdministradorComponent } from './informacion-administrador/informacion-administrador.component';
import { ValidaPerfilesComponent } from './valida-perfiles/valida-perfiles.component';
import { InfoGeneralComponent } from './info-general/info-general.component';
import { ListaCargadoresComponent } from './lista-cargadores/lista-cargadores.component';
import { ListaDescargadoresComponent } from './lista-descargadores/lista-descargadores.component';
import { ListaTransportistaComponent } from './lista-transportista/lista-transportista.component';
import { ListaPagoAnticipadoComponent } from './lista-pago-anticipado/lista-pago-anticipado.component';
import { NgbPaginationModule, NgbTypeaheadModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HeadPanelDescargadorComponent } from './head-panel-descargador/head-panel-descargador.component';
import { HeadPanelTransportistaComponent } from './head-panel-transportista/head-panel-transportista.component';
import { OfertasTodasComponent } from './ofertas-todas/ofertas-todas.component';
import { DialogPujaComponent } from './dialog-puja/dialog-puja.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { OfertasGuardadasComponent } from './ofertas-guardadas/ofertas-guardadas.component';
import { ViajesProcesoComponent } from './viajes-proceso/viajes-proceso.component';
import { TrayectoTresComponent } from './trayecto-tres/trayecto-tres.component';
import { ViajeContactoComponent } from './viajes-proceso/viaje-contacto/viaje-contacto.component'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ViajesRealizadosComponent } from './viajes-realizados/viajes-realizados.component';
import { ViajesPagoComponent } from './viajes-realizados/viajes-pago/viajes-pago.component'
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InfGeneComponent } from './inf-gene/inf-gene.component';
import { InfArticulosComponent } from './inf-articulos/inf-articulos.component';
import { InfTrayectoComponent } from './inf-trayecto/inf-trayecto.component';
import { RouterModule } from '@angular/router';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MensajesComponent } from './mensajes/mensajes.component';
// Emoji Picker
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DialogCalificaComponent } from './dialog-califica/dialog-califica.component';
import { DialogAnuncioComponent } from './dialog-anuncio/dialog-anuncio.component';
import { CargadorPrincipalComponent } from './cargador-principal/cargador-principal.component';
import { TabsDescargasComponent } from './tabs-descargas/tabs-descargas.component';
import { MisContratacionesComponent } from './mis-contrataciones/mis-contrataciones.component';
import { DescargasSolicitudesComponent } from './descargas-solicitudes/descargas-solicitudes.component';
import { DescargasProximasComponent } from './descargas-proximas/descargas-proximas.component';
import { DescargasRealizadasComponent } from './descargas-realizadas/descargas-realizadas.component';
import { ArticulosEditarComponent } from './articulos-editar/articulos-editar.component';
import { TrayectoCuatroComponent } from './trayecto-cuatro/trayecto-cuatro.component';
import { InformacionGeneralEditarComponent } from './informacion-general-editar/informacion-general-editar.component';
import { AddCuentaComponent } from './add-cuenta/add-cuenta.component';
import { AddTransportistaComponent } from './add-transportista/add-transportista.component';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogAlbaranesComponent } from './dialog-albaranes/dialog-albaranes.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { FormatNumberPipePipe } from './pipe/format-number-pipe.pipe';

@NgModule({
  declarations: [
    PersonalInformationComponent,
    AdjuntaDocumentacionComponent,
    TarjetaIdentificacionComponent,
    HorizontalTopBarComponent,
    HeadPanelComponent,
    CargadorPublicacionesComponent,
    TransportistaSugeridoComponent,
    GestionPropuestaComponent,
    InformacionGeneralComponent,
    ArticulosEnviarComponent,
    TrayectoUnoComponent,
    TrayectoDosComponent,
    InformacionAdministradorComponent,
    ValidaPerfilesComponent,
    InfoGeneralComponent,
    ListaCargadoresComponent,
    ListaDescargadoresComponent,
    ListaTransportistaComponent,
    ListaPagoAnticipadoComponent,
    HeadPanelDescargadorComponent,
    HeadPanelTransportistaComponent,
    OfertasTodasComponent,
    DialogPujaComponent,
    OfertasGuardadasComponent,
    ViajesProcesoComponent,
    TrayectoTresComponent,
    ViajeContactoComponent,
    ViajesRealizadosComponent,
    ViajesPagoComponent,
    InfGeneComponent,
    InfArticulosComponent,
    InfTrayectoComponent,
    MensajesComponent,
    DialogCalificaComponent,
    DialogAnuncioComponent,
    CargadorPrincipalComponent,
    TabsDescargasComponent,
    MisContratacionesComponent,
    DescargasSolicitudesComponent,
    DescargasProximasComponent,
    DescargasRealizadasComponent,
    ArticulosEditarComponent,
    TrayectoCuatroComponent,
    InformacionGeneralEditarComponent,
    AddCuentaComponent,
    AddTransportistaComponent,
    DialogAlbaranesComponent,
    FormatNumberPipePipe
  ],


  imports: [
    CommonModule,
    TranslateModule,
    UIModule,
    NgbCollapseModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
    NgbDatepickerModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    RouterModule,
    SimplebarAngularModule,
    PickerModule,
    NgbRatingModule,
    NgxCurrencyModule
  ],


  exports:[
    PersonalInformationComponent,
    AdjuntaDocumentacionComponent,
    TarjetaIdentificacionComponent,
    HorizontalTopBarComponent,
    HeadPanelComponent,
    CargadorPublicacionesComponent,
    TransportistaSugeridoComponent,
    GestionPropuestaComponent,
    InformacionGeneralComponent,
    ArticulosEnviarComponent,
    TrayectoUnoComponent,
    TrayectoDosComponent,
    InformacionAdministradorComponent,
    ValidaPerfilesComponent,
    InfoGeneralComponent,
    ListaCargadoresComponent,
    ListaDescargadoresComponent,
    ListaTransportistaComponent,
    ListaPagoAnticipadoComponent,
    HeadPanelDescargadorComponent,
    HeadPanelTransportistaComponent,
    OfertasTodasComponent,
    OfertasGuardadasComponent,
    ViajesProcesoComponent,
    TrayectoTresComponent,
    ViajesRealizadosComponent,
    MensajesComponent,
    CargadorPrincipalComponent,
    TabsDescargasComponent,
    MisContratacionesComponent,
    ArticulosEditarComponent,
    TrayectoCuatroComponent,
    InformacionGeneralEditarComponent,
    AddCuentaComponent,
    AddTransportistaComponent
  ],

  entryComponents:[
    DialogPujaComponent
  ],

  providers:[
    CurrencyPipe
  ]
})

export class SharedModule {}

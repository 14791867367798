import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { DialogAnuncioComponent } from '../dialog-anuncio/dialog-anuncio.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-descargas-realizadas',
  templateUrl: './descargas-realizadas.component.html',
  styleUrls: ['./descargas-realizadas.component.scss']
})
export class DescargasRealizadasComponent {

  searchForm: FormGroup;

  listOffers: any[] = [];
  dataSource: any[] = [];
  page: number = 0;
  size: number = 5;

  dataUnload:any={};

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private servicesService: ServicesService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private modalService: NgbModal,
    public httpClient:HttpClient) {
    this.loadSearch();
    this.loadData();
  }

  loadSearch() {
    this.searchForm = this.fb.group({
      fecha_viaje: [''],
      provincia: [''],
      ciudad: [''],
      calle: [''],
      numero: [''],
      codigoPostal: ['']
    });
  }

  loadData() {

    // Toma los valores de parámetros de búsqueda
    let pSearch = {};
    Object.entries(this.searchForm.value).forEach((entry) => {
      const [key, value] = entry;
      if (value) pSearch[key] = value;
    })

    this.servicesService.getFinishUnloadings(pSearch).subscribe((resp: any) => {
      if (resp.cod == 200) {
        this.dataSource = resp.listado;
        this.linkListToPaginator({ pageIndex: this.page, pageSize: this.size });
      }
    })
  }

  linkListToPaginator(obj) {
    const from = obj.pageIndex * obj.pageSize;
    const to = from + obj.pageSize;
    this.listOffers = this.dataSource.slice(from, to);
  }

  async viewModalAnuncio(iOffer: any) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = { idanuncio: iOffer.idanuncio };

    const dialogRef = this.dialog.open(DialogAnuncioComponent, dialogConfig);
  }

  search() {
    this.listOffers = [];
    this.dataSource = [];
    this.loadData();
  }

  viewModal(largeDataModal: any, idanuncio:number, idtrayecto:number){
    this.modalService.open(largeDataModal, { centered: true });
    this.loadUnload({idanuncio,idtrayecto});
  }

  loadUnload(data:any){
    this.servicesService.getModalUnload(data).subscribe((resp: any) => {
      if (resp.cod == 200) {
        this.dataUnload.fecha = resp.info_general[0].fecha;
        resp.info_archivo.forEach(f=>{
          if (f.orden_inicio.length>0){
            const name = f.orden_inicio.split('/')
            this.dataUnload.fichero_inicio = name[name.length-1]
            this.dataUnload.orden_inicio = f.orden_inicio;
          }
          if (f.orden_llegada.length>0){
            const name = f.orden_llegada.split('/')
            this.dataUnload.fichero_llegada = name[name.length-1]
            this.dataUnload.orden_llegada = f.orden_llegada;
          }
        })
      }
    })
  }

  downloadfiles(){

    const files = [{
        // nombre: this.dataUnload.fichero_inicio,
        file: this.dataUnload.orden_inicio
      },{
        // nombre: this.dataUnload.fichero_llegada,
        file: this.dataUnload.orden_llegada
      }];


      const observables = files.map(f =>
        this.servicesService.downloadObsfile(f.file)
      );

      forkJoin(observables).subscribe((responses:any[]) => {
        responses.forEach(response => {
          var file = new Blob([response.blob],{ type: response.blob.type })
          var fileURL = URL.createObjectURL(file);
          var a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = response.nombre;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(fileURL);
          document.body.removeChild(a);
        });
      });

  }



}

import { Component, OnInit, Input } from '@angular/core';
import { ServicesService } from '../informacion-general/services.service';
import { tipoContrato } from '../../core/models/tipoContrato.models';
import { tipoTransporte } from '../../core/models/tipoTransporte.models';
import { formaPago } from '../../core/models/formaPago.models';
import { tiempoPago } from '../../core/models/tiempoPago.models';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SharingService } from '../../core/services/sharing.service';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-informacion-general-editar',
  templateUrl: './informacion-general-editar.component.html',
  styleUrls: ['./informacion-general-editar.component.scss']
})


export class InformacionGeneralEditarComponent implements OnInit {

  public tipoContrato:tipoContrato[];
  public tipoTransporte:tipoTransporte[];
  public vehiculo:tipoTransporte;
  public formaPago:formaPago[];
  public tiempoPago:tiempoPago[];
  public fecha:Date;
  public model: NgbDateStruct;
  public model2: NgbDateStruct;
  public indexTransporte:number = -1;
  public informationGeneral: FormGroup;
  public fechaSalida:string;
  public registerUser = { 'id':0, 'name':'', 'perfil':0 }
  public validFecha:boolean;


  @Input() formGroupName: string;


  constructor(private servicesService:ServicesService,
              private formGroupDirective: FormGroupDirective){}

  ngOnInit(): void {
    this.informationGeneral = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
    this.iniciar();
  }


  iniciar(){
    this.contratotipo();
    this.formapago();
    this.pagotiempo();
  }


  contratotipo(){
    this.servicesService.contratotipo().subscribe(resp =>{
      if(resp.estatus == "ok"){
        this.tipoContrato = resp.response;
        if(this.tipoContrato.length == 1){
          this.informationGeneral.get('tipoContrato').setValue(1);
        }
      }
    });
  }


  formapago(){
    this.servicesService.formapago().subscribe(resp =>{
      if(resp.estatus == "ok"){
        this.formaPago = resp.response;
      }
    });
  }


  pagotiempo(){
    this.registerUser = JSON.parse(localStorage.getItem('data'));
    this.servicesService.pagotiempo(this.registerUser.id).subscribe(resp =>{
      if(resp.estatus == "ok"){
        this.tiempoPago = resp.response;
      }
    });
  }





  invalidate(nameFormControl: string){
    let valida = { value: false };
    if((this.informationGeneral.get(nameFormControl)?.touched ||
        this.informationGeneral.get(nameFormControl)?.dirty) &&
        this.informationGeneral.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }

  invalidateSelect(nameFormControl: string){
    let valida = { value: false };
    if(this.informationGeneral.get(nameFormControl)?.touched && this.informationGeneral.get(nameFormControl)?.value == 0)
    { valida.value = true; }

    return valida
  }

  invalidateTipoVehiculo(nameFormControl: string){
    let valida = { value: false };
    if(this.informationGeneral.get(nameFormControl)?.touched && this.informationGeneral.get(nameFormControl)?.value == -1)
    { valida.value = true; }

    return valida
  }

  validaFecha(){
    const fechaPuja = this.informationGeneral.get('fechaLimitePuja').value;
    const fechaSalida = this.informationGeneral.get('fechaSalida').value;

    if(fechaSalida != ''  &&  fechaPuja != '')
    { if(fechaSalida < fechaPuja)
      { this.informationGeneral.get('validaFecha').setValue(false);
        this.validFecha = true;
        Swal.fire({ icon: 'error', text: 'La Fecha límite de puja no puede ser mayor a la Fecha de salida!' })
      }
      else
      { this.informationGeneral.get('validaFecha').setValue(true);
        this.validFecha = false;
      }
    }
  }
}

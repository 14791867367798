import { tipoTransporte } from './../models/tipoTransporte.models';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

interface trayectoInicial {
  codigo_postal:number,
  provincia: string,
  ciudad: string,
  calle: string,
  numero: number
}

@Injectable({
  providedIn: 'root'
})

export class SharingService {
  private tipoTransporte: BehaviorSubject<tipoTransporte> = new BehaviorSubject<tipoTransporte>({ carga_util:'prueba', idtipotransporte:1, nombre:'prueba', tipo_carga:0, anterior:0, actual:0, cubitaje:0, area:0, alto:0 });
  public anterior:number = -1;
  public actual:number = 0;
  public step:number = 0;
  public idtipotransporte:number = 0;
  public resultado_suma:number = 0;
  public cubitaje_art_individual:number = 0;
  public restante_volumen:number = 0;
  public restante_area:number = 0;
  public porcentaje_total:number = 0;
  public largo:number;
  public ancho:number;
  public volumen:number;
  public caja:number = 0;
  public area:number = 0;
  public alto:number = 0;
  public tipoViaje:number = 0;
  public selectCheckbox:boolean = false;
  public telefono:string;
  public ocupacionInfo:any
  public trayectoIni:trayectoInicial = { codigo_postal:0, provincia:'', ciudad:'', calle:'', numero:0 };

  #idxObservable$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  idxObservable$ = this.#idxObservable$.asObservable();

  setIdxObservable(data:number){
    this.#idxObservable$.next(data);
  }

  get getTipoTransporte(){
    return this.tipoTransporte.asObservable();
  }

  set setTipoTransporte(data:tipoTransporte){
    this.tipoTransporte.next(data);
  }

  #trayectoInicialObservable$: BehaviorSubject<trayectoInicial> = new BehaviorSubject<trayectoInicial>({ codigo_postal:0, provincia:'', ciudad:'', calle:'', numero:0 });
   trayectoInicialObservable$ = this.#trayectoInicialObservable$.asObservable();

  setTrayectoInicialObservable(data:trayectoInicial){
    this.#trayectoInicialObservable$.next(data);
  }



  //*************************codigo_postal************************************************************

  #codigo_postalObservable$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
   codigo_postalObservable$ = this.#codigo_postalObservable$.asObservable();

   setCodigo_postalObservable(data:number){
      this.#codigo_postalObservable$.next(data);
   }

  //************************Provincia**************************************************************************

  #provinciaObservable$: BehaviorSubject<string> = new BehaviorSubject<string>('');
   provinciaObservable$ = this.#provinciaObservable$.asObservable();

   setProvinciaObservable(data:string){
    this.#provinciaObservable$.next(data);
   }

  //*************************Ciudad*************************************************************************

  #ciudadObservable$: BehaviorSubject<string> = new BehaviorSubject<string>('');
   ciudadObservable$ = this.#ciudadObservable$.asObservable();

   setCiudadObservable(data:string){
     this.#ciudadObservable$.next(data);
   }

  //***************************Calle***********************************************************************

  #calleObservable$: BehaviorSubject<string> = new BehaviorSubject<string>('');
   calleObservable$ = this.#calleObservable$.asObservable();

  setCalleObservable(data:string){
    this.#calleObservable$.next(data);
  }

  //***************************Numero***********************************************************************

  #numeroObservable$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  numeroObservable$ = this.#numeroObservable$.asObservable();

  setNumeroObservable(data:number){
    this.#numeroObservable$.next(data);
  }


  //********************************************************************************************************

}

import { Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormArray, FormControl, Validators } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language.service';
import { ServicesService } from '../../pages/components/viaje-multiple/services.service';
import { SharingService } from '../../core/services/sharing.service'

@Component({
  selector: 'app-trayecto-cuatro',
  templateUrl: './trayecto-cuatro.component.html',
  styleUrls: ['./trayecto-cuatro.component.scss']
})

export class TrayectoCuatroComponent {

  @Input() formGroupName: string;
  public trayectoAdicional: FormGroup;
  public orden:File;
  public descargadores:any;
  public registerUser = { 'id':0, 'name':'', 'perfil':0 }
  public verQuien:boolean;
  public zp:number=0;

  public cod_postal$:Observable<number>
  public provincia$:Observable<string>
  public ciudad$:Observable<string>
  public calle$:Observable<string>
  public numero$:Observable<number>

  constructor(public languageService: LanguageService,
              private formGroupDirective: FormGroupDirective,
              private sharingService: SharingService,
              private servicesService:ServicesService)
              {
                this.cod_postal$ = this.sharingService.codigo_postalObservable$
                this.provincia$ = this.sharingService.provinciaObservable$
                this.ciudad$ = this.sharingService.ciudadObservable$
                this.calle$ = this.sharingService.calleObservable$
                this.numero$ = this.sharingService.numeroObservable$
              }

  ngOnInit(): void {
    this.trayectoAdicional = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
    this.registerUser = JSON.parse(localStorage.getItem('data'));
    let arreglo = this.trayectoAdicional.get('campos') as FormArray;

    if(arreglo.length == 0){
      this.addTrayecto();
    }

    this.getDescargadores(0);
  }


  public verCodigo_Postal(arreglo:any){
    this.cod_postal$.subscribe({
      next:(resp) => {
        const formulario =  arreglo.controls[0] as FormGroup
        formulario.get('origen_cod_postal').setValue(resp);
      },
      error:(error) => {},
      complete:() => {},
    })
  }

  public verProvincia(arreglo:any){
    this.provincia$.subscribe({
      next:(resp) => {
        console.log('verProvincia',resp);

        const formulario =  arreglo.controls[0] as FormGroup
        console.log('formulario',formulario);
        formulario.get('origen_provincia').setValue(resp);
      },
      error:(error) => {},
      complete:() => {},
    })
  }

  public verCiudad(arreglo:any){
    this.ciudad$.subscribe({
      next:(resp) => {
        const formulario =  arreglo.controls[0] as FormGroup
        formulario.get('origen_ciudad').setValue(resp);
      },
      error:(error) => {},
      complete:() => {},
    })
  }

  public verCalle(arreglo:any){
    this.calle$.subscribe({
      next:(resp) => {
        const formulario =  arreglo.controls[0] as FormGroup
        formulario.get('origen_calle').setValue(resp);
      },
      error:(error) => {},
      complete:() => {},
    })
  }

  public verNumero(arreglo:any){
    this.numero$.subscribe({
      next:(resp) => {
        const formulario =  arreglo.controls[0] as FormGroup
        formulario.get('origen_numero').setValue(resp);
      },
      error:(error) => {},
      complete:() => {},
    })
  }


  onFileChange(event, indice:number){
    if(event.target.files.length > 0){
      let documento = this.trayectoAdicional.get('campos') as FormArray;
      let doc = documento.controls[indice] as FormGroup;
      doc.get('orden_entrega').setValue(event.target.files[0]);
      doc.get('orden_entrega_name').setValue(event.target.files[0].name);
    }
  }


  addTrayecto():void {
    const arre = this.trayectoAdicional.get('campos') as FormArray;

    (<FormArray>this.trayectoAdicional.get('campos')).push(new FormGroup({
        origen_provincia: new FormControl('',[Validators.required]),
        origen_ciudad: new FormControl('',[Validators.required]),
        origen_calle: new FormControl('',[Validators.required]),
        origen_numero: new FormControl(''),
        origen_cod_postal: new FormControl('',[Validators.required]),

        destino_provincia: new FormControl('',[Validators.required]),
        destino_ciudad: new FormControl('',[Validators.required]),
        destino_calle: new FormControl('',[Validators.required]),
        destino_numero: new FormControl(''),
        destino_cod_postal: new FormControl('',[Validators.required]),

        fecha_maxima: new FormControl(''),
        cantidad_articulos: new FormControl(0,[Validators.required, Validators.min(1)]),
        orden_entrega: new FormControl(null),
        orden_entrega_name: new FormControl(''),

        descripcion: new FormControl(''),

        indexDescargador: new FormControl(-1,[Validators.required, Validators.min(0)]),
        quien_recibe: new FormControl(-1,[Validators.required, Validators.min(0)]),
        verquien:new FormControl(false),
        descargadorDesconocido:new FormControl(false),

        idusuariodescargador:new FormControl(0),


        nombre: new FormControl(''),
        dni: new FormControl(''),
        telefono: new FormControl(''),
        correo: new FormControl(''),

        muelle: new FormControl(false),
        transpaleta: new FormControl(false),
        carretilla: new FormControl(false),

        editar:new FormControl(1),
    }));

    const ultimo = arre.length;

    if(ultimo == 1)
    {
      //this.verDireccion(arre);
      this.verCodigo_Postal(arre);
      this.verProvincia(arre);
      this.verCiudad(arre);
      this.verCalle(arre);
      this.verNumero(arre);


    }

    if(ultimo > 1){
      const valores = arre.controls[ultimo -2].value;
      const formulario =  arre.controls[ultimo -1] as FormGroup;

      formulario.get('origen_cod_postal').setValue(valores.destino_cod_postal);
      formulario.get('origen_provincia').setValue(valores.destino_provincia);
      formulario.get('origen_ciudad').setValue(valores.destino_ciudad);
      formulario.get('origen_calle').setValue(valores.destino_calle);
      formulario.get('origen_numero').setValue(valores.destino_numero);
    }
  }


  cerrar(indice:number){
    (<FormArray>this.trayectoAdicional.get('campos')).removeAt(indice)
  }


  getDescargadores(indice:number){
    this.descargadores = [];
    const documento = this.trayectoAdicional.get('campos') as FormArray;
    const doc = documento.controls[indice] as FormGroup;
    this.zp = doc.get('destino_cod_postal').value;

    this.servicesService.descargadores(this.registerUser.id, this.zp).subscribe(resp =>{
      if(resp.estatus == "ok"){
        this.descargadores = resp.response;
      }
    });
  }


  invalidate(nameFormControl: string, indice:number){
    let trayecto = this.trayectoAdicional.get('campos') as FormArray;
    let tray = trayecto.controls[indice] as FormGroup;

    let valida = { value: false };
    if((tray.get(nameFormControl)?.touched ||
        tray.get(nameFormControl)?.dirty) &&
        tray.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }


  invalidateQuienRecibe(nameFormControl: string, indice:number){
    let trayecto = this.trayectoAdicional.get('campos') as FormArray;
    let tray = trayecto.controls[indice] as FormGroup;

    let valida = { value: false };
    if(tray.get(nameFormControl)?.touched && tray.get(nameFormControl)?.value == -1)
    { valida.value = true; }

    return valida
  }


  /*
  getQuienrecibe(indice:number){
    let documento = this.trayectoAdicional.get('campos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;
    const quien =  doc.get('quien_recibe').value
    if(quien == 9999)
    { this.verQuien = true
      doc.get('verquien').setValue(true)
      doc.get('nombre').addValidators([Validators.required]);
      doc.get('telefono').addValidators([Validators.required]);
    }
    else
    { this.verQuien = false
      doc.get('verquien').setValue(false)

      doc.get('nombre').clearValidators();
      doc.get('telefono').clearValidators();

      doc.get('nombre').setValue('')
      doc.get('dni').setValue('')
      doc.get('telefono').setValue('')
      doc.get('correo').setValue('')
    }
  }
  */


  getQuienrecibe(datos:any,indice:number){
    let documento = this.trayectoAdicional.get('campos') as FormArray;
    let doc = documento.controls[indice] as FormGroup;

    if(datos === "9998" || datos === "9999"){
      doc.get('destino_provincia').setValue('')
      doc.get('destino_ciudad').setValue('')
      doc.get('destino_calle').setValue('')
      doc.get('destino_numero').setValue('')
      doc.get('quien_recibe').setValue(datos)

      //this.origen_provincia=''
      //this.ciudad=''
      //this.calle=''
      //this.numero=''
    }
    else if(datos>=0){

      doc.get('destino_provincia').setValue(this.descargadores[datos].provincia)
      doc.get('destino_ciudad').setValue(this.descargadores[datos].ciudad)
      doc.get('destino_calle').setValue(this.descargadores[datos].calle)
      doc.get('destino_numero').setValue(this.descargadores[datos].numero)
      doc.get('quien_recibe').setValue(this.descargadores[datos].id)

      if((indice + 1 < documento.length)){
        let sig = documento.controls[indice + 1] as FormGroup;
        sig.get('origen_provincia').setValue(doc.get('destino_provincia').value)
        sig.get('origen_ciudad').setValue(doc.get('destino_ciudad').value)
        sig.get('origen_calle').setValue(doc.get('destino_calle').value)
        sig.get('origen_numero').setValue(doc.get('destino_numero').value)
      }
    }

    const quien = doc.get('indexDescargador').value

    if(quien == 9999)
    { this.verQuien = true
      doc.get('verquien').setValue(true)
      doc.get('nombre').addValidators([Validators.required]);
      doc.get('telefono').addValidators([Validators.required]);
    }
    else
    { this.verQuien = false
      doc.get('verquien').setValue(false)

      doc.get('nombre').clearValidators();
      doc.get('telefono').clearValidators();

      doc.get('nombre').setValue('')
      doc.get('dni').setValue('')
      doc.get('telefono').setValue('')
      doc.get('correo').setValue('')
    }
  }


  verquien(indice:number):boolean{
    const documento = this.trayectoAdicional.get('campos') as FormArray;
    const doc = documento.controls[indice] as FormGroup;
    const verquien =  doc.get('verquien').value;
    return verquien
  }


  zonaPostal(event: any, indice:number){
    const documento = this.trayectoAdicional.get('campos') as FormArray;
    const doc = documento.controls[indice] as FormGroup;
    doc.get('destino_cod_postal').setValue(event.target.value);
    //this.zp = event.target.value
    this.getDescargadores(indice)
  }
}

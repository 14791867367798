<ng-container [formGroup]="documentacion">
  <div class="row mt-2">
    <div class="col-sm-12">
      <div class="text-center">
        <label style="color: black;">Adjunte la documentación solicitada para la seguridad de la empresa</label>
      </div>
    </div>
  </div>


  <div class="row mt-4 mb-2">
    <div class="col-sm-6">
      <div style="margin-left: 5px;">
        <label style="color: black;">Certificado de no adeudo <span style="color: red;">*</span></label>
      </div>

      <div class="mt-1">
        <input type="text"
               placeholder="Ningun archivo seleccionado"
               class="form-control"
               formControlName="certificadoName"
               style="font-size: 12px; width: 280px; color: #000000;"
               maxlength="90"
               [ngClass]="{'is-invalid': invalidate('certificadoName').value }">
      </div>


      <div class="mt-1">
        <button class="contenedor-btn-file" (click)="fileID.click()">
          <img src="../../../assets/images/transfriendly/upload.png">
            Agregar documento
          <label></label>
          <input type="file"
                 id="fileID"
                 #fileID
                 class="form-control"
                 (change)="onFileChange($event)">
        </button>
      </div>

      <div class="mt-2">
        <label style="color: #000000;">Fecha vencimiento <span style="color: red;">*</span></label>
        <input type="date"
               placeholder="Fecha vencimiento"
               class="form-control"
               name="vencimientoCertificado"
               formControlName="vencimientoCertificado"
               style="font-size: 12px; width: 280px; margin-top: -5px;"
               [ngClass]="{'is-invalid': invalidate('vencimientoCertificado').value }">
      </div>
    </div>


    <div class="col-sm-6">
      <div>
        <label style="color: black;">Seguro de mercancías <span style="color: red;">*</span></label>
      </div>

      <div class="mt-1">
        <input type="text"
               placeholder="Ningun archivo seleccionado"
               class="form-control"
               formControlName="seguroName"
               style="font-size: 12px; width: 280px;"
               maxlength="90"
               [ngClass]="{'is-invalid': invalidate('seguroName').value }">
      </div>

      <div class="mt-1">
        <button class="contenedor-btn-file" (click)="fileIDSeguro.click()">
          <img src="../../../assets/images/transfriendly/upload.png">
            Agregar documento
          <label></label>
          <input type="file"
                 id="fileIDSeguro"
                 #fileIDSeguro
                 class="form-control"
                 (change)="onFileChangeSeguro($event)">
        </button>
      </div>


      <div class="mt-2">
        <label style="color: #000000;">Fecha vencimiento <span style="color: red;">*</span></label>
        <input type="date"
                placeholder="Fecha vencimiento"
                class="form-control"
                name="vencimientoSeguro"
                formControlName="vencimientoSeguro"
                style="font-size: 12px; width: 280px; margin-top: -5px;"
                [ngClass]="{'is-invalid': invalidate('vencimientoSeguro').value }">
      </div>
    </div>
  </div>
</ng-container>



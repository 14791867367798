<ng-container [formGroup]="enviar">

  <ng-container *ngIf="tipoCarga == 0">
    <div class="text-center">
      <p style="font-size: 14px; padding-top: 20px;">Debe elegir un tipo de vehículo en el segmento de Información General</p>
    </div>
  </ng-container>

  <label *ngIf="sharingService.tipoViaje===1" >
    <input type="checkbox" [checked]="sharingService.selectCheckbox" (click)="selectCheckboxAction()">
    Ocupación completa del vehiculo
  </label>
  <!-- *ngIf="sharingService.selectCheckbox===false"  -->
  <!-- <div *ngIf="tipoCarga > 0">
    <label><input type="checkbox" (click)="selectCheckboxAction()"> Ocupación completa del vehiculo</label>
  </div> -->
  <ng-container *ngIf="tipoCarga == 1">
    <div class="form-group row">
      <div formArrayName="articulos">
        <div *ngFor="let item of enviar.get('articulos')?.controls; let i = index" [formGroupName]="i">

          <div class="linea mt-3">

            <div class="row">
              <div class="col-sm-12">
                <div style="color: #0F4C81; font-size: 15px;">
                  <div style="float: left">
                    <b>Contenedor de líquido {{ i + 1 }}</b>
                  </div>

                  <div *ngIf="i > 0" style="float: right; cursor: pointer;" (click)="cerrar(i)">
                    <i class="fal fa-times close-icon"></i>
                  </div>
                </div>

                <div>

                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-3">
                <div>
                  <label style="color: #000000;">Volumen <span style="color: red;">*</span></label>
                  <input type="number"
                        placeholder="Volumen"
                        class="form-control"
                        formControlName="volumen"
                        autocomplete="off"
                        style="font-size: 12px; margin-top: -5px;"
                        [ngClass]="{'is-invalid': invalidate('volumen').value }">
                  <span style="color: red;">*</span><span style="font-size: 10px;">Capacidad de hasta 45.000 L</span>
                </div>
              </div>


              <div class="col-sm-3">
                <div class="form-group">
                  <label style="color: #000000;">Unidad de medida <span style="color: red;">*</span></label>
                  <select class="form-select"
                          aria-label="Default select example"
                          style="font-size: 12px; margin-top: -5px;"
                          formControlName="unidad_medida"
                          [ngClass]="{'is-invalid': invalidateSelect('unidad_medida').value }">
                    <option value="0" [disabled]="true">Tipo</option>
                    <option value="1">Litros</option>
                    <option value="2">Onzas</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label style="color: #000000;">Descripción <span style="color: red;">*</span></label>
                  <input type="text"
                        placeholder="Descripción"
                        class="form-control"
                        formControlName="descripcion"
                        autocomplete="off"
                        style="font-size: 12px; margin-top: -5px;"
                        [ngClass]="{'is-invalid': invalidate('descripcion').value }">
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                    <div>
                      <label style="color: #000000;">Su envío es...</label>
                    </div>
                    <div class="form-check a">
                      <input class="form-check-input"
                             type="checkbox"
                             id="flexCheckDefault"
                             autocomplete="off"
                             formControlName="isotermico">
                      <label class="form-check-label" style="color: #000000; font-size: 10px;">
                        Isotérmico
                      </label>
                    </div>

                    <div class="form-check a">
                      <input class="form-check-input"
                             type="checkbox"
                             id="flexCheckDefault"
                             autocomplete="off"
                             formControlName="precinto">
                      <label class="form-check-label" style="color: #000000; font-size: 10px;">
                        Precinto
                      </label>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-12">
            <div class="b">
              <button class="contenedor-btn-file-long" (click)="addContenedorLiquido()">
                <img src="../../../assets/images/transfriendly/plus.png">
                Agregar Contenedor
                <label for="btn-file"></label>
                <input type="file">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="tipoCarga == 2">
    <div class="linea ">
      <div  style="display: flex; justify-content: space-between; align-items: center;">
        <label  style="color: #000000;">Capacidad del Vehiculo</label>
      </div>
      <ngb-progressbar style="height: 25px; font-size: 15px;"
                       type="primary"
                       [value]="porcentaje_ocupado"
                       [striped]="true"
                       [animated]="true"><b>{{porcentaje_ocupado | number:'1.0-1'}} %</b></ngb-progressbar>
    </div>

    <div class="form-group">
      <div formArrayName="articulos">
        <div *ngFor="let item of enviar.get('articulos')?.controls; let i = index" [formGroupName]="i">

          <div class="linea mt-3" [ngClass]="{'alert-ocupacion': alertOcupacion }">

            <div class="row">
              <div class="col-sm-12">
                <div style="color: #0F4C81; font-size: 15px; float: left">
                  <b>Articulo {{ i + 1 }}</b>
                </div>

                <div *ngIf="i > 0" style="float: right; cursor: pointer;" (click)="cerrar(i)">
                  <i class="fal fa-times close-icon"></i>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-4">
                <div class="form-group">
                  <label style="color: #000000;">Peso del artículo <span style="color: red;">*</span></label>
                  <input type="text"
                        (keypress)="numericOnly($event)"
                        (blur)="recalc()"
                        placeholder="Peso en Kg"
                        class="form-control"
                        formControlName="peso_artic"
                        autocomplete="off"
                        style="font-size: 12px; margin-top: -5px;"
                        [ngClass]="{'is-invalid': invalidatePaleta('peso_artic',i).value }">
                </div>
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Dimensiones <span style="color: red;">*</span></label>
                <div class="input-group mb-3">
                  <span class="input-group-text"
                        id="inputGroup-sizing-default"
                        style="margin-top: -5px;"
                        [ngClass]="{'seleccionado2': paletaSeleccionada(i).value > 0 }">Largo</span>
                  <input type="text"
                         (keypress)="numericOnly($event)"
                         (blur)="recalc()"
                         placeholder="en cm"
                         class="form-control seleccionado3"
                         aria-label="Sizing example input"
                         aria-describedby="inputGroup-sizing-default"
                         style="margin-top: -5px;"
                         autocomplete="off"
                         [ngClass]="{'seleccionado3': paletaSeleccionada(i).value > 0 }"
                         formControlName="lar"
                         >
                </div>
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;"><span> </span></label>
                <div class="input-group mb-3">
                  <span class="input-group-text"
                        id="inputGroup-sizing-default"
                        style="margin-top: 3px;"
                        [ngClass]="{'seleccionado2': paletaSeleccionada(i).value > 0 }">
                        Ancho
                  </span>
                  <input type="text"
                        (keypress)="numericOnly($event)"
                        (blur)="recalc()"
                        placeholder="en cm"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        style="margin-top: 3px;"
                        autocomplete="off"
                        [ngClass]="{'seleccionado3': paletaSeleccionada(i).value > 0 }"
                        formControlName="anch"
                        >
                </div>
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;"><span> </span></label>
                <div class="input-group mb-3">
                  <span class="input-group-text"
                        id="inputGroup-sizing-default"
                        style="margin-top: 3px;"
                        [ngClass]="{'seleccionado2': paletaSeleccionada(i).value == 4 }">
                    Alto
                  </span>

                  <input type="text"
                          (keypress)="numericOnly($event)"
                          (blur)="recalc()"
                          placeholder="en cm"
                          class="form-control"
                          aria-label="Sizing example input"
                          autocomplete="off"
                          aria-describedby="inputGroup-sizing-default"
                          style="margin-top: 3px; border-top-right-radius:4px; border-bottom-right-radius:4px;"
                          [ngClass]="{'seleccionado3': paletaSeleccionada(i).value == 4 }"
                          formControlName="alt"
                          >
                    <span style="font-size: 10px;"><span style="color: red;">*</span>  Altura máxima hasta 240cm</span>
                </div>
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Cantidad de artículos <span style="color: red;">*</span></label>
                <div class="input-group">
                  <button style="margin-top: -5px;"
                          class="btn btn-outline-danger"
                          type="button"
                          id="button-addon1"
                          (click)="minusCantidad(i)">
                          <i class="bx bx-minus"></i>
                        </button>
                  <input  style="margin-top: -5px; text-align: center;"
                          type="number"
                          (keypress)="numericOnly($event)"
                          (blur)="recalc()"
                          class="form-control"
                          aria-label="Example text with button addon"
                          aria-describedby="button-addon1"
                          formControlName="canti_art"
                          autocomplete="off"
                          [ngClass]="{'is-invalid': invalidateCantidadPaleta('canti_art',i).value }">
                  <button style="margin-top: -5px;"
                          class="btn btn-outline-primary"
                          type="button"
                          id="button-addon1"
                          (click)="addCantidad(i)">
                          <i class="bx bx-plus"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3">
                <div class="card" (click)="addEURPallet1(i)" [ngClass]="{'seleccionado': paletaSeleccionada(i).value == 1 }">
                  <div class="card-body flexbox-container">
                    <div>
                      <img  src="assets/images/transfriendly/palet1.png" alt="">
                    </div>

                    <div>
                      <label style="font-size: 11px; margin-top: 5px; color: black;"><b>EUR-1 Pallet</b></label>
                      <label style="font-size: 10px; margin-top: -5px;">120 x 80 cm</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card" (click)="addEURPallet3(i)" [ngClass]="{'seleccionado': paletaSeleccionada(i).value == 2 }">
                  <div class="card-body flexbox-container">
                    <div>
                      <img  src="assets/images/transfriendly/palet2.png" alt="">
                    </div>

                    <div>
                      <label style="font-size: 11px; margin-top: 5px; color: black;"><b>EUR-3 Pallet</b></label>
                      <label style="font-size: 10px; margin-top: -5px;">120 X 100 cm</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card" (click)="addEURPallet6(i)" [ngClass]="{'seleccionado': paletaSeleccionada(i).value == 3 }">
                  <div class="card-body flexbox-container">
                    <div>
                      <img  src="assets/images/transfriendly/palet3.png" alt="">
                    </div>

                    <div>
                      <label style="font-size: 11px; margin-top: 5px; color: black;"><b>EUR-6 Pallet</b></label>
                      <label style="font-size: 10px; margin-top: -5px;">80 X 60 cm</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card" (click)="cajaMudanza(i)" [ngClass]="{'seleccionado': paletaSeleccionada(i).value == 4 }">
                  <div class="card-body flexbox-container">
                    <div>
                      <img  src="assets/images/transfriendly/caja.png" alt="">
                    </div>

                    <div>
                      <label style="font-size: 11px; margin-top: 5px; color: black;"><b>Caja de mudanza</b></label>
                      <label style="font-size: 10px; margin-top: -5px;">75 X 35 X 35 cm</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-3">
                <div>
                  <label style="color: #000000;">Su artículo es...</label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                         type="checkbox"
                         id="flexCheckDefault"
                         autocomplete="off"
                         formControlName="paletizado">
                  <label class="form-check-label" style="color: #000000; font-size: 10px;">
                    Paletizado
                  </label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                         type="checkbox"
                         id="flexCheckDefault"
                         autocomplete="off"
                         formControlName="noapilable"
                         (click)="apilable(i)">
                  <label class="form-check-label" style="color: #000000; font-size: 10px;">
                    Apilable
                  </label>
                </div>
              </div>


              <div class="col-sm-3">
                <div>
                  <label style="color: black;">Cantidad apilable <span style="color: red;">*</span></label>
                </div>

                <select class="form-select"
                        aria-label="Default select example"
                        style="font-size: 12px;"
                        formControlName="cantidad_apilable"
                        (change)="recalc()"
                        [ngClass]="{'is-invalid': invalidateSelect('cantidad_apilable').value }">
                    <option [value]="0" [disabled]="true">Cantidad</option>
                    <option [value]="2">2</option>
                    <option [value]="3">3</option>
                    <option [value]="4">4</option>
                    <option [value]="5">5</option>
                    <option [value]="6">6</option>
                </select>
              </div>


              <div class="col-sm-4">
                <div>
                  <label style="color: black;">Foto de la carga</label>
                </div>

                <button style="margin-top: -5px;" class="contenedor-btn-file2" (click)="fileID.click()">
                  <img src="../../../assets/images/transfriendly/upload.png">
                    Agregar documento
                  <label></label>
                  <input type="file"
                         id="fileID"
                         #fileID
                         class="form-control"
                         (change)="fileFotodeCarga($event,i)">
                </button>
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Nombre Archivo</label>
                <input type="text"
                       placeholder="Nombre archivo"
                       class="form-control"
                       autocomplete="off"
                       formControlName="name_foto_de_carga"
                       style="font-size: 12px;">
              </div>
            </div>

            <div class="alert" *ngIf="alertOcupacion">
              La totalidad de las características de los artículos excede la capacidad del vehículo o algún artículo no tiene los datos completos o válidos
            </div>
          </div>
        </div>


        <div class="row mt-5">
          <div class="col-sm-12">
            <div class="b">
              <button class="contenedor-btn-file-long" (click)="addPaletizados()" [disabled]="alertOcupacion">
                <img src="../../../assets/images/transfriendly/plus.png">
                  Agregar Articulo
                <label for="btn-file"></label>
                <input type="file" id="btn-file">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="tipoCarga == 3">
    <div class="form-group">
      <div formArrayName="articulos">
        <div *ngFor="let item of enviar.get('articulos')?.controls; let i = index" [formGroupName]="i">

          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label style="color: #000000;">Peso del artículo <span style="color: red;">*</span></label>
                <input type="number"
                      (keypress)="numericOnly($event)"
                      placeholder="Peso del artículo"
                      class="form-control"
                      formControlName="peso_art"
                      autocomplete="off"
                      style="font-size: 12px; margin-top: -5px;"
                      [ngClass]="{'is-invalid': invalidate('peso_art').value }">
              </div>
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Dimensiones <span style="color: red;">*</span></label>
              <div class="input-group mb-3">
                <span class="input-group-text"
                      id="inputGroup-sizing-default"
                      style="margin-top: -5px;">Largo</span>
                <input type="text"
                       (keypress)="numericOnly($event)"
                       placeholder="en cm"
                       class="form-control"
                       aria-label="Sizing example input"
                       aria-describedby="inputGroup-sizing-default"
                       style="margin-top: -5px;"
                       autocomplete="off"
                       formControlName="largo"
                       [ngClass]="{'is-invalid': invalidate('largo').value }">
              </div>
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;"><span> </span></label>
              <div class="input-group mb-3">
                <span class="input-group-text"
                      id="inputGroup-sizing-default"
                      style="margin-top: 3px;">
                      Ancho
                </span>
                <input type="text"
                      (keypress)="numericOnly($event)"
                      placeholder="en cm"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      style="margin-top: 3px;"
                      formControlName="ancho"
                      autocomplete="off"
                      [ngClass]="{'is-invalid': invalidate('ancho').value }">
              </div>
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;"><span> </span></label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="inputGroup-sizing-default" style="margin-top: 3px;">
                  Alto
                </span>

                <input type="text"
                        (keypress)="numericOnly($event)"
                        placeholder="en cm"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        style="margin-top: 3px; border-top-right-radius:4px; border-bottom-right-radius:4px;"
                        formControlName="alto"
                        autocomplete="off"
                        [ngClass]="{'is-invalid': invalidate('alto').value }">

                  <span style="font-size: 10px;"><span style="color: red;">*</span>  Altura máxima hasta 220cm</span>
              </div>
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Cantidad de artículos <span style="color: red;">*</span></label>
              <div class="input-group">
                <button style="margin-top: -5px;"
                        class="btn btn-outline-danger"
                        type="button"
                        id="button-addon1"
                        (click)="minusFrigorificoCantidad()">
                        <i class="bx bx-minus"></i></button>
                <input  style="margin-top: -5px; text-align: center;"
                        type="number"
                        (keypress)="numericOnly($event)"
                        class="form-control"
                        placeholder="25"
                        aria-label="Example text with button addon"
                        aria-describedby="button-addon1"
                        formControlName="cant_articulos"
                        autocomplete="off"
                        [ngClass]="{'is-invalid': invalidateCantidad('cant_articulos').value }">
                <button style="margin-top: -5px;"
                        class="btn btn-outline-primary"
                        type="button"
                        id="button-addon1"
                        (click)="addFrigorificoCantidad()">
                        <i class="bx bx-plus"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-10">
              <div>
                <label style="color: black;">Foto de la carga</label>
              </div>

              <div class="b">
                <button class="contenedor-btn-file" (click)="fileID.click()">
                  <img src="../../../assets/images/transfriendly/upload.png">
                    Agregar foto
                  <label></label>
                  <input type="file"
                         id="fileID"
                         #fileID
                         class="form-control"
                         (change)="addFotoCarga($event)">
                </button>
              </div>
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Nombre Archivo</label>
              <input type="text"
                     placeholder="Nombre archivo"
                     class="form-control"
                     formControlName="name_foto_carga"
                     autocomplete="off"
                     style="font-size: 12px; margin-top: -5px;">
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="tipoCarga == 4">
    <div class="form-group">
      <div formArrayName="articulos">
        <div *ngFor="let item of enviar.get('articulos')?.controls; let i = index" [formGroupName]="i">

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label style="color: #000000;">Peso del artículo <span style="color: red;">*</span></label>
                <input type="number"
                      placeholder="Peso del artículo"
                      class="form-control"
                      formControlName="peso_articulo"
                      style="font-size: 12px; margin-top: -5px;"
                      autocomplete="off"
                      [ngClass]="{'is-invalid': invalidate('peso_articulo').value }">
              </div>
            </div>

            <div class="col-sm-6">
              <label style="color: #000000;">Volumen <span style="color: red;">*</span></label>
              <input type="number"
                     placeholder="Volumen"
                     class="form-control"
                     formControlName="volumen_tolva"
                     autocomplete="off"
                     style="font-size: 12px; margin-top: -5px;"
                     [ngClass]="{'is-invalid': invalidate('volumen_tolva').value }">
                     <span style="color: red;">*</span><span style="font-size: 10px;">Capacidad de hasta 45.000 L</span>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm-6">
              <div>
                <label style="color: #000000;">Su artículo es...</label>
              </div>

              <div class="form-check a">
                <input class="form-check-input"
                       type="radio"
                       id="flexCheckDefault"
                       formControlName="alimentario"
                       autocomplete="off"
                       value="1">
                <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                  Alimentario
                </label>
              </div>

              <div class="form-check a">
                <input class="form-check-input"
                       type="radio"
                       id="flexCheckDefault"
                       formControlName="alimentario"
                       autocomplete="off"
                       value="2">
                <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                  No Alimentario
                </label>
              </div>
            </div>

            <div class="col-sm-4">
              <div>
                <label style="color: black;">Foto de la carga</label>
              </div>

              <div>
                <button class="contenedor-btn-file2" (click)="fileID.click()">
                  <img src="../../../assets/images/transfriendly/upload.png">
                    Agregar Foto
                  <label></label>
                  <input type="file"
                         id="fileID"
                         #fileID
                         class="form-control"
                         (change)="fileFotoCarga($event)">
                </button>
              </div>
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Nombre Archivo</label>
              <input type="text"
                     placeholder="Nombre archivo"
                     class="form-control"
                     formControlName="nameFotocarga"
                     autocomplete="off"
                     style="font-size: 12px;">
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="tipoCarga == 5">
    <div class="form-group">
      <div formArrayName="articulos">
        <div *ngFor="let item of enviar.get('articulos')?.controls; let i = index" [formGroupName]="i">

          <div class="row">
            <div class="col-sm-10">
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Descripción <span style="color: red;">*</span></label>
                <textarea class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          formControlName="descripcion_jaula"
                          autocomplete="off"
                          [ngClass]="{'is-invalid': invalidate('descripcion_jaula').value }"></textarea>
              </div>
            </div>


            <div class="col-sm-2">
              <label style="color: #000000;">Cantidad de artículos <span style="color: red;">*</span></label>
              <div class="input-group">
                  <button
                          class="btn btn-outline-danger"
                          type="button"
                          id="button-addon1"
                          (click)="minusJaulaCantidad()">
                          <i class="bx bx-minus"></i></button>
                  <input  style="text-align: center;"
                          type="number"
                          class="form-control"
                          placeholder="25"
                          aria-label="Example text with button addon"
                          aria-describedby="button-addon1"
                          formControlName="cantidad_articulos"
                          autocomplete="off"
                          [ngClass]="{'is-invalid': invalidateCantidad('cantidad_articulos').value }">
                  <button
                          class="btn btn-outline-primary"
                          type="button"
                          id="button-addon1"
                          (click)="addJaulaCantidad()">
                          <i class="bx bx-plus"></i>
                  </button>
                </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-sm-10">
              <div>
                <label style="color: black;">Foto de la carga</label>
              </div>

              <div class="b">
                <button class="contenedor-btn-file" (click)="fileID.click()">
                  <img src="../../../assets/images/transfriendly/upload.png">
                    Agregar foto
                  <label></label>
                  <input type="file"
                         id="fileID"
                         #fileID
                         class="form-control"
                         (change)="addFoto($event)">
                </button>
              </div>
            </div>

            <div class="col-sm-2">
              <label style="color: #000000;">Nombre Archivo</label>
              <input type="text"
                     placeholder="Nombre archivo"
                     class="form-control"
                     formControlName="nombreAddFoto"
                     autocomplete="off"
                     style="font-size: 12px; margin-top: -5px;">
            </div>
          </div>

        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="tipoCarga == 6">
    <div class="form-group">
      <div formArrayName="articulos">
        <div *ngFor="let item of enviar.get('articulos')?.controls; let i = index" [formGroupName]="i">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Descripción <span style="color: red;">*</span></label>
                <textarea class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          formControlName="descripcion_especiales"
                          autocomplete="off"
                          [ngClass]="{'is-invalid': invalidate('descripcion_especiales').value }"
                          ></textarea>
              </div>
            </div>


            <div class="col-sm-4">
              <label style="color: black;">Foto de la carga</label>
              <button class="contenedor-btn-file2" (click)="fileID.click()">
                <img src="../../../assets/images/transfriendly/upload.png">
                  Agregar foto
                <label></label>
                <input type="file"
                       id="fileID"
                       #fileID
                       class="form-control"
                       (change)="fileaddDocumento($event)">
              </button>
            </div>


            <div class="col-sm-2">
              <label style="color: #000000;">Nombre Archivo</label>
              <input type="text"
                     placeholder="Nombre archivo"
                     class="form-control"
                     formControlName="nameAddDocumento"
                     autocomplete="off"
                     style="font-size: 12px;">
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>

</ng-container>

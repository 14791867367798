import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../pages/dashboards/services/services.service';
import { FormGroup, FormControl } from '@angular/forms';
import { merge } from 'rxjs/internal/observable/merge';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogAlbaranesComponent } from '../dialog-albaranes/dialog-albaranes.component'
import { SharingService } from '../../core/services/sharing.service';

@Component({
  selector: 'app-mis-contrataciones',
  templateUrl: './mis-contrataciones.component.html',
  styleUrls: ['./mis-contrataciones.component.scss']
})


export class MisContratacionesComponent implements OnInit {

  public searchForm: FormGroup;
  public contrataciones:any = [];
  public registerUser = { 'id':0, 'name':'', 'perfil':0 }
  public page = 1;
	public pageSize = 10;
	public collectionSize = 0;
  public pagina:any = [];

  constructor(private servicesService:ServicesService,
              public matDialog:MatDialog,
              private sharingService:SharingService){}

  ngOnInit(): void {
    this.registerUser = JSON.parse(localStorage.getItem('data'));
    this.getMisContrataciones();
    this.initForm();
    this.sharingService.setIdxObservable(1);
  }

  initForm(){
    this.searchForm = new FormGroup({
      codigo_anuncio: new FormControl(''),
      origen: new FormControl(''),
      destino: new FormControl(''),
      desde: new FormControl(''),
      hasta: new FormControl(''),
    });

    merge(this.searchForm.get('desde').valueChanges,this.searchForm.get('hasta').valueChanges).subscribe(() =>{
      this.setValidatorsDate(this.searchForm.get('desde').value,this.searchForm.get('hasta').value);
    })
  }

  public eliminarContratacion(id:number){
    Swal.fire({
      title: 'Estas seguro de eliminar la contratación?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:'Cancelar',
      confirmButtonText: 'Eliminar'
    })
    .then((result) => {
      if (result.isConfirmed)
      {
        this.servicesService.eliminarAnuncioContra(id).subscribe({
          next: (resp) => {
            if(resp.code == 200){
              this.getMisContrataciones();
              Swal.fire('Eliminado!','La contratación ha sido eliminada correctamente','success');
            }
          },
          error: (error) => {},
          complete: () => {},
        });

        /*
        this.servicesService.deleteanuncio(id).subscribe(resp =>{
          if(resp.code == 200){
            this.getAnuncioCargador();
            Swal.fire('Eliminado!','Anuncio ha sido eliminado correctamente','success');
          }
        },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }));
        */
      }
    })
  }

  getTime(itemOffer:any){
    let tiempo:string;

    if(itemOffer.minutos_transcurridos >= 0 && itemOffer.minutos_transcurridos < 60)
    { tiempo = itemOffer.minutos_transcurridos + ' minutos' }

    if(itemOffer.horas_transcurridos >= 1)
    { tiempo = itemOffer.horas_transcurridos + ' horas' }

    if(itemOffer.dias_transcurridos >= 1)
    { tiempo = itemOffer.dias_transcurridos + ' dias' }

    if(itemOffer.meses_transcurridos >= 1)
    { tiempo = itemOffer.meses_transcurridos + ' meses' }

    return tiempo;
  }

  setValidatorsDate(desde:string, hasta:string){
    if((!desde && hasta) || (desde && !hasta)){
      this.searchForm.get('desde').setErrors({'required': true});
    }else if(new Date(desde) > new Date(hasta)){
      this.searchForm.get('desde').setErrors({'rango': true});
    }else{
      this.searchForm.get('desde').setErrors(null)
    }
  }

  get f() { return this.searchForm.controls; }

  getMisContrataciones(){
    const data = { "id_usuario": this.registerUser.id }
    this.servicesService.consultaanunciocontratado(data).subscribe((resp:any) =>{
      if(resp.code == 200)
      { this.contrataciones = resp.msj.response.info_anuncio;
        this.collectionSize = this.contrataciones.length;
        this.refreshPagina();
      }
    },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }))
  }

  refreshPagina() {
    this.pagina = this.contrataciones.map((data, i) => ({ id: i + 1, ...data })).slice(
			(this.page - 1) * this.pageSize,
			(this.page - 1) * this.pageSize + this.pageSize,
		);
	}

  search(){
    const data = { "id_usuario": this.registerUser.id,
                   "codigo_anuncio": this.searchForm.get('codigo_anuncio').value,
                   "origen": this.searchForm.get('origen').value,
                   "destino": this.searchForm.get('destino').value,
                   "desde": this.searchForm.get('desde').value,
                   "hasta": this.searchForm.get('hasta').value,
                 }

    this.servicesService.consultaanunciocontratado(data).subscribe((resp:any) =>{
      if(resp.code == 200)
      { this.contrataciones = resp.msj.response.info_anuncio;
        this.collectionSize = this.contrataciones.length;
        this.refreshPagina();
      }
    },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }))
  }

  viewContact(id:any){
    console.log('----------------------id',id);

    console.log('-------------this.contrataciones.id ',id );

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={ idanuncio:id};
    const dialogRef = this.matDialog.open(DialogAlbaranesComponent, dialogConfig);


  }
}

<div class="row">
  <div class="col-12">
      <div class="card ">
          <div class="card-body border-bottom">


              <div>
                <form [formGroup]="searchForm" class="d-xl-block">
                  <div class="filters">
                    <input type="text" onkeydown="return false"
                            class="fecha form-control" placeholder="Fecha viaje"
                            onfocus="(this.type='date')"
                            onblur="(this.type= this.value ? 'date' : 'text')"
                            formControlName="fecha_viaje">
                    <input type="search"
                            class="provincia form-control"
                            placeholder="Provincia" formControlName="provincia">
                    <input type="search"
                            class="ciudad form-control"
                            placeholder="Ciudad" formControlName="ciudad">
                    <input type="search"
                            class="calle form-control"
                            placeholder="Calle" formControlName="calle">
                    <input type="search"
                            class="numero form-control"
                            placeholder="Número" formControlName="numero">
                    <input type="search"
                            class="codigoPostal form-control"
                            placeholder="Código Postal" formControlName="codigoPostal">

                    <button type="button" class="filtrar btn btn-primary w-100" [disabled]="!searchForm.valid" (click)="search()">
                      <img src="assets/images/transfriendly/filtro.png" style="padding-right: 15px;">
                      Filtrar
                    </button>
                  </div>
                </form>
              </div>


          </div>

          <div class="card-body">
            <ul>
              <li *ngFor="let offer of listOffers">
                <div class="col-12">
                  <div class="row g-3" style="align-items: center;">
                      <div class="col-sm-8">
                        <label style="display: flex; flex-direction: column; font-size: 1.2em;">
                          <b>Descarga en {{offer.ciudad_destino}}</b>
                        </label>
                        <div style="display: flex; flex-direction: column; gap: 5px;">
                          <span>Lugar: {{offer.direccion_descarga}}</span>
                          <span>Fecha:
                            <span *ngIf="offer.fecha && !offer.fecha.includes('0000')">
                              {{(offer.fecha | date:'d/MM/yyyy') || ''}}</span>
                          </span>
                          <span>Cargador: {{offer.cargador}}</span>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="col-12">
                          <div class="row g-3">
                            <div class="col-sm-6">
                              <button type="button"
                                      class="btn btn-secondary w-100"
                                      (click)="viewModalAnuncio(offer)">
                                  Ver detalle
                              </button>
                            </div>
                            <div class="col-sm-6">
                                <button type="button"
                                    class="btn btn-secondary w-100"
                                    [ngClass]="{'selected':offer.idpuja}"
                                    (click)="declineUnloading(offer)">
                                    Cancelar
                                </button>
                            </div>
                          </div>
                        </div>
                      </div>

                  </div>
                  <div class="row" class="col-sm-12">
                    {{offer.descripcion}}
                  </div>
              </div>
              </li>
            </ul>
          </div>
          <mat-paginator [length]="dataSource.length"
                [pageSize]="size"
                [pageIndex]="page"
                [pageSizeOptions]="[5,10,25,50]"
                (page)="linkListToPaginator($event)">
          </mat-paginator>
      </div>
  </div>
</div>





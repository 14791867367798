<ng-container [formGroup]="informacionAdmin">
  <div class="row mt-2">
    <div class="col-sm-6">
      <label style="color: #000000;">Nombre (Adm. de la cuenta) <span style="color: red;">*</span></label>
      <input type="text"
             placeholder="Nombre"
             class="form-control"
             name="nombreAdm"
             formControlName="nombreAdm"
             style="font-size: 12px; margin-top: -5px;"
             maxlength="190"
             [ngClass]="{'is-invalid': invalidate('nombreAdm').value }">
    </div>

    <div class="col-sm-6">
      <label style="color: #000000;">CIF/NIF <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="CIF/NIF"
              class="form-control"
              name="cifnif"
              formControlName="cifnif"
              style="font-size: 12px; margin-top: -5px;"
              maxlength="95"
              [ngClass]="{'is-invalid': invalidate('cifnif').value }">
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-6">
      <label style="color: #000000;">Teléfono <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="Teléfono"
              class="form-control"
              name="telefono"
              formControlName="telefono"
              style="font-size: 12px; margin-top: -5px;"
              maxlength="40"
              [ngClass]="{'is-invalid': invalidate('telefono').value }">
    </div>

    <div class="col-sm-6">
      <label style="color: #000000;">Correo <span style="color: red;">*</span></label>
      <input type="text"
              placeholder="Correo"
              class="form-control"
              name="correo"
              formControlName="correo"
              style="font-size: 12px; margin-top: -5px;"
              maxlength="190"
              [ngClass]="{'is-invalid': invalidate('correo').value }">
    </div>
  </div>
</ng-container>

<div class="d-lg-flex">
  <div class="chat-leftsidebar me-lg-4 mt-3">
    <div class="tab-content">
      <select class="form-select" [(ngModel)]="selectListUser" (change)="onChange($event)">
        <option *ngFor="let list of listsUser" [value]="list.value" >{{list.item}}</option>
      </select>

      <ngx-simplebar id="list-pujas" class="list-users" style="height: 80vh;" #scrollEle>
        <ul class="list-unstyled chat-list">
          <li class="" *ngFor="let data of chatData">
            <a href="javascript: void(0);" (click)="chatUsername(data)">
              <div class="d-flex">
                <div class="flex-shrink-0 align-self-center me-3">
                  <i *ngIf="!data.pendingRead" class="mdi mdi-circle text-secondary font-size-10"></i>
                  <span *ngIf="data.pendingRead" class="badge rounded-pill bg-danger">{{data.msj_no_leido}}</span>
                </div>
                <div class="flex-shrink-0 align-self-center me-3" *ngIf="data.avatar">
                  <img src="{{ data.avatar }}" class="rounded-circle avatar-xs" alt="">
                </div>
                <div class="avatar-xs align-self-center me-3" *ngIf="!data.avatar">
                  <span class="avatar-title rounded-circle bg-soft bg-primary text-primary">
                    {{data.nombre.charAt(0)}}
                  </span>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="text-truncate font-size-14 mb-1">{{ data.nombre }}</h5>
                  <p class="text-truncate mb-0">{{ data.mensaje }}</p>
                </div>
                <!-- <div class="font-size-11">{{ data.time }}</div> -->
              </div>
            </a>
          </li>
        </ul>
      </ngx-simplebar>

    </div>
  </div>



  <div class="w-100 user-chat">
    <div class="card">
      <div class="p-4 border-bottom">
        <div class="row">
          <div class="col-md-4 col-9">
            <h5 class="font-size-5 mb-1">{{username}}</h5>
            <p class="text-muted mb-0"><i class="mdi mdi-circle text-success align-middle me-1"></i> Active now</p>
          </div>

        </div>
      </div>

      <div>
        <div class="chat-conversation p-3">
          <ngx-simplebar style="height: 470px;" #scrollRef>
            <ul class="list-unstyled">
              <li>
                <div class="chat-day-title">
                  <span class="title">Today</span>
                </div>
              </li>
              <li *ngFor="let data of chatMessagesData" [ngClass]="{'right': data.align === 'right'}">
                <div class="conversation-list">
                  <div class="dropdown" dropdown>
                    <a class="dropdown-toggle" href="javascript: void(0);" role="button" dropdownToggle>
                      <i class="bx bx-dots-vertical-rounded"></i>
                    </a>
                    <div class="dropdown-menu" *dropdownMenu>
                      <a class="dropdown-item" href="javascript: void(0);" (click)="copyMessage($event)">Copy</a>
                      <a class="dropdown-item" href="javascript: void(0);">Save</a>
                      <a class="dropdown-item" href="javascript: void(0);">Forward</a>
                      <a class="dropdown-item" href="javascript: void(0);" (click)="deleteMessage($event)">Delete</a>
                    </div>
                  </div>
                  <div class="ctext-wrap">
                    <div class="conversation-name">{{ data.nombre_usuario_envia }}</div>
                    <p>
                      {{ data.mensaje }}
                    </p>
                    <p class="chat-time mb-0"><i class="bx bx-time-five align-middle me-1"></i> {{ data.tiempo }}</p>
                  </div>

                </div>
              </li>
            </ul>
          </ngx-simplebar>
        </div>
        <div class="p-3 chat-input-section">

          <form (ngSubmit)="messageSave()" [formGroup]="formData" class="row">
            <div class="col">
              <div class="position-relative">
                <input type="text"
                       class="form-control chat-input"
                       placeholder="{{disabledChat ? 'Chat deshabilitado' : 'Enter Message...'}}"
                       formControlName="message"
                       [ngClass]="{'is-invalid': chatSubmit && form.message.errors}"
                       [(ngModel)]="emoji"
                       (blur)="onBlur()"
                       (focus)="onFocus()">

                      <div *ngIf="chatSubmit && form.message.errors" class="invalid-feedback">
                        <span *ngIf="form.message.errors.required">
                          This value is required.
                        </span>
                      </div>

                      <div class="chat-input-links">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <a href="javascript: void(0);"
                               class="emoji-btn"
                               data-toggle="tooltip"
                               placement="top"
                               tooltip="Emoji"
                               id="emoji-btn"
                               (click)="toggleEmojiPicker()">
                               <i class="mdi mdi-emoticon-happy-outline"></i>
                            </a>
                          </li>

                          <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)" title="Pick your emoji…">
                          </emoji-mart>
                        </ul>
                      </div>
              </div>
            </div>

            <div class="col-auto">
              <button type="submit"
                      class="btn btn-primary btn-rounded chat-send w-md"
                      [disabled]="disabledChat">
                      <span class="d-sm-inline-block me-2">Send</span>
                      <i class="mdi mdi-send"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>

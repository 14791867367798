import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { DialogPujaComponent } from '../dialog-puja/dialog-puja.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';
import { DialogAnuncioComponent } from '../dialog-anuncio/dialog-anuncio.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { merge } from 'rxjs/internal/observable/merge';
import Swal from 'sweetalert2';

interface Offer {
  id: number,
  meses_transcurridos: number,
  dias_transcurridos: number,
  horas_transcurridos: number,
  minutos_transcurridos: number,
  ciudad_origen: string,
  ciudad_destino: string,
  puja_minima: number,
  descripcion: string,
  tipo_viaje: string,
  cargador: string,
  monto: number,
  nombre_cargador: string,
  fecha_viaje: Date,
  codigo_anuncio: string,
  forma_pago: string,
  transporte: string,
  idpuja?:string
};

interface typeVehicle {
  idtipotransporte: number,
  nombre: string,
  carga_util: string,
  descripcion: string,
  tipo_carga: number
}
@Component({
  selector: 'app-ofertas-guardadas',
  templateUrl: './ofertas-guardadas.component.html',
  styleUrls: ['./ofertas-guardadas.component.scss']
})
export class OfertasGuardadasComponent {

  searchForm: FormGroup;

  listOffers:Offer[] = [];
  dataSource:Offer[] = [];
  page:number=0;
  size:number=5;

  listTypeVehicles:typeVehicle[] = [];
  public currentRate = 3.14;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public dialog: MatDialog,
    private servicesService: ServicesService,
    private snackBar:MatSnackBar,
    private fb: FormBuilder){
      this.loadSearch();
      this.loadData();
      this.loadListVehicles();
  }

  public ariaValueText(current: number, max: number) {
		return `${current} out of ${max} hearts`;
	}

  linkListToPaginator(obj) {
    const from = obj.pageIndex * obj.pageSize;
    const to = from + obj.pageSize;
    this.listOffers = this.dataSource.slice(from, to);
  }

  loadData(){

    // Toma los valores de parámetros de búsqueda
    let pSearch={};
    Object.entries(this.searchForm.value).forEach((entry)=>{
      const [key, value] = entry;
      if(value) pSearch[key]= value;
    })

    this.servicesService.getListOffersSave(pSearch).subscribe((resp: any) => {
      if (resp.estatus == 'ok') {
        this.listOffers = resp.response.map(o=>{
          // Se adiciona en la respuesta la validación si hay puja y si la puja es menor al monto del anuncio
          if(o.puja_minima){
            o.validPuja = parseInt(o.puja_minima) < (parseInt(o.monto) * 0.91);
          }else{
            o.validPuja = false;
          }
          return o;
        });
        this.dataSource = this.listOffers;
        this.linkListToPaginator({pageIndex: this.page, pageSize: this.size});
      }
    });
  }

  loadListVehicles(){
    this.servicesService.getListTypeVehicles().subscribe((resp: any) => {
      if (resp.estatus == 'ok') {
        this.listTypeVehicles = resp.response.map(v =>{
          return{...v, carga_util: v.carga_util.replace(',',' ')}
        });
      }
    })
  }

  loadSearch(){
    this.searchForm = this.fb.group({
      codigo:[''],
      origen:[''],
      destino:[''],
      desde:[''],
      hasta:[''],
      tipo_camion:['']
    });

    merge(this.searchForm.get('desde').valueChanges,
          this.searchForm.get('hasta').valueChanges)
    .subscribe(() =>{
      this.setValidatorsDate(this.searchForm.get('desde').value,this.searchForm.get('hasta').value);
    })
  }

  setValidatorsDate(desde:string, hasta:string){
    if((!desde && hasta) || (desde && !hasta)){
      this.searchForm.get('desde').setErrors({'required': true});
    }else if(new Date(desde) > new Date(hasta)){
      this.searchForm.get('desde').setErrors({'rango': true});
    }else{
      this.searchForm.get('desde').setErrors(null)
    }
  }

  // Método para acceso fácil a los campos del formulario
  get f() { return this.searchForm.controls; }

  search(){
    this.listOffers = [];
    this.dataSource = [];
    this.loadData();
  }

  async viewModalAnuncio(iOffer:Offer){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data ={idanuncio: iOffer.id};

    const dialogRef = this.dialog.open(DialogAnuncioComponent, dialogConfig);
  }

  viewModal(iOffer:Offer){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    /** Dado que el modal puede tener varias vistas ('view', 'sended'),
     * se envía el valor de parámetro 'view'
     * que permite tener la vista para configurar la puja. */
    dialogConfig.data = {... iOffer,
                        listTypeVehicles:this.listTypeVehicles,
                        mode:'view'}
    const dialogRef = this.dialog.open(DialogPujaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if(data){
        // Resalta el botón de Puja
        const idx = this.listOffers.findIndex(o => o.id == iOffer.id);
        this.listOffers[idx]['idpuja'] = (data.idpuja)?data.idpuja:1;
      }
    })
  }

  // getTime(itemOffer:Offer){
  //   if(itemOffer.meses_transcurridos > 0){
  //     return `${itemOffer.meses_transcurridos} meses`;
  //   }else if(itemOffer.dias_transcurridos > 0){
  //     return `${itemOffer.dias_transcurridos} días`;
  //   }else if(itemOffer.horas_transcurridos > 0){
  //     return `${itemOffer.horas_transcurridos} horas`;
  //   }else if(itemOffer.minutos_transcurridos > 0){
  //     return `${itemOffer.minutos_transcurridos} minutos`;
  //   }
  // }
  getTime(itemOffer:any){
    let tiempo:string;

    if(itemOffer.minutos_transcurridos >= 0 && itemOffer.minutos_transcurridos < 60)
    { tiempo = itemOffer.minutos_transcurridos + ' minutos' }

    if(itemOffer.horas_transcurridos >= 1)
    { tiempo = itemOffer.horas_transcurridos + ' horas' }

    if(itemOffer.dias_transcurridos >= 1)
    { tiempo = itemOffer.dias_transcurridos + ' dias' }

    if(itemOffer.meses_transcurridos >= 1)
    { tiempo = itemOffer.meses_transcurridos + ' meses' }

    return tiempo;
  }

  async saveOffer(iOffer:Offer){

    try{
      let dataPuja = {id_anuncio: iOffer.id};
      const resp = await this.apiSaveOffer(dataPuja);
      if(resp['code'] == 200){
        // Elimina el anuncio de la lista de favoritos
        let idx = this.listOffers.findIndex(o => o.id == iOffer.id);
        this.listOffers.splice(idx,1);
        idx = this.dataSource.findIndex(o => o.id == iOffer.id);
        this.dataSource.splice(idx,1);


        this.snackBar.open(resp['mensaje'],'Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }else{
        throw new Error();
      }
    }catch{

      this.snackBar.open('Esta oferta no se logró guardar en favoritos','Ok',{
        duration:3000,
        horizontalPosition:right,
        verticalPosition:bottom,
      });
    }
  }

  apiSaveOffer(data:any){
    // Parámetro para retirar el anuncio de favoritos
    data.accion = 2;
    return new Promise((resolve, reject) => {
      this.servicesService.saveOffer(data).subscribe((resp: any) => {
        resolve(resp)
      },error=>{
        reject(error)
      })
    })
  }

  deletePuja(iOffer:Offer) {
    Swal.fire({
      title: `Esta seguro que desea eliminar su puja para el anuncio ${iOffer.codigo_anuncio}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await this.apiDeletePuja({id_puja:iOffer.idpuja});
        if(resp['code'] == 200){
          // Quita la identificación de anuncio pujado
          const idx = this.listOffers.findIndex(o => o.id == iOffer.id);
          this.listOffers[idx]['idpuja'] = null;

          this.snackBar.open(resp['mensaje'],'Ok',{
            duration:3000,
            horizontalPosition:right,
            verticalPosition:bottom,
          });
        }

      }
    })
  }

  apiDeletePuja(data:any){
    // Parámetro para adicionar el anuncio de favoritos
    return new Promise((resolve, reject) => {
      this.servicesService.deletePuja(data).subscribe((resp: any) => {
        resolve(resp)
      },error=>{
        reject(error)
      })
    })
  }

}

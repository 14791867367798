<ul>
  <li *ngFor="let articulo of articulos">

    <div class="row  mb-4" *ngIf="articulo.ocupacion_completa === 1">
      <div class="col-12">
        <div >
          <label>Ocupación completa del vehículo</label>
        </div>
      </div>
    </div>

    <div class="container">

      <div *ngIf="articulo.descripcion">
        <label>Descripción</label>
        <div>{{articulo.descripcion}}</div>
      </div>

      <div *ngIf="(articulo.peso_articulo !== null || articulo.largo_articulo !== null || articulo.ancho_articulo !== null || articulo.alto_articulo !== null) && articulo.cantidad">
        <label>Cantidad</label>
        <div>{{articulo.cantidad}}</div>
      </div>

      <div *ngIf="articulo.peso_articulo">
        <label>Peso</label>
        <div>{{articulo.peso_articulo}} Kg</div>
      </div>

      <div *ngIf="articulo.volumen">
        <label>Volumen</label>
        <div>{{articulo.volumen}}</div>
      </div>

      <div *ngIf="articulo.largo_articulo">
        <label>Largo</label>
        <div>{{articulo.largo_articulo}} cm</div>
      </div>

      <div *ngIf="articulo.ancho_articulo">
        <label>Ancho</label>
        <div>{{articulo.ancho_articulo}} cm</div>
      </div>

      <div *ngIf="articulo.alto_articulo">
        <label>Alto</label>
        <div>{{articulo.alto_articulo}} cm</div>
      </div>

      <div *ngIf="articulo.id_unidad_medida">
        <label>Unidad de medida</label>
        <div *ngIf="articulo.id_unidad_medida === 2">
          Onzas
        </div>
        <div *ngIf="articulo.id_unidad_medida === 1">
          Litros
        </div>
      </div>

      <div *ngIf="articulo.se_paletiza">
        <label *ngIf="(articulo.peso_articulo !== null || articulo.largo_articulo !== null || articulo.ancho_articulo !== null || articulo.alto_articulo !== null)">Paletizable</label>
        <!-- <div>{{articulo.se_paletiza}}</div> -->
        <div *ngIf="(articulo.peso_articulo !== null || articulo.largo_articulo !== null || articulo.ancho_articulo !== null || articulo.alto_articulo !== null) && articulo.se_paletiza === '0,0'">
          No ha sido seleccionado ninguno
        </div>

        <div *ngIf="articulo.se_paletiza === '1,0'">
          Paletizado
        </div>

        <div *ngIf="articulo.se_paletiza === '0,2'">
          Apilable
        </div>

        <div *ngIf="articulo.se_paletiza === '1,2'">
          Paletizado y Apilable
        </div>
      </div>

      <div *ngIf="articulo.tipo_envio !== '' && articulo.tipo_envio !== ',' && articulo.tipo_envio !== null">
        <label>Tipo de envío</label>
        <div>{{articulo.tipo_envio}}</div>
      </div>

      <div *ngIf="articulo.volumen && (articulo.tipo_envio === ',' || articulo.tipo_envio === '' || articulo.tipo_envio === null)">
        <label>Tipo de envío</label>
        <div>No ha sido seleccionado ninguno</div>
      </div>

      <div *ngIf="articulo.transporta_alimento">
        <label *ngIf="(articulo.peso_articulo !== null || articulo.volumen)">Transporta alimentos?</label>
        <div *ngIf="articulo.transporta_alimento === 1">
          Alimentario
        </div>

        <div *ngIf="(articulo.peso_articulo !== null || articulo.volumen) && articulo.transporta_alimento === 2">
          No Alimentario
        </div>
      </div>

      <div *ngIf="articulo.url">
        <a href="{{articulo.url}}" target="_blank"><i class="fa fa-external-link-alt"></i></a>
      </div>
    </div>

  </li>
</ul>

<ng-container *ngIf="false">
  <div class="form-group row my-4">
    <div class="linea">
      <div class="row">
        <div class="col-sm-2">
          <div>
            <label style="color: #000000;">Volumen</label>
            <input type="text" placeholder="Volumen" class="form-control" style="font-size: 12px; margin-top: -5px;">
          </div>
        </div>

        <div class="col-sm-2">
          <div class="form-group">
            <label style="color: #000000;">Unidad de medida</label>
            <input type="text" placeholder="Volumen" class="form-control" style="font-size: 12px; margin-top: -5px;">
          </div>
        </div>

        <div class="col-sm-5">
          <div class="form-group">
            <label style="color: #000000;">Descripción</label>
            <input type="text" placeholder="Descripción" class="form-control"
              style="font-size: 12px; margin-top: -5px;">
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <div>
              <label style="color: #000000;">Su envío es...</label>
            </div>
            <div class="form-check a">
              <input class="form-check-input" type="checkbox" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                Isotérmico
              </label>
            </div>

            <div class="form-check a">
              <input class="form-check-input" type="checkbox" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                Precinto
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="false">
  <div class="form-group">
    <div class="linea my-4">
      <div class="row my-2">
        <div class="col-sm-3">
          <div class="form-group">
            <label style="color: #000000;">Peso del artículo</label>
            <input type="text" placeholder="Peso del artículo" class="form-control"
              style="font-size: 12px; margin-top: -5px;">
          </div>
        </div>

        <div class="col-sm-3">
          <label style="color: #000000;">Dimensiones</label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default" style="margin-top: -5px;">Largo</span>
            <input type="text" placeholder="en cm" class="form-control" aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default" style="margin-top: -5px;">
          </div>
        </div>

        <div class="col-sm-3">
          <label style="color: #000000;"><span> </span></label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default" style="margin-top: 3px;">
              Ancho
            </span>
            <input type="text" placeholder="en cm" class="form-control" aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default" style="margin-top: 3px;">
          </div>
        </div>

        <div class="col-sm-3">
          <label style="color: #000000;"><span> </span></label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default" style="margin-top: 3px;">
              Alto
            </span>

            <input type="text" placeholder="en cm" class="form-control" aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              style="margin-top: 3px; border-top-right-radius:4px; border-bottom-right-radius:4px;">
          </div>
        </div>
      </div>


      <div class="row my-2">
        <div class="col-sm-3">
          <label style="color: #000000;">Cantidad de artículos <span style="color: red;">*</span></label>
          <div>
            <input style="margin-top: -5px; text-align: center;" type="text" class="form-control" placeholder="25"
              aria-label="Example text with button addon" aria-describedby="button-addon1">
          </div>
        </div>


        <div class="col-sm-3">
          <div>
            <label style="color: #000000;">Su artículo es...</label>
          </div>

          <div class="form-check a">
            <input class="form-check-input" type="checkbox" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
              Paletizado
            </label>
          </div>

          <div class="form-check a">
            <input class="form-check-input" type="checkbox" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
              Apilable
            </label>
          </div>
        </div>


        <div class="col-sm-3">
          <div>
            <label style="color: black;">Foto de la carga</label>
          </div>

          <div class="text-center">
            <a [href]="'www.google.com'" target="_blank">{{ 'www.google.com' }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="false">
  <div class="form-group">
    <div class="row my-4">
      <div class="col-sm-2">
        <div class="form-group">
          <label style="color: #000000;">Peso del artículo</label>
          <input type="text" placeholder="Peso del artículo" class="form-control"
            style="font-size: 12px; margin-top: -5px;">
        </div>
      </div>

      <div class="col-sm-2">
        <label style="color: #000000;">Dimensiones</label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="inputGroup-sizing-default" style="margin-top: -5px;">Largo</span>
          <input type="text" placeholder="en cm" class="form-control" aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default" style="margin-top: -5px;">
        </div>
      </div>

      <div class="col-sm-2">
        <label style="color: #000000;"><span> </span></label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="inputGroup-sizing-default" style="margin-top: 3px;">
            Ancho
          </span>
          <input type="text" placeholder="en cm" class="form-control" aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default" style="margin-top: 3px;">
        </div>
      </div>

      <div class="col-sm-2">
        <label style="color: #000000;"><span> </span></label>
        <div class="input-group mb-3">
          <span class="input-group-text" id="inputGroup-sizing-default" style="margin-top: 3px;">
            Alto
          </span>

          <input type="text" placeholder="en cm" class="form-control" aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            style="margin-top: 3px; border-top-right-radius:4px; border-bottom-right-radius:4px;">
        </div>
      </div>

      <div class="col-sm-2">
        <label style="color: #000000;">Cantidad de artículos <span style="color: red;">*</span></label>
        <div>
          <input style="margin-top: -5px; text-align: center;" type="text" class="form-control" placeholder="25"
            aria-label="Example text with button addon" aria-describedby="button-addon1">
        </div>
      </div>

      <div class="col-sm-2">
        <div>
          <label style="color: black;">Foto de la carga</label>
        </div>

        <div class="text-center">
          <a [href]="'www.google.com'" target="_blank">{{ 'www.google.com' }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="false">
  <div class="form-group">
    <div class="row my-4">
      <div class="col-sm-3">
        <div class="form-group">
          <label style="color: #000000;">Peso del artículo</label>
          <input type="text" placeholder="Peso del artículo" class="form-control"
            style="font-size: 12px; margin-top: -5px;">
        </div>
      </div>


      <div class="col-sm-3">
        <label style="color: #000000;">Volumen</label>
        <input type="text" placeholder="Volumen" class="form-control" style="font-size: 12px; margin-top: -5px;">
      </div>


      <div class="col-sm-3">
        <div>
          <label style="color: black;">Foto de la carga</label>
        </div>

        <div class="text-center">
          <a [href]="'www.google.com'" target="_blank">{{ 'www.google.com' }}</a>
        </div>
      </div>


      <div class="col-sm-3">
        <div>
          <label style="color: #000000;">Su artículo es...</label>
        </div>

        <div class="form-check a">
          <input class="form-check-input" type="radio" id="flexCheckDefault" value="1">
          <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
            Alimentario
          </label>
        </div>

        <div class="form-check a">
          <input class="form-check-input" type="radio" id="flexCheckDefault" value="2">
          <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
            No Alimentario
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="false">
  <div class="row my-4">
    <div class="col-sm-6">
      <div class="form-group">
        <label>Descripción</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea>
      </div>
    </div>

    <div class="col-sm-2">
      <label style="color: #000000;">Cantidad de artículos</label>
      <input style="text-align: center;" type="text" class="form-control" placeholder="25"
        aria-label="Example text with button addon" aria-describedby="button-addon1">
    </div>

    <div class="col-sm-4">
      <div>
        <label style="color: black;">Foto de la carga</label>
      </div>

      <div class="text-center">
        <a [href]="'www.google.com'" target="_blank">{{ 'www.google.com' }}</a>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="false">
  <div class="form-group">
    <div class="row my-4">
      <div class="col-sm-8">
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Descripción</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea>
        </div>
      </div>

      <div class="col-sm-4">
        <div>
          <label style="color: black;">Foto de la carga</label>
        </div>

        <div class="text-center">
          <a [href]="'www.google.com'" target="_blank">{{ 'www.google.com' }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

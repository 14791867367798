import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { bottom, right } from '@popperjs/core';

@Component({
  selector: 'app-dialog-puja',
  templateUrl: './dialog-puja.component.html',
  styleUrls: ['./dialog-puja.component.scss']
})

export class DialogPujaComponent implements OnInit {

  vehicles = [];
  mode:string = null;
  dataDialog: any;
  pujaForm: FormGroup;
  dataPuja:any;

  constructor(@Inject(MAT_DIALOG_DATA)
              private data: any,
              private dialogRef: MatDialogRef<DialogPujaComponent>,
              private fb: FormBuilder,
              private currencyPipe:CurrencyPipe,
              private servicesService: ServicesService,
              private snackBar:MatSnackBar,){
    this.dataDialog = data;
    this.mode = data.mode;
    this.loadInfoPuja(data);
  }

  ngOnInit():void {}

  async loadInfoPuja(dataAnuncio:any){

    this.pujaForm = this.fb.group({
      valor:[null,[Validators.required]],
      comentario:[null],
      fecha_viaje:[null],
      tipo_vehiculo:[null, [Validators.required]]
    });

    if(dataAnuncio.idpuja){

      const dataPuja = await this.loadDataPuja(dataAnuncio['id']);

      if(dataPuja){
        this.pujaForm.patchValue({
          valor:dataPuja['valor'],
          comentario:dataPuja['comentario'],
          fecha_viaje:dataPuja['fecha_viaje'],
          tipo_vehiculo:dataPuja['id_tipovehiculo']
        })
      }
    }

  }

  async loadDataPuja(id:number){
    return new Promise((resolve) => {
      this.servicesService.getPuja({id_anuncio:id}).subscribe((resp: any) => {
        if (resp.code == 200) {
          resolve(resp.pujas[0]);
        }
        resolve(null);
      })
    });
  }

  blurCurrency(nameField:string){
    let field = this.pujaForm.controls[nameField];
    if(field.value){
      let tValue = this.clearCurrency(field.value);
      field.patchValue(this.currencyPipe.transform(tValue,'','','1.0-2')+' €');
    }
  }

  clearCurrency(value:string):string{
    return value.replace('.00','').replace(/[^0-9.]/g,'').replace(/^0+/,'');
  }

  selTypeVehicle(idTypeVehicle:number){
    this.pujaForm.controls['tipo_vehiculo'].patchValue(idTypeVehicle);
  }

  // Método para acceso fácil a los campos del formulario
  get f() { return this.pujaForm.controls; }

  close(): void {
    this.dialogRef.close();
  }

  async send() {

    if(this.pujaForm.valid){
      let dataPuja = this.pujaForm.value;
      dataPuja.id_anuncio = this.dataDialog.id;
      //dataPuja.valor=parseFloat(this.clearCurrency(dataPuja.valor));

      try
      {
        console.log('dataPuja', dataPuja);
        const resp = await this.sendPuja(dataPuja);

        if(resp['code'] == 200){
          this.mode = 'sended';
        }else{
          throw new Error();
        }
      }
      catch
      {
        this.snackBar.open('No se pudo enviar la puja para esta oferta','Ok',{
          duration:3000,
          horizontalPosition:right,
          verticalPosition:bottom,
        });
      }
    }
  }

  sendPuja(data:any){
    return new Promise((resolve, reject) => {
      this.servicesService.sendPuja(data).subscribe((resp: any) => {
        resolve(resp)
      },error=>{
        reject(error)
      })
    })
  }

  closeSended(): void {
    this.dialogRef.close({idpuja:this.dataDialog.idpuja});
  }
}

<label style="display: flex; flex-direction: row; align-items: center; gap: 20px; font-size: 25px;">
  <a [routerLink]="['../../../cargador']"><i class="sidenav-link-icon" class="fa fa-angle-left" style="font-size: 1.5em;color: #777;"></i></a>
  <b style="color:black">Gestión propuesta</b>
</label>

<div class="row mt-2">
  <div class="col-12">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Mi propuesta</a>
        <ng-template ngbNavContent >
          <div class="col-12 p-3 border-bottom" style="background: #FFFFFF;">
            <div class="row g-3">
              <div class="col-sm-12">
                <div><i>Código: {{get('codigo_anuncio','info_anuncio')}}</i></div>
                <label style="display: flex; flex-direction: column; font-size: 14px;">
                  <b>{{get('ciudad_origen','info_anuncio')}} - {{get('ciudad_destino','info_anuncio')}}</b></label>
                <div>
                </div>
              </div>
            </div>
            <div class="row" class="col-sm-12 py-3">
              <div style="display: flex; flex-direction: row; align-items: center; gap: 12px;">
                <span>{{get('presupuesto','info_anuncio') | formatNumberPipe }} €</span>
                <span><img src="assets/images/transfriendly/point.png"></span>
                <span>Pago por {{get('forma_pago','info_anuncio')}}</span>
                <span><img src="assets/images/transfriendly/point.png"></span>
                <span>{{get('tipo_viaje','info_anuncio')}}</span>
              </div>
            </div>
            <div class="col-sm-12">
              {{get('descripcion','info_anuncio')}}
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <div class="form-group">
                  <div>
                    <label style="color: #000000;">Fecha de salida</label>
                  </div>
                  <div>
                    {{get('fechasalida','info_anuncio') | date:'dd/MM/yyyy'}}
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <div>
                    <label style="color: #000000;">Tipo de contrato</label>
                  </div>
                  <div>
                    {{get('tipo_contrato','info_anuncio')}}
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <div>
                    <label style="color: #000000;">Tipo de Vehiculo</label>
                  </div>

                  <div>
                    {{get('tipo_transporte','info_anuncio')}}
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <div>
                    <label style="color: #000000;">Tiempo de Pago</label>
                  </div>

                  <div>
                    {{get('tiempo_pago','info_anuncio')}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12">
                <div class="form-group">
                  <div>
                    <label style="color: #000000;">El punto de carga cuenta con...</label>
                  </div>

                  <div>
                    {{get('cuenta_con','info_anuncio')}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-3 border-bottom" style="background: #FFFFFF;">
            <div class="mb-3" style="color: #000000; font-size: 1.3em;"><b>Trayecto</b></div>
            <div class="mb-3" style="border-bottom: 1px solid #ddd;" *ngFor="let trayecto of this.infoAnuncio?.trayecto">
              <div class="row col-12">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div>
                      <label style="color: #000000;">Origen</label>
                    </div>
                    <div>
                      {{trayecto.calle_origen}} {{trayecto.numero_origen}} - {{trayecto.ciudad_origen}}, {{trayecto.provincia_origen}}
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <div>
                      <label style="color: #000000;">Destino</label>
                    </div>
                    <div>
                      {{trayecto.calle_destino}} {{trayecto.numero_destino}} - {{trayecto.ciudad_destino}}, {{trayecto.provincia_destino}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row col-12 mt-3">

                <div class="col-sm-4">
                  <div class="form-group">
                    <div>
                      <label style="color: #000000;">Fecha máxima de entrega</label>
                    </div>

                    <div *ngIf="trayecto.fecha_max_entrega && !trayecto.fecha_max_entrega.includes('0000')">
                      {{trayecto.fecha_max_entrega | date:'dd/MM/yyyy'}}
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <div>
                      <label style="color: #000000;">Acta de la entrega</label>
                    </div>

                    <div>
                      <a *ngIf="trayecto.url" href="{{trayecto.url}}">archivo</a>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <div>
                      <label style="color: #000000;">El punto de descarga cuenta con...</label>
                    </div>

                    <div>
                      {{trayecto.cuenta_con}}
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-sm-12 mt-3">
                <div class="form-group">
                  <div>
                    <label style="color: #000000;">Observaciones</label>
                  </div>

                  <div>
                    {{trayecto.descripcion}}
                  </div>
                </div>

              </div>
              <div class="row mt-3">

                <div class="col-sm-6">
                  <div class="form-group">
                    <div>
                      <label style="color: #000000;">Quien recibe</label>
                    </div>

                    <div>
                      {{trayecto.nombre_descargador}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Pujas</a>
        <ng-template ngbNavContent>
          <app-gestion-pujas [idAnuncio]="idAnuncio" (vMessage)="goToTab($event)"></app-gestion-pujas>
        </ng-template>
      </li>

    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>

<div class="container-fluid">

  <ngx-spinner type="ball-scale-multiple" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff">
    <p style="font-size: 20px; color: white">Cargando...</p>
  </ngx-spinner>

  <div class="row">
    <div class="col-12">
      <div class="card">
          <div class="card-body border-bottom">
            <form class="d-xl-block" [formGroup]="searchUsers">
              <div class="row g-3">
                <div class="col-sm-6">
                  <input type="search"
                         class="form-control"
                         id="searchInput"
                         placeholder="Buscar..."
                         formControlName="nombre"
                        >
                </div>

                <div class="col-sm-3">
                  <!-- <input type="search"
                         class="form-control"
                         id="searchInput"
                         placeholder="Perfil"> -->
                  <select class="form-select" formControlName="perfil">
                    <option value="" selected>Perfil</option>
                    <option *ngFor="let perfil of listPerfiles" [value]="perfil.id" >{{perfil.descripcion}}</option>
                  </select>
                </div>

                <div class="col-sm-3">
                  <button type="button"
                          class="btn btn-primary w-100"
                          (click)="searchUsuarios()">
                              <!-- <i class="mdi mdi-filter-outline align-middle"></i> -->
                            Filtrar
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body">
              <div class="table-responsive">
                  <table class="table table-bordered align-middle nowrap">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Perfil para aprobación</th>
                        <th scope="col">Correo</th>
                        <th scope="col">Usuario</th>
                        <th scope="col">Seguro</th>
                        <th scope="col">Carta NA</th>
                        <th scope="col">Acción</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let item of pagina">
                        <td>{{ item.id_usuario }}</td>
                        <td>{{ item.name }}</td>
                        <!-- <td *ngIf="item.perfil == 1">Administrador</td>
                        <td *ngIf="item.perfil == 2">Cargador</td>
                        <td *ngIf="item.perfil == 3">Descargador</td>
                        <td *ngIf="item.perfil == 4">Transportista</td> -->
                        <td>{{ getPerfil(item.perfil) }}</td>
                        <td>{{ item.email }}</td>
                        <td>
                          <span class="badge" [ngClass]="{ 'bg-success': item.status === 'Activo', 'bg-info': item.status === 'Nuevo', 'bg-danger': item.status === 'Inactivo'}">
                            {{ item.status }}
                          </span>
                        </td>
                        <td style="color: #0000FF;">
                          <span *ngIf="item.archivo?.archivo0" style="cursor: pointer;" (click)="downloadFile(item.email,1, item.archivo.archivo0.nombre_archivo)">
                            {{ item.archivo.archivo0.nombre_archivo }}
                          </span>
                        </td>
                        <td style="color: #0000FF;">
                          <span *ngIf="item.archivo?.archivo1" style="cursor: pointer;" (click)="downloadFile(item.email,2, item.archivo.archivo1.nombre_archivo)">
                            {{ item.archivo.archivo1.nombre_archivo }}
                          </span>
                        </td>

                        <td>
                          <ul class="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Aprobar">
                                <a class="btn btn-sm btn-soft-primary2"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg"
                                   (click)="checkValidaUsuario(item.email, item.status, item.id_usuario)">
                                   <i class="mdi mdi-check"></i>
                                </a>
                            </li>

                            <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Borrar">
                                <a *ngIf="item.status == 'Inactivo'" class="btn btn-sm btn-soft-danger"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg">
                                   <i class="mdi mdi-close"></i>
                                </a>

                                <a *ngIf="item.status == 'Nuevo'" class="btn btn-sm btn-soft-danger"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg"
                                   (click)="invalidarUsuario(usuarioRechazado,item.id_usuario)">
                                   <i class="mdi mdi-close"></i>
                                </a>
                            </li>

                            <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Ver">
                                <a *ngIf="(item.perfil == '1' || item.perfil == '2' || item.perfil == '3') && (item.status != 'Inactivo')" class="btn btn-sm btn-soft-primary"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg"
                                   (click)="largeModal(registroCargadorDescargador, item.id_usuario)">
                                   <i class="mdi mdi-eye-outline"></i>
                                </a>

                                <a *ngIf="(item.perfil == '4') && (item.status != 'Inactivo')" class="btn btn-sm btn-soft-primary"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg"
                                   (click)="largeModal(registroTransportistaNatural, item.id_usuario)">
                                   <i class="mdi mdi-eye-outline"></i>
                                </a>

                                <a *ngIf="item.status == 'Inactivo'" class="btn btn-sm btn-soft-primary"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg"
                                   (click)="largeModal(usuarioDenegado, item.id_usuario)">
                                   <i class="mdi mdi-eye-outline"></i>
                                </a>
                            </li>

                            <!-- <li data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Editar">
                                <a class="btn btn-sm btn-soft-info"
                                   data-bs-toggle="modal"
                                   data-bs-target=".bs-example-modal-lg">
                                   <i class="mdi mdi-pencil-outline"></i>
                                </a>
                            </li> -->
                          </ul>
                        </td>
                      </tr>

                    </tbody>
                  </table>
              </div>

              <div class="row justify-content-between align-items-center">
                <div class="d-flex justify-content-between p-2">
                  <ngb-pagination [collectionSize]="collectionSize"
                                  [(page)]="page"
                                  [pageSize]="pageSize"
                                  (pageChange)="refreshPagina()">
                  </ngb-pagination>

                  <select class="form-select"
                          style="width: auto"
                          [(ngModel)]="pageSize"
                          (ngModelChange)="refreshPagina()">
                    <option [ngValue]="5">5 items por página</option>
                    <option [ngValue]="10">10 items por página</option>
                    <option [ngValue]="25">25 items por página</option>
                    <option [ngValue]="50">50 items por página</option>
                  </select>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>


<ng-template #registroTransportistaNatural let-modal>
  <div class="modal-header">
    <div class="modal-tags">
      <h5 class="modal-title" id="myLargeModalLabel">Detalles del Registro</h5>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
  </div>

  <div class="modal-body">
      <div class="row">
        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Nombre</b></label>
          </div>
          <div>
            <label>{{ usuario?.name }}</label>
          </div>
        </div>

        <div class="col-sm-2">
          <div>
            <label style="color: black;"><b>Estado</b></label>
          </div>
          <div>
            <label>
              <span [ngClass]="{
                'activo': usuario?.status == 'Activo',
                'nuevo': usuario?.status == 'Nuevo',
                'inactivo': usuario?.status == 'Inactivo'}">
              {{ usuario?.status }}
              </span>
            </label>
          </div>
        </div>

        <div class="col-sm-2">
          <div>
            <label style="color: black;"><b>CIF/NIF</b></label>
          </div>
          <div>
            <label>{{ usuario?.cif_nif }}</label>
          </div>
        </div>

        <div class="form-group" class="col-sm-5">
          <div>
            <label style="color: black;"><b>Correo</b></label>
          </div>
          <div>
            <label id="emailA" #emailA>{{ usuario?.email }}</label>
          </div>
        </div>

      </div>

      <div class="row mt-2">
        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Carta NA</b></label>
          </div>
          <div>
            <label
              (click)="downloadFile(usuario.email,1, usuario.archivo.archivo0.nombre_archivo)"
              style="color: #0000FF;
              cursor: pointer;">{{ usuario?.archivo?.archivo0.nombre_archivo }}</label>
          </div>
        </div>

        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Fecha Vencimiento</b></label>
          </div>
          <div>
            <label>{{ usuario?.archivo?.archivo0.fecha_vencimiento }}</label>
          </div>
        </div>

        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Seguro</b></label>
          </div>
          <div>
            <label
              (click)="downloadFile(usuario.email,2, usuario.archivo.archivo1.nombre_archivo)"
              style="color: #0000FF;
              cursor: pointer;">{{ usuario?.archivo?.archivo1.nombre_archivo }}</label>
          </div>
        </div>

        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Fecha Vencimiento</b></label>
          </div>
          <div>
            <label>{{ usuario?.archivo?.archivo1.fecha_vencimiento }}</label>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-9">
          <div>
            <label style="color: black;"><b>Dirección</b></label>
          </div>
          <div>
            <label>{{ usuario?.direccion }}</label>
          </div>
        </div>

        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Teléfono</b></label>
          </div>
          <div>
            <label>{{ usuario?.telefono }}</label>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-3">
          <div>
            <label style="color: black;"><b>Perfil para aprobación </b></label>
          </div>
          <div>
            <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '1'">Administrador</label>
            <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '2'">Cargador</label>
            <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '3'">Descargador</label>
            <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '4'">Transportista</label>
          </div>
        </div>

        <div class="col-sm-6" *ngIf="usuario.status && usuario?.status != 'Nuevo'">
          <div>
            <label style="color: black;"><b>Perfiles activos</b></label>
          </div>
          <div>
            <label *ngIf="checkPerfil(2)"
                    style="color: white;
                          background-color: #FF6C2B;
                          padding: 4px;
                          margin: 3px;
                          border-radius: 4px;">Cargador</label>
            <label *ngIf="checkPerfil(4)"
                    style="color: white;
                          background-color: #0F4C81;
                          padding: 4px;
                          margin: 3px;
                          border-radius: 4px;">Transportista</label>
            <label *ngIf="checkPerfil(3)"
                    style="color: white;
                          background-color: #0f8113;
                          padding: 4px;
                          margin: 3px;
                          border-radius: 4px;">Descargador</label>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="usuario?.admin && usuario?.admin[0]?.cif_nif_admin">
        <h5 style="color: #FF6C2B;margin: 1.5em 0 1em" class="modal-title border-bottom">Administrador de la cuenta</h5>
        <div class="row">
          <div class="col-sm-4">
            <div>
              <label style="color: black;"><b>Nombre Administrador</b></label>
            </div>
            <div>
              <label>{{ usuario?.admin[0].nombre_admin }}</label>
            </div>
          </div>
          <div class="col-sm-4">
            <div>
              <label style="color: black;"><b>CIF/NIF</b></label>
            </div>
            <div>
              <label>{{ usuario?.admin[0].cif_nif_admin }}</label>
            </div>
          </div>
          <div class="col-sm-4">
            <div>
              <label style="color: black;"><b>Correo Administrador</b></label>
            </div>
            <div>
              <label>{{ usuario?.admin[0].email_admin }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div>
              <label style="color: black;"><b>Teléfono</b></label>
            </div>
            <div>
              <label>{{ usuario?.admin[0].tel_admin }}</label>
            </div>
          </div>
          <div class="col-sm-4">
            <div>
              <label style="color: black;"><b>Fecha de creación</b></label>
            </div>
            <div>
              <label>{{ usuario?.admin[0].created_at | date:'short'}}</label>
            </div>
          </div>
          <div class="col-sm-4">
            <div>
              <label style="color: black;"><b>Fecha de actualización</b></label>
            </div>
            <div>
              <label>{{ usuario?.admin[0].updated_at | date:'short' }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5 mb-2">
        <div class="col-sm-3">
          <button type="button" class="btn btn-secondary w-100" (click)="rechazarModal(usuarioRechazado)">
            Rechazar
          </button>
        </div>

        <div class="col-sm-6 mt-3"></div>

        <div class="col-sm-3">
          <button type="button" class="btn btn-primary w-100" (click)="validarUsuario(usuario.email, usuario.status)">
            Aprobar
          </button>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #registroTransportistaEmpresa let-modal>
  <div class="modal-header">
      <h5 style="color: #FF6C2B;" class="modal-title" id="myLargeModalLabel">Detalles del Registro</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-4">
        <div>
          <label style="color: black;"><b>Nombre</b></label>
        </div>
        <div>
          <label>{{ usuario.name }}</label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Estado</b></label>
        </div>
        <div>
          <span [ngClass]="{'activo': usuario.status == 'Activo', 'nuevo': usuario.status == 'Nuevo', 'inactivo': usuario.status == 'Inactivo'}">
            {{ usuario.status }}
          </span>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>CIF/NIF</b></label>
        </div>
        <div>
          <label>{{ usuario.cif_nif }}</label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Teléfono</b></label>
        </div>
        <div>
          <label>{{ usuario.telefono }}</label>
        </div>
      </div>

    </div>

    <div class="row mt-3">
      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Dirección</b></label>
        </div>
        <div>
          <label>{{ usuario.direccion }}</label>
        </div>
      </div>

      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Correo</b></label>
        </div>
        <div>
          <label>{{ usuario.email }}</label>
        </div>
      </div>

    </div>

    <div class="row mt-3">
      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Perfil para aprobación</b></label>
        </div>
        <div>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '1'">Administrador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '2'">Cargador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '3'">Descargador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '4'">Transportista</label>
        </div>
      </div>

      <div class="col-sm-6" *ngIf="usuario.status && usuario?.status != 'Nuevo'">
        <div>
          <label style="color: black;"><b>Perfiles activos</b></label>
        </div>
        <div>
          <label *ngIf="checkPerfil(2)"
                  style="color: white;
                        background-color: #FF6C2B;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Cargador</label>
          <label *ngIf="checkPerfil(4)"
                  style="color: white;
                        background-color: #0F4C81;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Transportista</label>
          <label *ngIf="checkPerfil(3)"
                  style="color: white;
                        background-color: #0f8113;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Descargador</label>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-12">
        <label style="color: black;"><b>Información del Administrador</b></label>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <div>
          <label style="color: black;"><b>Nombre</b></label>
        </div>
        <div>
          <label>Adm. de la cuenta</label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>CIF/NIF</b></label>
        </div>
        <div>
          <label></label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Teléfono</b></label>
        </div>
        <div>
          <label></label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Correo</b></label>
        </div>
        <div>
          <label></label>
        </div>
      </div>
    </div>


    <div class="row mt-5 mb-2">
      <div class="col-sm-3">
        <button type="button" class="btn btn-secondary w-100" (click)="rechazarModal(usuarioRechazado)">
          Rechazar
        </button>
      </div>

      <div class="col-sm-6 mt-3"></div>

      <div class="col-sm-3">
        <button type="button" class="btn btn-primary w-100" (click)="validarUsuario(usuario.email, usuario.status)">
          Aprobar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #registroCargadorDescargador let-modal>
  <div class="modal-header">
    <div class="modal-tags">
      <h5 class="modal-title" id="myLargeModalLabel">Detalles del Registro</h5>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-4">
        <div>
          <label style="color: black;"><b>Nombre</b></label>
        </div>
        <div>
          <label>{{ usuario.name }}</label>
        </div>
      </div>

      <div class="col-sm-1">
        <div>
          <label style="color: black;"><b>Estado</b></label>
        </div>
        <div>
          <span [ngClass]="{'activo': usuario.status == 'Activo', 'nuevo': usuario.status == 'Nuevo', 'inactivo': usuario.status == 'Inactivo'}">
            {{ usuario.status }}
          </span>
        </div>
      </div>

      <div class="col-sm-2">
        <div>
          <label style="color: black;"><b>CIF/NIF</b></label>
        </div>
        <div>
          <label>{{ usuario.cif_nif }}</label>
        </div>
      </div>

      <div class="col-sm-2">
        <div>
          <label style="color: black;"><b>Teléfono</b></label>
        </div>
        <div>
          <label>{{ usuario.telefono }}</label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Teléfono (Opcional)</b></label>
        </div>
        <div>
          <label>
            <span *ngIf="usuario.telefono_opc != null">
              {{ usuario.telefono_opc }}
            </span>
          </label>
        </div>
      </div>


    </div>

    <div class="row mt-3">
      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Dirección</b></label>
        </div>
        <div>
          <label>{{ usuario.direccion }}</label>
        </div>
      </div>

      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Correo</b></label>
        </div>
        <div>
          <label>{{ usuario.email }}</label>
        </div>
      </div>

    </div>

    <div class="row mt-3">
      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Perfil para aprobación</b></label>
        </div>
        <div>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '1'">Administrador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '2'">Cargador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '3'">Descargador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '4'">Transportista</label>
        </div>
      </div>
      <!-- //--------------------- -->
      <div class="col-sm-6" *ngIf="usuario.status && usuario?.status != 'Nuevo'">
        <div>
          <label style="color: black;"><b>Perfiles activos</b></label>
        </div>
        <div>
          <label *ngIf="checkPerfil(2)"
                  style="color: white;
                        background-color: #FF6C2B;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Cargador</label>
          <label *ngIf="checkPerfil(4)"
                  style="color: white;
                        background-color: #0F4C81;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Transportista</label>
          <label *ngIf="checkPerfil(3)"
                  style="color: white;
                        background-color: #0f8113;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Descargador</label>
        </div>
      </div>
    </div>


    <div class="row mt-5 mb-2">
      <div class="col-sm-3">
        <button type="button" class="btn btn-secondary w-100" (click)="rechazarModal(usuarioRechazado)">
          Rechazar
        </button>
      </div>

      <div class="col-sm-6 mt-3"></div>

      <div class="col-sm-3">
        <button type="button" class="btn btn-primary w-100" (click)="validarUsuario(usuario.email, usuario.status)">
          Aprobar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #usuarioRechazado let-modal>
  <div class="modal-header">
      <h5 style="color: #FF6C2B;" class="modal-title" id="myLargeModalLabel">Rol Rechazado</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-4">
        <div>
          <label style="color: black;"><b>Nombre</b></label>
        </div>
        <div>
          <label>{{ usuario.name }}</label>
        </div>
      </div>

      <div class="col-sm-1">
        <div>
          <label style="color: black;"><b>Estado</b></label>
        </div>
        <div>
          <span [ngClass]="{'activo': usuario.status == 'Activo', 'nuevo': usuario.status == 'Nuevo', 'inactivo': usuario.status == 'Inactivo'}">
            {{ usuario.status }}
          </span>
        </div>
      </div>

      <div class="col-sm-2">
        <div>
          <label style="color: black;"><b>CIF/NIF</b></label>
        </div>
        <div>
          <label>{{ usuario.cif_nif }}</label>
        </div>
      </div>

      <div class="col-sm-2">
        <div>
          <label style="color: black;"><b>Teléfono</b></label>
        </div>
        <div>
          <label>{{ usuario.telefono }}</label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Teléfono (Opcional)</b></label>
        </div>
        <div>
          <label>{{ usuario.telefono_opc }}</label>
        </div>
      </div>

    </div>

    <div class="row mt-2">
      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Dirección</b></label>
        </div>
        <div>
          <label>{{ usuario.direccion }}</label>
        </div>
      </div>

      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Correo</b></label>
        </div>
        <div>
          <label>{{ usuario.email }}</label>
        </div>
      </div>

    </div>


    <div class="row mt-3">
      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Perfil para aprobación</b></label>
        </div>
        <div>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '1'">Administrador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '2'">Cargador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '3'">Descargador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '4'">Transportista</label>
        </div>
      </div>

      <div class="col-sm-6" *ngIf="usuario?.status != 'Nuevo'">
        <div>
          <label style="color: black;"><b>Perfiles activos</b></label>
        </div>
        <div>
          <label *ngIf="checkPerfil(2)"
                  style="color: white;
                        background-color: #FF6C2B;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Cargador</label>
          <label *ngIf="checkPerfil(4)"
                  style="color: white;
                        background-color: #0F4C81;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Transportista</label>
          <label *ngIf="checkPerfil(3)"
                  style="color: white;
                        background-color: #0f8113;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Descargador</label>
        </div>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col-sm-12">
        <div>
          <label style="color: black;"><b>Motivo de rechazo</b></label>
        </div>

        <div>
          <select class="form-select" aria-label="Default select example" [(ngModel)]="motivo">
            <option value="0" [disabled]="true">Motivo</option>
            <option value="{{ item.idmotivo }}" *ngFor="let item of motivoArray;let i = index">
              {{ item.descripcion }}
            </option>



            <!--
            <option value="1">Documento ilegible o incorrecto</option>
            <option value="2">Documento faltante</option>
            <option value="3">Documentos próximos a vencer o vencidos</option>
            <option value="4">Información o documentación suministrada es de otra legislación</option>
            <option value="5">Datos de contacto inválidos</option>
            -->


          </select>
        </div>
      </div>
    </div>


    <div class="row mt-2">
      <div class="col-sm-12">
        <div>
          <label style="color: black;"><b>Observaciones</b></label>
          <textarea class="form-control" [(ngModel)]="observaciones" rows="2"></textarea>
        </div>
      </div>
    </div>


    <div class="row mt-4 mb-2">
      <div class="col-sm-3">
        <button type="button" class="btn btn-secondary w-100" (click)="cerrar()">
          Cancelar
        </button>
      </div>

      <div class="col-sm-6 mt-3"></div>

      <div class="col-sm-3">
        <button type="button" class="btn btn-primary w-100" (click)="invalida(usuario.email, usuario.status)">
          Enviar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #usuarioDenegado let-modal>
  <div class="modal-header">
    <div class="modal-tags">
      <h5 class="modal-title" id="myLargeModalLabel">Detalles del Registro</h5>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Close click')"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-4">
        <div>
          <label style="color: black;"><b>Nombre</b></label>
        </div>
        <div>
          <label>{{ usuario.name }}</label>
        </div>
      </div>

      <div class="col-sm-1">
        <div>
          <label style="color: black;"><b>Estado</b></label>
        </div>
        <div>
          <span [ngClass]="{'activo': usuario.status == 'Activo', 'nuevo': usuario.status == 'Nuevo', 'inactivo': usuario.status == 'Inactivo'}">
            {{ usuario.status }}
          </span>
        </div>
      </div>

      <div class="col-sm-2">
        <div>
          <label style="color: black;"><b>CIF/NIF</b></label>
        </div>
        <div>
          <label>{{ usuario.cif_nif }}</label>
        </div>
      </div>

      <div class="col-sm-2">
        <div>
          <label style="color: black;"><b>Teléfono</b></label>
        </div>
        <div>
          <label>{{ usuario.telefono }}</label>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Teléfono (Opcional)</b></label>
        </div>
        <div>
          <label>{{ usuario.telefono_opc }}</label>
        </div>
      </div>

    </div>

    <div class="row mt-2">
      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Dirección</b></label>
        </div>
        <div>
          <label>{{ usuario.direccion }}</label>
        </div>
      </div>

      <div class="col-sm-6">
        <div>
          <label style="color: black;"><b>Correo</b></label>
        </div>
        <div>
          <label>{{ usuario.email }}</label>
        </div>
      </div>

    </div>


    <div class="row mt-2">
      <div class="col-sm-3">
        <div>
          <label style="color: black;"><b>Perfil para aprobación</b></label>
        </div>
        <div>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '1'">Administrador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '2'">Cargador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '3'">Descargador</label>
          <label *ngIf="usuario?.perfiles_pendientes?.id_perfil == '4'">Transportista</label>
        </div>
      </div>

      <div class="col-sm-6" *ngIf="usuario?.status != 'Nuevo'">
        <div>
          <label style="color: black;"><b>Perfiles activos</b></label>
        </div>
        <div>
          <label *ngIf="checkPerfil(2)"
                  style="color: white;
                        background-color: #FF6C2B;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Cargador</label>
          <label *ngIf="checkPerfil(4)"
                  style="color: white;
                        background-color: #0F4C81;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Transportista</label>
          <label *ngIf="checkPerfil(3)"
                  style="color: white;
                        background-color: #0f8113;
                        padding: 4px;
                        margin: 3px;
                        border-radius: 4px;">Descargador</label>
        </div>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col-sm-12">
        <div>
          <label style="color: black;"><b>Historial</b></label>
          <div class="card">
            <div class="card-body" style="border: 1px solid gray; border-radius: 4px;">

              <div class="contenedor">
                <div>
                  <label style="padding: 6px; border-radius: 4px; color: black; background-color: #F3F3F3;" >
                    <b>Denegado por:</b> {{ motivoRechazo }}
                  </label>
                </div>

                <div>
                  <label>{{ historial.fecha }}</label>
                </div>
              </div>


              <div>
                <p>{{ historial.observacion }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    <div class="row mt-2 mb-2">
      <div class="col-sm-3">
        <button type="button" class="btn btn-secondary w-100">
          Rechazar
        </button>
      </div>

      <div class="col-sm-6 mt-3"></div>

      <div class="col-sm-3">
        <button type="button" class="btn btn-primary w-100" (click)="cerrar()">
          Aceptar
        </button>
      </div>
    </div>
    -->

  </div>
</ng-template>

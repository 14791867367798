<div class="container-fluid">
  <div class="my-2">
    <label style="color: #000000; font-size: 18px;">Gestion global</label>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Validación de perfiles</a>
          <ng-template ngbNavContent>
            <app-valida-perfiles></app-valida-perfiles>
          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <a ngbNavLink>Cargadores</a>
          <ng-template ngbNavContent>
            <app-lista-cargadores></app-lista-cargadores>
          </ng-template>
        </li>

        <li [ngbNavItem]="3">
          <a ngbNavLink>Transportistas</a>
          <ng-template ngbNavContent>
            <app-lista-transportista></app-lista-transportista>
          </ng-template>
        </li>

        <li [ngbNavItem]="4">
          <a ngbNavLink>Descargadores</a>
          <ng-template ngbNavContent>
            <app-lista-descargadores></app-lista-descargadores>
          </ng-template>
        </li>

        <li [ngbNavItem]="5">
          <a ngbNavLink>Propuestas con pago anticipado</a>
          <ng-template ngbNavContent>
            <app-lista-pago-anticipado></app-lista-pago-anticipado>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>


<div>
  <div class="card">
    <div class="card-body">


      <div>
        <form [formGroup]="searchForm" class="d-xl-block">
          <div class="filters">
            <div class="buscar">
              <div class="app-search position-relative">
                <input type="search" class="form-control" placeholder="Código anuncio" formControlName="codigo_anuncio">
                <span class="bx bx-search-alt"></span>
              </div>
            </div>
            <input type="search" class="origen form-control" placeholder="Origen" formControlName="origen">
            <input type="search" class="destino form-control" placeholder="Destino" formControlName="destino">
            <input type="text" onkeydown="return false"
                    class="desde form-control" placeholder="Desde"
                    onfocus="(this.type='date')"
                    onblur="(this.type= this.value ? 'date' : 'text')"
                    formControlName="desde">
            <div class="error errDesde" *ngIf="f.desde.errors?.required">Es obligatorio el rango completo de fechas</div>
            <div class="error errDesde" *ngIf="f.desde.errors?.rango">La fecha de inicio no puede ser superior a la fecha final</div>
            <input type="text" onkeydown="return false"
                    class="hasta form-control" placeholder="Hasta"
                    onfocus="(this.type='date')"
                    onblur="(this.type= this.value ? 'date' : 'text')"
                    formControlName="hasta">
            <button type="button" class="filtrar btn btn-primary w-100" [disabled]="!searchForm.valid" (click)="search()">
              <img src="assets/images/transfriendly/filtro.png" style="padding-right: 15px;">
              Filtrar
            </button>
          </div>
        </form>
      </div>


      <div class="row mt-2">
        <div class="col-sm-12">
          <table class="table">
            <tbody>

              <tr *ngFor="let item of pagina">
                <td>
                  <i>Código: {{item.codigo}}</i>
                  <label  [ngStyle]="{'display': 'flex', 'flex-direction': 'column', 'font-size': '14px', 'color': item.incidencia === 1 ? 'red' : 'initial'}"><b>Viaje {{ item.ciudad_origen }} - {{ item.ciudad_destino }}</b></label>
                  <div style="margin-top: -7px;">
                    <span style="font-size: 12px;">{{ item.monto * 1.1 | formatNumberPipe }} €</span>
                    <!-- <span style="padding-left: 3px; padding-right: 2px;"><img src="assets/images/transfriendly/point.png" alt=""></span> -->
                    <!-- <span style="font-size: 12px;"> Cabina entera</span> -->
                  </div>
                  <span style="display: flex; flex-direction: column; font-size: 12px; color: #C0BFBF;">Publicado hace {{ getTime(item) }}</span>
                  <!-- <span *ngIf="item.dias_transcurridos > 30" style="display: flex; flex-direction: column; font-size: 12px; color: #C0BFBF;">Publicado hace {{ item.meses_transcurridos }} mes</span>
                  <span *ngIf="item.horas_transcurridos > 24 && item.dias_transcurridos < 30" style="display: flex; flex-direction: column; font-size: 12px; color: #C0BFBF;">Publicado hace {{ item.dias_transcurridos }} dias</span>
                  <span *ngIf="item.horas_transcurridos < 24 && item.horas_transcurridos > 0" style="display: flex; flex-direction: column; font-size: 12px; color: #C0BFBF;">Publicado hace {{ item.horas_transcurridos }} Horas</span>
                  <span *ngIf="item.minutos_transcurridos > 0 && item.minutos_transcurridos < 60 && item.horas_transcurridos <= 0" style="display: flex; flex-direction: column; font-size: 12px; color: #C0BFBF;">Publicado hace {{ item.minutos_transcurridos }} minutos</span> -->
                </td>
                <td>
                  <div *ngIf="item.estatus === 'FINALIZADO'" style="justify-content: center;">
                    <button type="button" class="filtrar btn btn-primary w-100"  (click)="viewContact(item.id)" >
                      Consultar albaranes
                    </button>
                  </div>
                </td>
                <td class="text-center">

                  <label style="display: flex; flex-direction: column; font-size: 22px;">{{ item.cantidad_puja }}</label>
                  <label style="display: flex; flex-direction: column; font-size: 12px; margin-top: -10px;">Pujas</label>
                </td>


                <!--
                <td class="text-center">
                  <label style="display: flex; flex-direction: column; font-size: 22px;">2</label>
                  <label style="display: flex; flex-direction: column; font-size: 12px; margin-top: -10px;">Mensajes</label>
                </td>
                -->

                <td style="text-align:end">
                  <div class="btn-group" ngbDropdown>
                    <button type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                      aria-expanded="false">
                      <img src="assets/images/transfriendly/puntos.png" alt="">
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <a class="dropdown-item p" [routerLink]="['propuesta', item.id]" routerLinkActive="active">
                        <i class="mdi mdi-eye me-2" style="margin-right: 20px; color: blue;"></i>
                         Ver detalle
                      </a>

                      <a *ngIf="item.estatus == 'CONTRATADO'" class="dropdown-item p" (click)="eliminarContratacion(item.id)">
                        <i class="mdi mdi-trash-can-outline me-2" style="margin-right: 5px; color: red;"></i>
                        Eliminar
                      </a>

                      <!--
                      <a class="dropdown-item p">
                        <i class="mdi mdi-pencil me-2" style="margin-right: 20px; color: green;"></i>
                        Editar
                      </a>

                      <a class="dropdown-item p">
                        <i class="mdi mdi-trash-can-outline me-2" style="margin-right: 5px; color: red;"></i>
                        Eliminar
                      </a>
                      -->

                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <div class="row justify-content-between align-items-center">
          <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="collectionSize"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            (pageChange)="refreshPagina()">
            </ngb-pagination>

            <select class="form-select"
                    style="width: auto"
                    [(ngModel)]="pageSize"
                    (ngModelChange)="refreshPagina()">
              <option [ngValue]="5">5 items por página</option>
              <option [ngValue]="10">10 items por página</option>
              <option [ngValue]="25">25 items por página</option>
              <option [ngValue]="50">50 items por página</option>
            </select>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>



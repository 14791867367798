<!-- <div class="modal-content">
    <div>
        
        <button  type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="close()"></button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <p>Orden de llegada</p>
                <div *ngFor="let archivo of infoArchivo" style="margin-bottom: 5px;">
          
                    
                    <ng-container *ngIf="archivo.orden_llegada !== ''">
                        
                      <a href="{{ archivo.orden_llegada }}">{{ archivo.orden_llegada }}</a>
                    </ng-container>
                  </div>
                  <div *ngFor="let infogen of infoGeneral">
                    <p *ngIf="infogen.incidencia" style="color: red;"> Incidencia: {{infogen.incidencia}}</p>
                    <p *ngIf="infogen.incidencia === null">Si deseas registrar alguna incidencia en la descarga de este porte, contacta al Asistente Virtual al número 634 13 29 90. 

                        Para ello, deberás pulsar la opción 5 del menú “Reporte de incidencias”, indicar tu perfil de Cargador y confirmar el “Incidente en la Descarga</p>
                  </div>
            </div>
        </div>
    </div>
</div> -->




<div class="dialog-top">
  <!-- <mat-spinner [diameter]="20" style="display: inline-block;
                 vertical-align: bottom;"></mat-spinner> -->
  <span class="mdi mdi-close" (click)="close()"></span>
</div>
<div style="max-height: 70vh; overflow-y:auto; overflow-x:hidden;">
  <div style="width: 400px;padding: 0 30px">
    <dl>
      <!-- <dt>Orden de inicio</dt>
      <dd>
        <ul>
          <li *ngFor="let file of infoArchivo; let idx = index">
            <a *ngIf="file.orden_inicio.length > 0" href="{{file.orden_inicio}}" target="_blank">Orden inicio
              {{idx+1}}</a>
          </li>
        </ul>
      </dd> -->
      <dt>Orden de llegada</dt>
      <dd>
        <ul>
          <li *ngFor="let file of infoArchivo ; let idx = index">
            <a *ngIf="file.orden_llegada.length > 0" href="{{file.orden_llegada}}" target="_blank">Orden llegada
              {{idx}}</a>
          </li>
        </ul>
      </dd>
    </dl>
  </div>
</div>

<div *ngFor="let infogen of infoGeneral"  style="max-height: 70vh; overflow-y:auto; overflow-x:hidden;">
  <div *ngIf="infogen.incidencia === null" style="width: 400px;padding: 0 30px">
      <div class="row" style="align-items: center;">

        <div class="col-2 text-center">

          <img src="assets/images/transfriendly/advertencia.png" style="width: 30px; height: 30px; margin-bottom: 30px"/>

        </div>
        <div class="col-10">

          <p class="mt-4">
            Si deseas registrar alguna incidencia en la descarga de este porte, contacta al Asistente Virtual al número 634 13
            29 90. 
            </p>
            <p class="mb-5">
              Para ello, deberás pulsar la opción 5 del menú “Reporte de incidencias”, indicar tu perfil de Cargador y confirmar
              el “Incidente en la Descarga
            </p>

        </div>
    </div>
  </div>
  <div *ngIf="infogen.incidencia" style="padding: 1em 30px; color:red">
    <p>
      Incidencia: {{infogen.incidencia}}
    </p>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../pages/dashboards/services/services.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { merge } from 'rxjs/internal/observable/merge';
import { SharingService } from '../../core/services/sharing.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cargador-publicaciones',
  templateUrl: './cargador-publicaciones.component.html',
  styleUrls: ['./cargador-publicaciones.component.scss']
})


export class CargadorPublicacionesComponent implements OnInit {

  public searchForm: FormGroup;
  public anuncios:any = [];
  public registerUser = { 'id':0, 'name':'', 'perfil':0 }
  public page = 1;
	public pageSize = 10;
	public collectionSize = 0;
  public pagina:any = [];

  constructor(private servicesService:ServicesService, private router:Router, private sharingService:SharingService){}

  ngOnInit(): void {
    this.registerUser = JSON.parse(localStorage.getItem('data'));
    this.getAnuncioCargador();
    this.initForm();
    this.sharingService.setIdxObservable(0);
  }

  getTime(itemOffer:any){
    let tiempo:string;

    if(itemOffer.minutos_transcurridos >= 0 && itemOffer.minutos_transcurridos < 60)
    { tiempo = itemOffer.minutos_transcurridos + ' minutos' }

    if(itemOffer.horas_transcurridos >= 1)
    { tiempo = itemOffer.horas_transcurridos + ' horas' }

    if(itemOffer.dias_transcurridos >= 1)
    { tiempo = itemOffer.dias_transcurridos + ' dias' }

    if(itemOffer.meses_transcurridos >= 1)
    { tiempo = itemOffer.meses_transcurridos + ' meses' }

    return tiempo;
  }

  initForm(){
    this.searchForm = new FormGroup({
      codigo_anuncio: new FormControl(''),
      origen: new FormControl(''),
      destino: new FormControl(''),
      desde: new FormControl(''),
      hasta: new FormControl(''),
    });

    merge(this.searchForm.get('desde').valueChanges,this.searchForm.get('hasta').valueChanges).subscribe(() =>{
      this.setValidatorsDate(this.searchForm.get('desde').value,this.searchForm.get('hasta').value);
    })
  }

  getAnuncioCargador(){
    const data = { "id_usuario": this.registerUser.id }
    this.servicesService.consultaanunciocargador(data).subscribe((resp:any) =>{
      if(resp.code == 200)
      { this.anuncios = resp.msj.response.info_anuncio;
        this.collectionSize = this.anuncios.length;
        this.refreshPagina();
      }
    },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }))
  }

  refreshPagina() {
    this.pagina = this.anuncios.map((data, i) => ({ id: i + 1, ...data })).slice(
			(this.page - 1) * this.pageSize,
			(this.page - 1) * this.pageSize + this.pageSize,
		);
	}

  get f() { return this.searchForm.controls; }

  setValidatorsDate(desde:string, hasta:string){
    if((!desde && hasta) || (desde && !hasta)){
      this.searchForm.get('desde').setErrors({'required': true});
    }else if(new Date(desde) > new Date(hasta)){
      this.searchForm.get('desde').setErrors({'rango': true});
    }else{
      this.searchForm.get('desde').setErrors(null)
    }
  }

  search(){
    const data = { "id_usuario": this.registerUser.id,
                   "codigo_anuncio": this.searchForm.get('codigo_anuncio').value,
                   "origen": this.searchForm.get('origen').value,
                   "destino": this.searchForm.get('destino').value,
                   "desde": this.searchForm.get('desde').value,
                   "hasta": this.searchForm.get('hasta').value,
                 }
    this.servicesService.consultaanunciocargador(data).subscribe((resp:any) =>{
      if(resp.code == 200)
      { this.anuncios = resp.msj.response.info_anuncio;
        this.collectionSize = this.anuncios.length;
        this.refreshPagina();
      }
      },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }))

  }

  editarAnuncio(id:number){
    this.router.navigate(['/pagina/principal/propuesta/'+ id]);
  }

  public duplicarAnuncio(id:number){
    Swal.fire({
      title: 'Has pulsado la opción de duplicar anuncio',
      text: "Si confirmas, se va a publicar un nuevo anuncio con los mismos datos del porte seleccionado",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:'Cancelar',
      confirmButtonText: 'Duplicar'
    }).then((result) => {
      if(result.isConfirmed){

        this.servicesService.duplicarAnuncio(id).subscribe({
          next: (resp) => {
            if(resp.code == 200){
              this.getAnuncioCargador();
              Swal.fire('Publicación!','La publicación ha sido duplicada correctamente','success');
            }
          },
          error: (error) => {},
          complete: () => {},
        });
      }
    })
  }


  eliminarAnuncio(id:number){
    Swal.fire({
      title: 'Estas seguro de eliminar el anuncio?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.servicesService.deleteanuncio(id).subscribe(resp =>{
          if(resp.code == 200){
            this.getAnuncioCargador();
            Swal.fire('Eliminado!','Anuncio ha sido eliminado correctamente','success');
          }
        },err => Swal.fire({ icon: 'error', text: `HTTP Error ${err}` }));

      }
    })
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServicesService } from 'src/app/pages/dashboards/services/services.service';
import { SharingService } from '../../core/services/sharing.service';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface ChatUser {
  avatar?: string;
  nombre: string;
  mensaje: string;
  id_sala: number;
  // color: string;
}

export interface ChatMessage {
  align?: string;
  nombre_usuario_envia?: string;
  mensaje: string;
  tiempo: string;
}

@Component({
  selector: 'app-mensajes',
  templateUrl: './mensajes.component.html',
  styleUrls: ['./mensajes.component.scss']
})


export class MensajesComponent implements OnInit, AfterViewInit {

  @ViewChild('scrollEle') scrollEle;
  @ViewChild('scrollRef') scrollRef;

  @Input() vMessage: any;
  @Output() clearMessage = new EventEmitter<any>();

  username:string;
  name_header:string;
  echo:any;         // Manejador Websockets
  userChanell:number;
  loadChanell:boolean = false;
  disabledChat:boolean = false;
  listChatData: ChatUser[];
  chatData: ChatUser[];
  chatMessagesData: ChatMessage[]=[];
  formData: UntypedFormGroup;
  paramsChat:any;

  // Form submit
  chatSubmit: boolean;
  usermessage: string;
  emoji = '';
  listsUser=[{item:'Pujas', value:'pujas'},
             {item:'Viajes en proceso', value:'viajes'},
             {item:'Conversaciones Cerradas', value:'cerradas'}];
  selectListUser:string;

  private data$: Observable<number>;

  constructor(public formBuilder: UntypedFormBuilder,
              private servicesService: ServicesService,
              private sharingService:SharingService)
              {
                this.data$ = this.sharingService.idxObservable$;
              }


  ngOnInit(){
    this.data$.subscribe({
      next: (resp) => {

        this.vMessage = JSON.parse(localStorage.getItem('goMsg'));

        let idx:number = 0;
        if (this.vMessage && this.vMessage['listsUser'])
        {
          idx = resp;
          //this.listsUser.findIndex(l=>l.value == this.vMessage['listsUser']);
        }

        this.selectListUser = this.listsUser[idx]['value'];

        this.servicesService.getChats().subscribe((resp: any) => {
          if (resp.code == '200') {
            delete(resp.code);
            this.listChatData = resp;
            this.loadPusher();
            this._fetchData();

            if(this.vMessage) this.searchUser();
          }
          this.onListScroll();
        })

        this.formData = this.formBuilder.group({
          message: [[{value:'',disabled:this.disabledChat}], [Validators.required]],
        });

        if(this.vMessage) localStorage.removeItem('goMsg');

      },
      error: (error) => {},
      complete: () => {},
    })


    /*
    this.vMessage = JSON.parse(localStorage.getItem('goMsg'));

    let idx:number = 0;
    if (this.vMessage && this.vMessage['listsUser'])
    {
      idx = 0
      //this.listsUser.findIndex(l=>l.value == this.vMessage['listsUser']);
    }

    this.selectListUser = this.listsUser[idx]['value'];

    this.servicesService.getChats().subscribe((resp: any) => {
      if (resp.code == '200') {
        delete(resp.code);
        this.listChatData = resp;
        this.loadPusher();
        this._fetchData();

        if(this.vMessage) this.searchUser();
      }
      this.onListScroll();
    })

    this.formData = this.formBuilder.group({
      message: [[{value:'',disabled:this.disabledChat}], [Validators.required]],
    });

    if(this.vMessage) localStorage.removeItem('goMsg');
    */
  }


  searchUser(){
    const user = this.chatData.find(c=>c.id_sala == this.vMessage['id_sala']);
    console.log('user',user);
    this.chatUsername(user);
    this.clearMessage.emit()
  }

  onChange(value: any) {
    this.selectListUser = value.target.value;
    this.disabledChat = (this.selectListUser == 'cerradas')?true:false;
    this._fetchData();

  }

  ngAfterViewInit() {
    this.scrollEle.SimpleBar.getScrollElement().scrollTop = 100;
    this.scrollRef.SimpleBar.getScrollElement().scrollTop = 200;
  }

  chatUsername(user:any) {
    this.loadMessages(user);
    this.username = user.nombre;

    if(this.userChanell == user.id_sala || this.loadChanell) return;

    this.loadChanell=true;
    this.echo.channel(`Chat.Sala.${user.id_sala}`)
    .listen('.chat',(resp) => {
      this.userChanell = user.id_sala;
      let msg = resp.sala.mensaje[0];
      if(msg.nombre_usuario_envia == this.name_header){
        msg={...msg, align: "right"};
      }
      this.chatMessagesData.push(msg)
      this.onListScroll();
      this.loadChanell=false;
    })

  }

  /**
 * Returns form
 */
  get form() {
    return this.formData.controls;
  }

  // Delete Message
  deleteMessage(event:any){
    event.target.closest('li').remove();
  }

  // Copy Message
  copyMessage(event:any){
    navigator.clipboard.writeText(event.target.closest('li').querySelector('p').innerHTML);
  }

  // Delete All Message
  deleteAllMessage(event:any){
    var allMsgDelete:any = document.querySelector('.chat-conversation')?.querySelectorAll('li');
    allMsgDelete.forEach((item:any)=>{
      item.remove();
    })
  }

  private _fetchData() {
    this.chatData = this.listChatData[this.selectListUser];
  }

  private loadPusher(){
    const options = {
      broadcaster: 'pusher',
      key: environment.keyPusher,
      cluster: 'us2',
    };

    this.echo = new Echo({
      ...options,
      wsHost: window.location.hostname,
      forceTLS: false,
      wsPort: 6001,
      client: new Pusher(options.key, options),
      disableStats: true,
      enabledTransports: ['ws'],
    });
  }

  private loadMessages(infoChat:any){
    this.chatMessagesData = [];
    this.paramsChat = {id_sala:infoChat.id_sala,
                  id_user_from:infoChat.id_user_receptor};
    this.paramsChat;

    this.servicesService.loadChat(this.paramsChat).subscribe((resp: any) => {
      if (resp.code == '200') {
        this.chatMessagesData = (typeof resp.mensajes == "object")?resp.mensajes:[];
        this.name_header = resp.name_header;
        this.onListScroll();
        this.servicesService.readedChat(infoChat.id_sala).subscribe((resp: any) => {})
      }
    })

  }

  onListScroll() {
    if (this.scrollRef !== undefined) {
      setTimeout(() => {
        this.scrollRef.SimpleBar.getScrollElement().scrollTop =
          this.scrollRef.SimpleBar.getScrollElement().scrollHeight + 1500;
      }, 500);
    }
  }


  messageSave(){
    let mensaje = this.formData.get('message').value;
    let regExp = /(\d\s)|(\d{5})/.test(mensaje);

    if(regExp)
    { mensaje = '';

      this.formData = this.formBuilder.group({
        message: ''
      });

    }


    const currentDate = new Date();
    if (this.formData.valid && mensaje){
      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null
      });

      const{id_user_from, ...data} = this.paramsChat;

      this.servicesService.sendMessage({...data,mensaje}).subscribe()
    }

    this.chatSubmit = true;
  }

  // Emoji Picker
  showEmojiPicker = false;
  sets:any = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set:any = 'twitter';
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event:any) {
    const { emoji } = this;
    const text = `${emoji}${event.emoji.native}`;
    this.emoji = text;
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }

  onBlur() {
  }

}

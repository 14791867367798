<ng-container [formGroup]="informationGeneral">
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Descripción <span style="color: red;">*</span></label>
        <textarea class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  formControlName="descripcion"
                  [ngClass]="{'is-invalid': invalidate('descripcion').value }"></textarea>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-6">
      <div class="form-group">
        <div>
          <label style="color: #000000;">Fecha límite de puja <span style="color: red;">*</span></label>
        </div>

        <div>
          <div>
            <input type="date"
                   class="form-control"
                   name="fechaLimitePuja"
                   formControlName="fechaLimitePuja"
                   [ngStyle]="validFecha && {'border-color':'red'}"
                   (change)="validaFecha()">
          </div>
          <span style="color: red;">*</span><span style="font-size: 10px;">El plazo para realizar pujas se cierra a media noche</span>
        </div>
      </div>
    </div>



    <div class="col-sm-6">
      <div class="form-group">
        <div>
          <label style="color: #000000;">Fecha de salida <span style="color: red;">*</span></label>
        </div>

        <div>
          <input type="date"
                 class="form-control"
                 name="fechaSalida"
                 formControlName="fechaSalida"
                 [ngStyle]="validFecha && {'border-color':'red'}"
                 [ngClass]="{'is-invalid': invalidate('fechaSalida').value }"
                 (change)="validaFecha()">
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <!-- <div class="col-sm-6">
      <div *ngIf="false" class="form-group" >
        <label style="color: #000000;">Tipo de contrato <span style="color: red;">*</span></label>
        <select class="form-select"
                aria-label="Default select example"
                style="margin-top: -5px;"
                formControlName="tipoContrato"
                [ngClass]="{'is-invalid': invalidateSelect('tipoContrato').value }">
          <option value="0" [disabled]="true">Tipo</option>
          <option value="{{ item.idtipocontrato }}" *ngFor="let item of tipoContrato;let i = index">
            {{ item.descripcion }}
          </option>
        </select>
      </div>
    </div> -->

    <div class="col-sm-6">
      <div class="form-group">
        <label style="color: #000000;">Tipo de Vehiculo <span style="color: red;">*</span></label>
            <input type="text"
                   placeholder="Tipo de Vehiculo"
                   class="form-control"
                   style="font-size: 12px; margin-top: -5px;"
                   formControlName="tipoVehiculo"
                   readonly>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        <label style="color: #000000;">Presupuesto <span style="color: red;">*</span></label>
        <input
               placeholder="Presupuesto"
               class="form-control"
               style="font-size: 12px; margin-top: -5px;"
               formControlName="presupuesto"
               currencyMask
               [options]="{ prefix:'', thousands:'.', precision:2, decimal:',', allowNegative:false }"
               [ngClass]="{'is-invalid': invalidate('presupuesto').value }"
               oninput="this.value = this.value.replace(/[^0-9.,]/g, '');">
      </div>
    </div>
  </div>

  <div class="row mt-3">


    <div class="col-sm-6">
      <div class="form-group">
        <label style="color: #000000;">Forma de pago <span style="color: red;">*</span></label>
        <select class="form-select"
                aria-label="Default select example"
                style="margin-top: -5px;"
                formControlName="formaPago"
                [ngClass]="{'is-invalid': invalidateSelect('formaPago').value }">
          <option value="0" [disabled]="true">Forma</option>
          <option value="{{ item.idformapago }}" *ngFor="let item of formaPago;let i = index">
            {{ item.descripcion }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        <label style="color: #000000;">Tiempos de pago <span style="color: red;">*</span></label>
        <select class="form-select"
                aria-label="Default select example"
                style="margin-top: -5px;"
                formControlName="tiempoPago"
                [ngClass]="{'is-invalid': invalidateSelect('tiempoPago').value }">
          <option value="0" [disabled]="true">Tiempos</option>
          <option value="{{ item.id }}" *ngFor="let item of tiempoPago;let i = index">
            {{ item.descripcion }}
          </option>
        </select>
        <span style="color: red;">*</span><span style="font-size: 10px;">Para habilitar otros plazos de pago, ponerse en contacto con administracion@transfriendly.es</span>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-sm-6">
      <div>
        <label style="color: #000000;"><span> </span></label>
      </div>

      <div class="form-check b">
        <input class="form-check-input"
               type="checkbox"
               formControlName="mercanciaPeligrosa">
        <label class="form-check-label"
               style="color: #000000; font-size: 10px;">
          El envío contiene mercancias peligrosas
        </label>
      </div>
    </div>

    <div class="col-sm-6">
      <div>
        <label style="color: #000000;">El punto de carga cuenta con...</label>
      </div>

      <div class="form-check a">
        <input class="form-check-input"
               type="checkbox"
               formControlName="muelle">
        <label class="form-check-label" style="color: #000000; font-size: 10px;">
          Muelle
        </label>
      </div>

      <div class="form-check a">
        <input class="form-check-input"
               type="checkbox"
               formControlName="transpaleta">
        <label class="form-check-label" style="color: #000000; font-size: 10px;">
          Transpaleta
        </label>
      </div>

      <div class="form-check a">
        <input class="form-check-input"
               type="checkbox"
               formControlName="carretilla">
        <label class="form-check-label" style="color: #000000; font-size: 10px;">
          Carretilla elevadora
        </label>
      </div>
    </div>
  </div>
</ng-container>


<ng-container [formGroup]="trayectoAdicional">
  <div class="form-group row">
    <div formArrayName="campos">
      <div *ngFor="let item of trayectoAdicional.get('campos')?.controls; let i = index" [formGroupName]="i">
        <div class="card">
          <div class="card-body">

            <div *ngIf="i > 0" style="float: right; cursor: pointer;" (click)="cerrar(i)">
              <i class="fal fa-times close-icon"></i>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 text-center">
                <label style="font-size: 20px; color: #0F4C81;"><b>Trayecto Adicional {{ i + 1 }}</b></label>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-6">
                <label style="color: #000000;"><b>Origen</b></label>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-2">
                <label style="color: #000000;">Código Postal <span style="color: red;">*</span></label>
                <input type="number"
                      placeholder="Código Postal"
                      class="form-control"
                      formControlName="origen_cod_postal"
                      style="font-size: 12px; margin-top: -5px;"
                      autocomplete="off"
                      [readonly]="true"
                      [ngClass]="{'is-invalid': invalidate('origen_cod_postal',i).value }">
              </div>

              <div class="col-sm-3">
                <label style="color: #000000;">Provincia <span style="color: red;">*</span></label>
                <input type="text"
                      placeholder="Provincia"
                      class="form-control"
                      formControlName="origen_provincia"
                      style="font-size: 12px; margin-top: -5px;"
                      autocomplete="off"
                      [readonly]="true"
                      [ngClass]="{'is-invalid': invalidate('origen_provincia',i).value }">
              </div>

              <div class="col-sm-3">
                <label style="color: #000000;">Ciudad <span style="color: red;">*</span></label>
                <input type="text"
                      placeholder="Ciudad"
                      class="form-control"
                      formControlName="origen_ciudad"
                      style="font-size: 12px; margin-top: -5px;"
                      autocomplete="off"
                      [readonly]="true"
                      [ngClass]="{'is-invalid': invalidate('origen_ciudad',i).value }">
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Calle <span style="color: red;">*</span></label>
                <input type="text"
                      placeholder="Calle"
                      class="form-control"
                      formControlName="origen_calle"
                      style="font-size: 12px; margin-top: -5px;"
                      autocomplete="off"
                      [readonly]="true"
                      [ngClass]="{'is-invalid': invalidate('origen_calle',i).value }">
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Número</label>
                <input type="text"
                       placeholder="Número"
                       class="form-control"
                       formControlName="origen_numero"
                       style="font-size: 12px; margin-top: -5px;"
                       autocomplete="off"
                       [readonly]="true"
                       >
              </div>
            </div>


            <div class="row mt-4">
              <div class="col-sm-6">
                <label style="color: #000000;"><b>Destino</b></label>
              </div>
            </div>


            <div class="row">
              <div class="col-sm-6">
                <label style="color: #000000;">Código Postal <span style="color: red;">*</span></label>
                <input type="text"
                      placeholder="Código Postal"
                      class="form-control"
                      formControlName="destino_cod_postal"
                      autocomplete="off"
                      style="font-size: 12px; margin-top: -5px;"
                      (keyup)="zonaPostal($event,i)"
                      [ngClass]="{'is-invalid': invalidate('destino_cod_postal',i).value }">
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label style="color: hsl(0, 0%, 0%);">¿Quien recibe en el destino? <span style="color: red;">*</span></label>
                  <select class="form-select"
                          aria-label="Default select example"
                          style="margin-top: -5px;"
                          formControlName="indexDescargador"
                          #datos
                          (change)='getQuienrecibe(datos.value,i)'
                          [ngClass]="{'is-invalid': invalidateQuienRecibe('indexDescargador',i).value }">
                    <option value="-1" [disabled]="true">¿Quien recibe?</option>
                    <option value="9998">Descargador Desconocido</option>
                    <option value="9999">Descargador no Registrado</option>
                    <option [value]="i" *ngFor="let item of descargadores;let i = index">
                      {{ item.name }} ({{ item.direccion_descarga }})
                    </option>
                  </select>
                </div>
              </div>
            </div>


            <div class="row mt-4" *ngIf="verquien(i)">
              <label style="color: #000000; font-size: 14px;"><b>Datos del Descargador</b></label>
            </div>

            <div class="row mt" *ngIf="verquien(i)">
              <div class="col-sm-6">
                <div class="form-group">
                  <label style="color: #000000;">Nombre <span style="color: red;">*</span></label>
                  <input type="text"
                         placeholder="Nombre"
                         class="form-control"
                         formControlName="nombre"
                         autocomplete="off"
                         style="font-size: 12px; margin-top: -5px;"
                         [ngClass]="{'is-invalid': invalidate('nombre',i).value }">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label style="color: #000000;">NIF/CIF</label>
                  <input type="text"
                         placeholder="NIF/CIF"
                         class="form-control"
                         formControlName="dni"
                         autocomplete="off"
                         style="font-size: 12px; margin-top: -5px;">
                </div>
              </div>
            </div>

            <div class="row mt-2" *ngIf="verquien(i)">
              <div class="col-sm-6">
                <div class="form-group">
                  <label style="color: #000000;">Contacto de WhatsApp <span style="color: red;">*</span></label>
                  <input type="number"
                         placeholder="Teléfono"
                         class="form-control"
                         formControlName="telefono"
                         style="font-size: 12px; margin-top: -5px;"
                         autocomplete="off"
                         [ngClass]="{'is-invalid': invalidate('telefono',i).value }">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label style="color: #000000;">Correo</label>
                  <input type="text"
                         placeholder="Correo"
                         class="form-control"
                         autocomplete="off"
                         formControlName="correo"
                         style="font-size: 12px; margin-top: -5px;">
                </div>
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-sm-3">
                <label style="color: #000000;">Provincia <span style="color: red;">*</span></label>
                <input type="text"
                      placeholder="Provincia"
                      class="form-control"
                      formControlName="destino_provincia"
                      style="font-size: 12px; margin-top: -5px;"
                      autocomplete="off"
                      [ngClass]="{'is-invalid': invalidate('destino_provincia',i).value }">
              </div>

              <div class="col-sm-3">
                <label style="color: #000000;">Ciudad <span style="color: red;">*</span></label>
                <input type="text"
                      placeholder="Ciudad"
                      class="form-control"
                      formControlName="destino_ciudad"
                      style="font-size: 12px; margin-top: -5px;"
                      autocomplete="off"
                      [ngClass]="{'is-invalid': invalidate('destino_ciudad',i).value }">
              </div>

              <div class="col-sm-3">
                <label style="color: #000000;">Calle <span style="color: red;">*</span></label>
                <input type="text"
                      placeholder="Calle"
                      class="form-control"
                      formControlName="destino_calle"
                      style="font-size: 12px; margin-top: -5px;"
                      autocomplete="off"
                      [ngClass]="{'is-invalid': invalidate('destino_calle',i).value }">
              </div>

              <div class="col-sm-3">
                <label style="color: #000000;">Número</label>
                <input type="text"
                       placeholder="Número"
                       class="form-control"
                       formControlName="destino_numero"
                       style="font-size: 12px; margin-top: -5px;"
                       autocomplete="off"
                       >
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-3">
                <div class="form-group">
                  <label style="color: #000000;">Fecha máxima de entrega</label>
                  <input type="date"
                         class="form-control"
                         formControlName="fecha_maxima"
                         autocomplete="off"
                         style="font-size: 12px; margin-top: -5px;">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label style="color: #000000;">Cantidad de artículos a entregar <span style="color: red;">*</span></label>
                  <input type="number"
                        placeholder="0"
                        class="form-control"
                        formControlName="cantidad_articulos"
                        style="font-size: 12px; margin-top: -5px;"
                        autocomplete="off"
                        [ngClass]="{'is-invalid': invalidate('cantidad_articulos', i).value }">
                </div>
              </div>
              <div class="col-sm-4">
                <label style="color: black;">Orden de entrega</label>
                <button class="contenedor-btn-file" (click)="fileID.click()">
                  <img src="../../../assets/images/transfriendly/upload.png">
                    Agregar documento
                  <label></label>
                  <input type="file"
                         id="fileID"
                         #fileID
                         class="form-control"
                         (change)="onFileChange($event,i)"
                         style="font-size: 12px; margin-top: -5px;">
                </button>
              </div>

              <div class="col-sm-2">
                <label style="color: #000000;">Nombre Archivo</label>
                <input type="text"
                        placeholder="Nombre archivo"
                        class="form-control"
                        formControlName="orden_entrega_name"
                        autocomplete="off"
                        style="font-size: 12px; margin-top: -5px;">
              </div>
            </div>


            <div class="row mt-2">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1" style="color: black;">Descripción</label>
                  <textarea class="form-control"
                            id="exampleFormControlTextarea1"
                            autocomplete="off"
                            rows="2" formControlName="descripcion"></textarea>
                </div>
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-sm-6">
                <div>
                  <label style="color: #000000;">El punto de descarga cuenta con...</label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                         type="checkbox" value=""
                         id="flexCheckDefault"
                         autocomplete="off"
                         formControlName="muelle">
                  <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                    Muelle
                  </label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                         type="checkbox"
                         id="flexCheckDefault"
                         autocomplete="off"
                         formControlName="transpaleta">
                  <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                    Transpaleta
                  </label>
                </div>

                <div class="form-check a">
                  <input class="form-check-input"
                         type="checkbox"
                         id="flexCheckDefault"
                         autocomplete="off"
                         formControlName="carretilla">
                  <label class="form-check-label" for="flexCheckDefault" style="color: #000000; font-size: 10px;">
                    Carretilla elevadora
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-4">
        <div class="col-sm-12">
          <div class="b">
            <button class="contenedor-btn-file-long" (click)="addTrayecto()">
              <img src="../../../assets/images/transfriendly/plus.png">
              Agregar trayecto
              <label for="btn-file"></label>
              <input type="file" id="btn-file">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language.service';
import { ServicesService } from '../../pages/components/viaje-multiple/services.service';
import { SharingService } from '../../core/services/sharing.service';

@Component({
  selector: 'app-trayecto-uno',
  templateUrl: './trayecto-uno.component.html',
  styleUrls: ['./trayecto-uno.component.scss']
})


export class TrayectoUnoComponent implements OnInit {

  @Input() formGroupName: string;
  public trayecto: FormGroup;
  public orden:File;
  public descargadores:any;
  public registerUser = { 'id':0, 'name':'', 'perfil':0 }
  public verQuien:boolean;
  //public descargadorDesconocido:boolean = false;
  public zp:number=0;
  public origen_provincia:string= ''
  public ciudad:string= ''
  public calle:string= ''
  public numero:number = 0

  constructor(public languageService: LanguageService,
              private formGroupDirective: FormGroupDirective,
              private servicesService:ServicesService,
              private sharingService:SharingService){}


  ngOnInit(): void {
    this.trayecto = this.formGroupDirective.control.get(this.formGroupName) as FormGroup;
    this.registerUser = JSON.parse(localStorage.getItem('data'));
    this.getQuienrecibe(-1);
    this.getDescargadores();
  }


  public writeCod_Postal(valor:any){
    if(this.verQuien || this.trayecto.get('descargadorDesconocido').value){
      this.sharingService.setCodigo_postalObservable(valor)
    }
  }

  public writeProvincia(valor:any){
    console.log('verQuien', this.verQuien)
    console.log('descargadorDesconocido', this.trayecto.get('descargadorDesconocido').value)
    if(this.verQuien || this.trayecto.get('descargadorDesconocido').value){
      this.sharingService.setProvinciaObservable(valor)
    }
  }

  public writeCiudad(valor:any){
    console.log('ciudad', valor)
    if(this.verQuien || this.trayecto.get('descargadorDesconocido').value){
      this.sharingService.setCiudadObservable(valor);
    }
  }

  public writeCalle(valor:any){
    if(this.verQuien || this.trayecto.get('descargadorDesconocido').value){
      this.sharingService.setCalleObservable(valor);
    }
  }

  public writeNumero(valor:any){
    if(this.verQuien || this.trayecto.get('descargadorDesconocido').value){
      this.sharingService.setNumeroObservable(valor);
    }
  }

  onFileChange(event){
    if(event.target.files.length > 0){
      this.orden = event.target.files[0]
      this.trayecto.get('orden_entrega').setValue(this.orden)
      this.trayecto.get('orden_entrega_name').setValue(this.orden.name)
    }
  }

  getDescargadores(){
    this.descargadores = [];
    this.zp = this.trayecto.get('destino_cod_postal').value
    this.servicesService.descargadores(this.registerUser.id, this.zp).subscribe(resp =>{
      if(resp.estatus == "ok"){
        this.descargadores = resp.response;
      }
    });
  }

  invalidate(nameFormControl: string){
    let valida = { value: false };
    if((this.trayecto.get(nameFormControl)?.touched ||
        this.trayecto.get(nameFormControl)?.dirty) &&
        this.trayecto.get(nameFormControl)?.invalid)
    { valida.value = true; }

    return valida
  }

  invalidateQuienRecibe(nameFormControl: string){
    let valida = { value: false };
    if(this.trayecto.get(nameFormControl)?.touched && this.trayecto.get(nameFormControl)?.value == -1)
    { valida.value = true; }

    return valida
  }


  getQuienrecibe(datos:any){
    if(datos === "9998" || datos === "9999"){
      this.origen_provincia=''
      this.ciudad=''
      this.calle=''
      this.numero = 0

      this.trayecto.get('destino_provincia').setValue('')
      this.trayecto.get('destino_ciudad').setValue('')
      this.trayecto.get('destino_calle').setValue('')
      this.trayecto.get('destino_numero').setValue('')
      this.trayecto.get('quien_recibe').setValue(datos)
    }
    else if(datos >=0 ){
      this.origen_provincia=this.descargadores[datos].provincia
      this.ciudad=this.descargadores[datos].ciudad
      this.calle=this.descargadores[datos].calle
      this.numero=this.descargadores[datos].numero

      this.trayecto.get('destino_provincia').setValue(this.descargadores[datos].provincia)
      this.trayecto.get('destino_ciudad').setValue(this.descargadores[datos].ciudad)
      this.trayecto.get('destino_calle').setValue(this.descargadores[datos].calle)
      this.trayecto.get('destino_numero').setValue(this.descargadores[datos].numero)
      this.trayecto.get('quien_recibe').setValue(this.descargadores[datos].id)
    }


    this.sharingService.setCodigo_postalObservable(this.trayecto.get('destino_cod_postal').value);
    this.sharingService.setProvinciaObservable(this.trayecto.get('destino_provincia').value);
    this.sharingService.setCiudadObservable(this.trayecto.get('destino_ciudad').value);
    this.sharingService.setCalleObservable(this.trayecto.get('destino_calle').value);
    this.sharingService.setNumeroObservable(this.trayecto.get('destino_numero').value);

    const quien = this.trayecto.get('indexDescargador').value

    if(quien == 9998){
      this.trayecto.get('descargadorDesconocido').setValue(true)
    }
    else
    {
      this.trayecto.get('descargadorDesconocido').setValue(false)
    }

    if(quien == 9999)
    { this.verQuien = true
      this.trayecto.get('verquien').setValue(true)
      this.trayecto.get('nombre').addValidators([Validators.required]);
      this.trayecto.get('telefono').addValidators([Validators.required]);
    }
    else
    { this.verQuien = false
      this.trayecto.get('verquien').setValue(false)
      this.trayecto.get('nombre').clearValidators();
      this.trayecto.get('telefono').clearValidators();

      this.trayecto.get('nombre').setValue('')
      this.trayecto.get('dni').setValue('')
      this.trayecto.get('telefono').setValue('')
      this.trayecto.get('correo').setValue('')
    }
  }

  public verquien():boolean{
    const verquien = this.trayecto.get('verquien').value;
    return verquien
  }

  public zonaPostal(event: any){
    this.trayecto.get('destino_cod_postal').setValue(event.target.value);
    //this.zp = event.target.value
    this.getDescargadores()
  }
}
